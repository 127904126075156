import { formatISODate } from 'helpers/date';
import { createUserName } from 'helpers/common';
import { IconButton, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext } from 'react';
import { ModalContext } from 'context/modalContext';
import {
  AdminCourseUsersFilterInput,
  CourseUsersQuery,
  Exact,
  InputMaybe,
  useRemoveUserFromCourseMutation,
} from '@generated/graphql';
import { ArrayElement } from 'types/common';
import { ApolloQueryResult } from '@apollo/client';
import { ITableStructure } from 'layouts/TableLayout/types';

type User = ArrayElement<CourseUsersQuery['courseUsers']['data']>;
type Refetch = (
  variables?:
    | Partial<
        Exact<{
          filter?: InputMaybe<AdminCourseUsersFilterInput> | undefined;
          limit: number;
          page: number;
          courseId: string;
        }>
      >
    | undefined,
) => Promise<ApolloQueryResult<CourseUsersQuery>>;

const USERS_TABLE = (refetch: Refetch, course: CourseUsersQuery['course'] | undefined): ITableStructure<User>[] => {
  const navigate = useNavigate();
  const { handleOpen } = useContext(ModalContext);
  const { addToast } = useContext(ToastContext);
  const [removeUserFromCourse] = useRemoveUserFromCourseMutation();
  return [
    {
      title: 'ID',
      value: (user) => user.numericId || '—',
      loadingValue: '1355',
    },
    {
      title: 'ФИО',
      value: (user) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          component='button'
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/users/${user.id}`);
          }}
        >
          {createUserName(user)}
        </Link>
      ),
      loadingValue: 'Иванов Иван Иваныч',
    },
    {
      title: 'Дата получения курса',
      value: (user) => (user.coursePurchaseDate ? formatISODate(user.coursePurchaseDate) : '—'),
      loadingValue: '100',
    },
    {
      title: 'Количество платных блоков',
      value: (user) => user.coursePaidBlocksCount || '0',
      loadingValue: 'Иванов Иван Иваныч',
    },
    {
      title: 'Дата последней покупки',
      value: (user) => (user.courseLastPurchaseDate ? formatISODate(user.courseLastPurchaseDate) : '—'),
      loadingValue: '100',
    },
    {
      title: 'Действие',
      value: (user) => (
        <IconButton
          size='large'
          color='error'
          onClick={(e) => {
            e.stopPropagation();
            handleOpen({
              title: 'Удалить пользователя',
              content: `Вы уверены, что хотите удалить пользователя из курса «${course?.title}»?`,
              handleClick: async () =>
                removeUserFromCourse({
                  variables: { userId: user.id, courseId: course?.id! },
                })
                  .then(() => {
                    addToast({ type: ToastTypeEnum.SUCCESS });
                    refetch();
                  })
                  .catch(() => addToast({ type: ToastTypeEnum.ERROR })),
            });
          }}
        >
          <CloseIcon />
        </IconButton>
      ),
      tooltip: () => 'Удалить пользователя из курса',
      loadingValue: 'Х',
    },
  ];
};

export default USERS_TABLE;

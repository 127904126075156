import MenuButton from 'components/buttons/MenuButton';
import { Box, Button } from '@mui/material';
import {
  useBlockOrUnblockUserMutation,
  useChangeRoleMutation,
  useGenerateUserPasswordMutation,
  UserHeaderQuery,
} from '@generated/graphql';
import { FC, useContext, useEffect, useState } from 'react';
import { ModalContext } from 'context/modalContext';
import { useForm } from 'react-hook-form';
import { ROLE_OPTIONS } from 'constants/global';
import { AutocompleteControl, Form } from 'components/inputs';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import ShowNewPasswordModal from 'pages/User/view/components/ShowNewPasswordModal';

interface IGeneratePasswordResult {
  password?: string | null | undefined;
  phoneNumber?: string | null | undefined;
}

export type GeneratePasswordResult = IGeneratePasswordResult | undefined;

const ChangeRoleModalContent: FC = () => (
  <Box sx={{ pt: 2 }}>
    <AutocompleteControl label='Роль' options={ROLE_OPTIONS} name='role' />
  </Box>
);

const HeaderButtons = ({ user }: { user: UserHeaderQuery['user'] | undefined }) => {
  const { addToast } = useContext(ToastContext);
  const { handleOpen } = useContext(ModalContext);
  const [blockOrUnblockUser] = useBlockOrUnblockUserMutation();
  const [changeRole] = useChangeRoleMutation();
  const [generatePassword] = useGenerateUserPasswordMutation();
  const [openPasswordModal, setOpenPasswordModal] = useState<GeneratePasswordResult>();

  const isBlocked = user?.isBlocked;

  const form = useForm();
  const { reset, handleSubmit } = form;

  useEffect(() => {
    if (user?.role) {
      reset({
        role: ROLE_OPTIONS.find((role) => role.id === user?.role),
      });
    }
  }, [user]);

  const onBlockOrUnblockUser = () =>
    handleOpen({
      title: isBlocked ? 'Разблокировать пользователя' : 'Заблокировать пользователя',
      content: isBlocked
        ? 'Вы уверены, что хотите разблокировать пользователя?'
        : 'Вы уверены, что хотите заблокировать пользователя?',
      buttonText: isBlocked ? 'Разблокировать' : 'Заблокировать',
      handleClick: async () =>
        blockOrUnblockUser({ variables: { userId: user?.id! } })
          .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
          .catch(() => addToast({ type: ToastTypeEnum.ERROR })),
    });

  const onChangeRole = () =>
    handleSubmit((data) => {
      changeRole({ variables: { userId: user?.id!, role: data.role.id } })
        .then((res) => {
          addToast({ type: ToastTypeEnum.SUCCESS });
          setOpenPasswordModal(res.data?.changeRole);
        })
        .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
    })();

  const handleChangeRole = () =>
    handleOpen({
      title: 'Изменить роль',
      content: (
        <Form form={form}>
          <ChangeRoleModalContent />
        </Form>
      ),
      buttonText: 'Сохранить',
      maxWidth: 'sm',
      handleClick: async () => onChangeRole(),
    });

  const resetPassword = () => {
    generatePassword({
      variables: {
        userId: user?.id!,
      },
    })
      .then((res) => {
        addToast({ type: ToastTypeEnum.SUCCESS, text: 'Пароль успешно сгенерирован' });
        setOpenPasswordModal(res.data?.generateUserPassword);
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  return (
    <>
      <MenuButton>
        <Button onClick={handleChangeRole}>Изменить роль</Button>
        <Button onClick={onBlockOrUnblockUser}>{isBlocked ? 'Разблокировать' : 'Заблокировать'}</Button>
        <Button onClick={resetPassword}>Сбросить пароль</Button>
      </MenuButton>
      {!!openPasswordModal && <ShowNewPasswordModal data={openPasswordModal} setOpen={setOpenPasswordModal} />}
    </>
  );
};

export default HeaderButtons;

import { formatISODate } from 'helpers/date';
import { ROLE_NAME } from 'constants/global';
import { AdminUserListOrderFields } from '@generated/graphql';
import { createUserName } from 'helpers/common';
import { ITableStructure } from 'layouts/TableLayout/types';
import { UserItem } from 'pages/User/list/types';

const USERS_TABLE: ITableStructure<UserItem>[] = [
  {
    title: 'ID',
    value: (user) => user.numericId || '—',
    loadingValue: '1355',
    sortName: AdminUserListOrderFields.NumericId,
  },
  {
    title: 'ФИО',
    value: (user) => createUserName(user),
    loadingValue: 'Иванов Иван Иваныч',
  },
  {
    title: 'Роль',
    value: (user) => (user.role ? ROLE_NAME[user.role] : '—'),
    loadingValue: '100',
  },
  {
    title: 'Дата создания',
    value: (user) => (user.createdAt ? formatISODate(user.createdAt) : '—'),
    loadingValue: '100',
  },
];

export default USERS_TABLE;

import React from 'react';
import { Autocomplete as MuiAutocomplete, InputAdornment, TextField } from '@mui/material';
import { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import { FieldError } from 'react-hook-form';
import { AutocompleteFreeSoloValueMapping, AutocompleteValue } from '@mui/material/useAutocomplete/useAutocomplete';

export interface IOption {
  id: string;
  displayName: string;
  [key: string]: string;
}

type MuiAutocompleteProps<
  TOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<AutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'onChange'>;

export interface IAutocompleteProps<
  TOption extends Record<string, any> = IOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends MuiAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo> {
  placeholder?: string;
  error?: FieldError;
  label?: string;
  startIcon?: React.ReactNode;
  optionName?: keyof TOption;
  equalityParam?: string;
  search?: boolean;
  onChange: (value: AutocompleteValue<TOption, Multiple, DisableClearable, FreeSolo> | null) => void;
}

const Autocomplete = <
  TOption extends Record<string, any> = IOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  props: IAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>,
) => {
  const {
    fullWidth,
    placeholder,
    error,
    label,
    startIcon,
    optionName = 'displayName',
    getOptionLabel = (option: TOption | AutocompleteFreeSoloValueMapping<FreeSolo>) =>
      typeof option === 'object' ? option[optionName] : option,
    options,
    equalityParam = 'id',
    search = false,
    freeSolo,
    multiple,
    disabled,
    onInputChange,
    onChange,
    value,
    onBlur,
    loading,
    filterOptions,
  } = props;
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      placeholder={placeholder || 'Выберите из списка'}
      helperText={error?.message}
      error={!!error}
      label={label}
      InputProps={{
        ...params.InputProps,
        startAdornment:
          params.InputProps.startAdornment ||
          (startIcon && <InputAdornment position='start'>{startIcon}</InputAdornment>),
      }}
    />
  );

  const renderOption = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: any) => {
    const text = getOptionLabel?.(option) || option[optionName];
    return (
      <li {...optionProps} key={`${option[equalityParam]}-${text}`}>
        {text}
      </li>
    );
  };
  return (
    <MuiAutocomplete
      fullWidth={fullWidth}
      size='small'
      value={value}
      onBlur={onBlur}
      multiple={multiple}
      options={options || []}
      filterOptions={search ? (x) => x : filterOptions}
      disabled={disabled}
      loading={loading}
      freeSolo={freeSolo}
      loadingText='Загрузка...'
      noOptionsText='Нет данных'
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      renderOption={renderOption}
      isOptionEqualToValue={(option, optionValue) => option[equalityParam] === (optionValue[equalityParam] || '')}
      onChange={(_, data, reason) => {
        if (reason === 'clear') {
          onChange(null);
        } else {
          onChange(data);
        }
      }}
      onInputChange={onInputChange}
    />
  );
};

export default Autocomplete;

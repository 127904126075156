import React, { FC } from 'react';
import { Box, Grid } from '@mui/material';
import TextFieldControl from 'components/inputs/control/TextFieldControl';
import { UseFormReturn } from 'react-hook-form';
import FileItem from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/elements/QuestionContainer/FileItem';
import AttachFile from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/elements/QuestionContainer/AttachFile';
import { FewAnswer, SingleAnswer, TextAnswer, Matching } from '../answers';
import {
  ExerciseForm,
  ExerciseFormWithFewAnswer,
  ExerciseFormWithMatching,
  ExerciseFormWithSingleAnswer,
  ExerciseFormWithTextAnswer,
  ExtendedElementTypes,
  QuestionComponents,
} from '../../types/formTypes';
import ScoreSettings from './ScoreSettings';

type QuestionProps = {
  elementIndex: number;
  disabled?: boolean;
  itemType: QuestionComponents;
  form: UseFormReturn<ExerciseForm>;
};

const QuestionContainer: FC<QuestionProps> = ({ elementIndex, disabled, itemType, form }) => {
  const { watch } = form;
  const watchFiles = watch(`elements.${elementIndex}.files`) || [];

  const renderComponent = () => {
    switch (itemType) {
      case ExtendedElementTypes.QUESTION_FEW_ANSWERS:
        return <FewAnswer elementIndex={elementIndex} form={form as UseFormReturn<ExerciseFormWithFewAnswer>} />;
      case ExtendedElementTypes.QUESTION_MATCHING:
        return <Matching elementIndex={elementIndex} form={form as UseFormReturn<ExerciseFormWithMatching>} />;
      case ExtendedElementTypes.QUESTION_TEXT_ANSWERS:
        return <TextAnswer elementIndex={elementIndex} form={form as UseFormReturn<ExerciseFormWithTextAnswer>} />;
      case ExtendedElementTypes.QUESTION_SINGLE_ANSWER:
        return <SingleAnswer elementIndex={elementIndex} form={form as UseFormReturn<ExerciseFormWithSingleAnswer>} />;
      default:
        return null;
    }
  };
  return (
    <Box flexGrow={1}>
      <Grid container spacing={2}>
        <Grid container spacing={2} item xs={12}>
          <Grid container item xs={12} md={8}>
            <Grid item xs={12}>
              <TextFieldControl
                minRows={4}
                multiline
                label='Описание'
                name={`elements.${elementIndex}.description`}
                rules={{
                  validate: (value, formValues) => !!formValues.elements[elementIndex].title || !!value,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              {renderComponent()}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <ScoreSettings elementIndex={elementIndex} elementType={itemType} form={form} />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid container item spacing={3}>
            {watchFiles?.map((currentFile, index) => (
              <FileItem
                key={`question-file-${currentFile.id}`}
                currentFile={currentFile}
                elementIndex={elementIndex}
                fileIndex={index}
                form={form}
                disabled={disabled}
              />
            ))}
          </Grid>
          <Grid item>
            <AttachFile form={form} disabled={disabled} elementIndex={elementIndex} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default QuestionContainer;

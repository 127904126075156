import { array, bool, boolean, number, object, string } from 'yup';

const blockValidation = object().shape({
  blocks: array()
    .of(
      object().shape({
        id: string().nullable(),
        title: string().required(' '),
        trial: bool(),
        coverFileUrl: string().nullable(),
        dateFrom: string().nullable(),
        dateTo: string().nullable(),
        cost: number().nullable(),
        lessons: array()
          .of(
            object().shape({
              id: string().nullable(),
              title: string().required(' '),
              trial: boolean(),
              dateFrom: string().nullable(),
              dateTo: string().nullable(),
              exercises: array().of(
                object().shape({
                  id: string().nullable(),
                  title: string().required(' '),
                  inProgress: boolean(),
                  type: object()
                    .shape({
                      id: string().required(' '),
                      displayName: string().required(' '),
                    })
                    .default(undefined)
                    .required(' '),
                }),
              ),
            }),
          )
          .min(1),
      }),
    )
    .min(1),
});

export default blockValidation;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date custom scalar type */
  Date: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export enum Add_User_In_Course_Type {
  Full = 'FULL',
  Trial = 'TRIAL',
}

export type Acquiring = {
  __typename?: 'Acquiring';
  /** Курсы эквайринга */
  courses?: Maybe<Array<Course>>;
  createdAt: Scalars['Date']['output'];
  /** Данные интеграции эквайринга */
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  /** Название инстанса экфайринга */
  title: Scalars['String']['output'];
  /** Транзакции эквайринга */
  transactions?: Maybe<Array<Transaction>>;
  /** Ресурс эквайринга */
  type: AcquiringType;
  updatedAt: Scalars['Date']['output'];
};

export enum AcquiringType {
  Sberbank = 'SBERBANK',
  Yoomoney = 'YOOMONEY',
}

export type AddUserInCourseInput = {
  /** Тип доступа к курсу */
  accessType: Add_User_In_Course_Type;
  /** Id курса */
  courseId: Scalars['ID']['input'];
  /** Id пользователя */
  userId: Scalars['ID']['input'];
};

export type AdminBlockListFilterInput = {
  /** Id пользователя */
  courseId: Scalars['ID']['input'];
  /** Id пользователя */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminCourseListFilterInput = {
  /** Диапазон дат начала курса */
  dateFrom?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** Диапазон дат окончания курса */
  dateTo?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** Скрыт ли курс */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Числовой ID курса */
  numericId?: InputMaybe<Scalars['Float']['input']>;
  /** Тема курса */
  subject?: InputMaybe<CourseSubject>;
  /** Название курса */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCourseUsersFilterInput = {
  /** Id курса */
  courseId: Scalars['ID']['input'];
  /** Дата получения доступа к курсу */
  coursePurchaseAt?: InputMaybe<Scalars['Date']['input']>;
  /** ФИО пользователя */
  userFullName?: InputMaybe<Scalars['String']['input']>;
  /** Числовой Id пользователя */
  userNumericId?: InputMaybe<Scalars['Float']['input']>;
};

export type AdminExerciseListFilterInput = {
  /** Id Курса */
  courseId?: InputMaybe<Scalars['ID']['input']>;
  /** Типы задания */
  types?: InputMaybe<Array<ExerciseType>>;
};

export type AdminUserAnswerListFilterInput = {
  /** Класс курса */
  courseGrade?: InputMaybe<Scalars['Float']['input']>;
  /** Предмет курса */
  courseSubject?: InputMaybe<CourseSubject>;
  /** Название курса */
  courseTitle?: InputMaybe<Scalars['String']['input']>;
  /** Статус задания */
  status?: InputMaybe<UserAnswerStatus>;
  /** Дата обновления задания, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Числовой Id пользователя */
  userNumericId?: InputMaybe<Scalars['Float']['input']>;
};

export enum AdminUserAnswerListOrderFields {
  UpdatedAt = 'updatedAt',
}

export type AdminUserAnswerListOrderInput = {
  /** Поле сортировки */
  field: AdminUserAnswerListOrderFields;
  /** Порядок сортировка */
  order: OrderType;
};

export type AdminUserCourseListFilterInput = {
  /** Класс предмета курса */
  courseGrade?: InputMaybe<Scalars['Float']['input']>;
  /** Id курса */
  courseId?: InputMaybe<Scalars['ID']['input']>;
  /** Числовой Id курса */
  courseNumericId?: InputMaybe<Scalars['Float']['input']>;
  /** Название курса */
  courseTitle?: InputMaybe<Scalars['String']['input']>;
  /** Дата создания пользователя, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** Тип приобритения курса */
  type?: InputMaybe<UserCourseType>;
  /** Id пользователя */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminUserListFilterInput = {
  /** Дата создания пользователя, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** ФИО пользователя */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Заблокирован ли пользователь */
  isBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  /** Числовой Id пользователя */
  numericId?: InputMaybe<Scalars['Float']['input']>;
  /** Роль */
  role?: InputMaybe<RoleName>;
};

export enum AdminUserListOrderFields {
  NumericId = 'numericId',
}

export type AdminUserListOrderInput = {
  /** Поле сортировки */
  field: AdminUserListOrderFields;
  /** Порядок сортировка */
  order: OrderType;
};

export type AuthTokensDto = {
  __typename?: 'AuthTokensDto';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type Block = {
  __typename?: 'Block';
  /** Тип доступа к блоку */
  availabilityType: BlockAvailabilityType;
  /** Стоимость блока */
  cost: Scalars['Float']['output'];
  /** Курс, к которому привязан блок */
  course: Course;
  /** ID курса */
  courseId: Scalars['ID']['output'];
  /** Файл обложки блока */
  coverFile?: Maybe<File>;
  /** ID файла обложки блока */
  coverFileId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Дата начала уроков блока */
  dateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата окончания уроков блока */
  dateTo?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** Уроки блока */
  lessons?: Maybe<Array<Lesson>>;
  /** Порядковый номер блока */
  order: Scalars['Int']['output'];
  /** Название блока */
  title: Scalars['String']['output'];
  /** Транзакции по блоку */
  transactions?: Maybe<Array<Transaction>>;
  updatedAt: Scalars['Date']['output'];
  /** Доступ пользователя к блоку. !! без динамических рилейшенов, только базовые поля !! */
  userBlock?: Maybe<UserBlock>;
  /** Пользователи блока */
  userBlocks?: Maybe<Array<UserBlock>>;
  /** Последняя транзакция пользователя по блоку. !! пока без динамических рилейшенов, только базовые поля !! */
  userLastTransaction?: Maybe<Transaction>;
};

export type BlockUserBlockArgs = {
  userId: Scalars['ID']['input'];
};

export type BlockUserLastTransactionArgs = {
  userId: Scalars['ID']['input'];
};

export enum BlockAvailabilityType {
  Issued = 'ISSUED',
  Mixed = 'MIXED',
  Payable = 'PAYABLE',
  Trial = 'TRIAL',
}

export type BlockLkDto = {
  __typename?: 'BlockLkDto';
  /** Тип доступа к блоку */
  availabilityType: BlockAvailabilityType;
  /** Стоимость блока */
  cost: Scalars['Float']['output'];
  /** ID курса */
  courseId: Scalars['ID']['output'];
  /** Файл обложки блока */
  coverFile?: Maybe<FileLkDto>;
  /** Дата начала уроков блока */
  dateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата окончания уроков блока */
  dateTo?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** Доступен ли блок пользователю */
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Уроки блока */
  lessons?: Maybe<Array<LessonLkDto>>;
  /** Порядковый номер блока */
  order: Scalars['Int']['output'];
  /** Название блока */
  title: Scalars['String']['output'];
  /** Причина недоступности блока */
  unavailableReason?: Maybe<UnavailableReason>;
};

export type ColorTheme = {
  __typename?: 'ColorTheme';
  /** Цвет в формате HEX */
  color: Scalars['String']['output'];
  /** Курсы привязанные к цветовой теме */
  courses?: Maybe<Array<Course>>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type ColorThemeLkDto = {
  __typename?: 'ColorThemeLkDto';
  /** Цвет в формате HEX */
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CopyCourseInput = {
  /** Id курса */
  id: Scalars['ID']['input'];
  /** Новое название */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CountType = {
  __typename?: 'CountType';
  completeCount: Scalars['Float']['output'];
  doneCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type Course = {
  __typename?: 'Course';
  /** Эквайринг курса */
  acquiring?: Maybe<Acquiring>;
  /** Id эквайринга */
  acquiringId?: Maybe<Scalars['ID']['output']>;
  /** Рекламные карточки */
  advertising?: Maybe<Array<CourseCardAdvertising>>;
  /** Блоки курса */
  blocks?: Maybe<Array<Block>>;
  /** Карточки курса */
  cards?: Maybe<Array<CourseCard>>;
  /** Цветовая тема курса */
  colorTheme?: Maybe<ColorTheme>;
  /** ID цветовой темы */
  colorThemeId?: Maybe<Scalars['ID']['output']>;
  /** Стоимость курса в рублях */
  cost: Scalars['Float']['output'];
  /** Кураторы курса */
  courseCurators?: Maybe<Array<CourseCurator>>;
  /** Файл обложка курса */
  coverFile?: Maybe<File>;
  /** ID файла */
  coverFileId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Дата начала курса */
  dateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата окончания курса */
  dateTo?: Maybe<Scalars['Date']['output']>;
  /** Описание курса */
  description?: Maybe<Scalars['String']['output']>;
  /** Класс предмета */
  grade: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Скрыт ли курс */
  isHidden: Scalars['Boolean']['output'];
  /** Числовой ID курса */
  numericId: Scalars['Int']['output'];
  /** Используется ли курс в онбординг-боте */
  onboarding: Scalars['Boolean']['output'];
  /** Тема курса */
  subject: CourseSubject;
  /** Заголовок курса */
  title?: Maybe<Scalars['String']['output']>;
  /** Транзакции по курсу */
  transactions?: Maybe<Array<Transaction>>;
  updatedAt: Scalars['Date']['output'];
  /** Пользователи курса */
  userCourses?: Maybe<Array<UserCourse>>;
  /** Количество пользователей в курсе */
  usersCount?: Maybe<Scalars['Float']['output']>;
};

export type CourseCard = {
  __typename?: 'CourseCard';
  /** Курсы в которых отображать карточку */
  advertising?: Maybe<Array<CourseCardAdvertising>>;
  /** Курс, к которому привязана карточка */
  course: Course;
  /** ID курса */
  courseId: Scalars['ID']['output'];
  /** Файл обложка карточки */
  coverFile?: Maybe<File>;
  /** ID файла */
  coverFileId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Описание */
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Скрыта ли карточка */
  isHidden: Scalars['Boolean']['output'];
  /** Ссылка карточки */
  link?: Maybe<Scalars['String']['output']>;
  /** Тип карточки */
  type: CourseCardType;
  updatedAt: Scalars['Date']['output'];
  /** Использовать ли обложку из курса */
  useDefaultCover: Scalars['Boolean']['output'];
};

export type CourseCardAdvertising = {
  __typename?: 'CourseCardAdvertising';
  /** Карточка */
  card: CourseCard;
  /** ID карточки */
  cardId: Scalars['ID']['output'];
  /** Курс, в котором показывать карточку  */
  course: Course;
  /** ID курса */
  courseId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type CourseCardAdvertisingInput = {
  /** Id курса к которому привязана карточка */
  courseId?: InputMaybe<Scalars['ID']['input']>;
  /** Список Id курсов в которых отображать карточку */
  coursesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CourseCardAdvertisingLkDto = {
  __typename?: 'CourseCardAdvertisingLkDto';
  /** Карточка */
  card: CourseCardLkDto;
  /** ID карточки */
  cardId: Scalars['ID']['output'];
  /** Курс, в котором показывать карточку  */
  course: CoursePresentationLkDto;
  /** ID курса */
  courseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type CourseCardLkDto = {
  __typename?: 'CourseCardLkDto';
  /** Курс, к которому привязана карточка */
  course: CoursePresentationLkDto;
  /** Файл обложка карточки */
  coverFile?: Maybe<FileLkDto>;
  /** Описание */
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Ссылка карточки */
  link?: Maybe<Scalars['String']['output']>;
  /** Тип карточки */
  type: CourseCardType;
  /** Использовать ли обложку из курса */
  useDefaultCover: Scalars['Boolean']['output'];
};

export enum CourseCardType {
  Advertising = 'ADVERTISING',
  Onboarding = 'ONBOARDING',
  Presentation = 'PRESENTATION',
  Purchase = 'PURCHASE',
}

export type CourseCardsListFilterInput = {
  type?: InputMaybe<CourseCardType>;
};

export type CourseCurator = {
  __typename?: 'CourseCurator';
  /** Курс */
  course: Course;
  /** ID курса */
  courseId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Порядок */
  order: Scalars['Float']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user: User;
  /** ID пользователя */
  userId: Scalars['ID']['output'];
};

export type CoursePresentationLkDto = {
  __typename?: 'CoursePresentationLkDto';
  /** Карточки курса */
  cards?: Maybe<Array<CourseCardLkDto>>;
  /** Цветовая тема курса */
  colorTheme?: Maybe<ColorThemeLkDto>;
  /** Стоимость курса в рублях */
  cost: Scalars['Float']['output'];
  /** Файл обложка курса */
  coverFile?: Maybe<FileLkDto>;
  /** Дата начала курса */
  dateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата окончания курса */
  dateTo?: Maybe<Scalars['Date']['output']>;
  /** Описание курса */
  description?: Maybe<Scalars['String']['output']>;
  /** Класс предмета */
  grade: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Тема курса */
  subject: CourseSubject;
  /** Заголовок курса */
  title?: Maybe<Scalars['String']['output']>;
};

export enum CourseSubject {
  Biology = 'BIOLOGY',
  Chemistry = 'CHEMISTRY',
  History = 'HISTORY',
  Informatics = 'INFORMATICS',
  Literature = 'LITERATURE',
  Math = 'MATH',
  Physics = 'PHYSICS',
  RussianLanguage = 'RUSSIAN_LANGUAGE',
  SocialScience = 'SOCIAL_SCIENCE',
}

export type CreateUserBlockInput = {
  /** Id блока */
  blockId: Scalars['ID']['input'];
  /** Id пользователя */
  userId: Scalars['ID']['input'];
};

export type CuratorIdInput = {
  /** ID пользователя */
  curatorId: Scalars['ID']['input'];
};

/** Элемент задания */
export type Element = {
  createdAt: Scalars['Date']['output'];
  /** Части элементов */
  elementParts?: Maybe<Array<ElementPart>>;
  /** Задание, к которому привязан элемент */
  exercise: Exercise;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFile>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователей */
  usersAnswers?: Maybe<Array<UserAnswer>>;
  /** Ответы пользователей */
  usersProgresses?: Maybe<Array<UserProgress>>;
};

/** Файл, прикрепленный к элементу */
export type ElementFile = {
  __typename?: 'ElementFile';
  createdAt: Scalars['Date']['output'];
  /** Элемент новостей */
  element: Element;
  /** ID элемента */
  elementId: Scalars['ID']['output'];
  /** Прикрепленный файл */
  file: File;
  /** ID прикрепленного файла */
  fileId: Scalars['ID']['output'];
  /** Название файла при отправке */
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Порядковый номер файла элемента */
  order?: Maybe<Scalars['Int']['output']>;
  /** Тип файла элемента */
  type: ElementFileType;
  updatedAt: Scalars['Date']['output'];
};

export type ElementFileInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  /** Название файла при отправке */
  fileName: Scalars['String']['input'];
  /** Id связи сущности с файлом */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Тип файла в элементе */
  type: ElementFileType;
};

export type ElementFileLkDto = {
  __typename?: 'ElementFileLkDto';
  /** ID элемента */
  elementId: Scalars['ID']['output'];
  /** Прикрепленный файл */
  file: FileLkDto;
  /** Название файла при отправке */
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Порядковый номер файла элемента */
  order?: Maybe<Scalars['Int']['output']>;
  /** Тип файла элемента */
  type: ElementFileType;
};

export enum ElementFileType {
  Content = 'CONTENT',
  Cover = 'COVER',
  Download = 'DOWNLOAD',
}

export type ElementInput = {
  /** Данные элемент ссылки на задание */
  exerciseLinkElement?: InputMaybe<ExerciseLinkElementInput>;
  /** Данные элемент с файлами */
  fileElement?: InputMaybe<FileElementInput>;
  /** Данные элемента заголовка */
  headingElement?: InputMaybe<HeadingElementInput>;
  /** ID элемента */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Данные элемент вопроса */
  questionElement?: InputMaybe<QuestionElementInput>;
  /** Данные текстового элемента */
  textElement?: InputMaybe<TextElementInput>;
  /** Тип элемента */
  type: ElementType;
  /** Данные видео элемент */
  videoElement?: InputMaybe<VideoElementInput>;
};

export type ElementLkDto = {
  /** Файлы элементов */
  elementParts?: Maybe<Array<ElementPartLkDto>>;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFileLkDto>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
};

export type ElementPart = {
  __typename?: 'ElementPart';
  /** Содержание части элемента */
  content?: Maybe<Scalars['String']['output']>;
  /** Правильный ответ */
  correctAnswer?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Вопрос к части элемента */
  element?: Maybe<Element>;
  /** ID Вопроса */
  elementId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** Обозначение правильного ответа */
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  /** Порядковый номер части элемента */
  order?: Maybe<Scalars['Int']['output']>;
  /** Тип части элемента */
  type: ElementPartType;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователей */
  usersAnswers?: Maybe<Array<UserAnswer>>;
};

export type ElementPartInput = {
  /** Содержание части элемента */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Правильный ответ */
  correctAnswer?: InputMaybe<Scalars['String']['input']>;
  /** ID варианта ответа */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Обозначение правильного ответа */
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Тип части элемента */
  type: ElementPartType;
};

export type ElementPartLkDto = {
  __typename?: 'ElementPartLkDto';
  /** Содержание части элемента */
  content?: Maybe<Scalars['String']['output']>;
  /** ID Вопроса */
  elementId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** Порядковый номер части элемента */
  order?: Maybe<Scalars['Int']['output']>;
  /** Тип части элемента */
  type: ElementPartType;
};

export enum ElementPartType {
  Answer = 'ANSWER',
  Matching = 'MATCHING',
}

export enum ElementType {
  ExerciseLink = 'EXERCISE_LINK',
  Feedback = 'FEEDBACK',
  File = 'FILE',
  Heading = 'HEADING',
  Question = 'QUESTION',
  Text = 'TEXT',
  Video = 'VIDEO',
}

export type Exercise = {
  __typename?: 'Exercise';
  createdAt: Scalars['Date']['output'];
  /** Дата начала задания */
  dateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата окончания задания */
  dateTo?: Maybe<Scalars['Date']['output']>;
  /** Задания урока */
  elements?: Maybe<Array<Element>>;
  id: Scalars['ID']['output'];
  /** Учитывается ли урок в прогрессе */
  inProgress: Scalars['Boolean']['output'];
  /** Урок, к которому привязано задание */
  lesson: Lesson;
  /** ID урока */
  lessonId: Scalars['ID']['output'];
  /** Порядковый номер задания */
  order: Scalars['Int']['output'];
  /** Время прохождения задания в секундах */
  timeLimit?: Maybe<Scalars['Float']['output']>;
  /** Заголовок задания */
  title: Scalars['String']['output'];
  /** ип задания */
  type: ExerciseType;
  updatedAt: Scalars['Date']['output'];
  /** Ответ выбранного пользователя на ворпос части Б */
  userFileAnswer?: Maybe<UserAnswer>;
  /** Статистика пользователя по заданию */
  userStatistic: ExerciseStatistic;
};

export type ExerciseUserFileAnswerArgs = {
  userId: Scalars['ID']['input'];
};

export type ExerciseUserStatisticArgs = {
  userId: Scalars['ID']['input'];
};

/** Элемент ссылки на задание */
export type ExerciseLinkElement = Element & {
  __typename?: 'ExerciseLinkElement';
  createdAt: Scalars['Date']['output'];
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** Части элементов */
  elementParts?: Maybe<Array<ElementPart>>;
  /** Задание, к которому привязан элемент */
  exercise: Exercise;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFile>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователей */
  usersAnswers?: Maybe<Array<UserAnswer>>;
  /** Ответы пользователей */
  usersProgresses?: Maybe<Array<UserProgress>>;
};

export type ExerciseLinkElementInput = {
  /** Описание */
  description?: InputMaybe<Scalars['String']['input']>;
};

export type ExerciseLinkElementLkDto = ElementLkDto & {
  __typename?: 'ExerciseLinkElementLkDto';
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** Файлы элементов */
  elementParts?: Maybe<Array<ElementPartLkDto>>;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFileLkDto>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
};

export type ExerciseLkDto = {
  __typename?: 'ExerciseLkDto';
  /** Дата начала задания */
  dateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата окончания задания */
  dateTo?: Maybe<Scalars['Date']['output']>;
  /** Элементы */
  elements?: Maybe<Array<ElementLkDto>>;
  id: Scalars['ID']['output'];
  /** Учитывается ли урок в прогрессе */
  inProgress: Scalars['Boolean']['output'];
  /** Доступено ли задание пользователю */
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** ID урока */
  lessonId: Scalars['ID']['output'];
  /** Порядковый номер задания */
  order: Scalars['Int']['output'];
  /** Время прохождения задания в секундах */
  timeLimit?: Maybe<Scalars['Float']['output']>;
  /** Заголовок задания */
  title: Scalars['String']['output'];
  /** ип задания */
  type: ExerciseType;
  /** Причина недоступности задания */
  unavailableReason?: Maybe<UnavailableReason>;
};

export type ExerciseStatistic = {
  __typename?: 'ExerciseStatistic';
  maxScoreA?: Maybe<Scalars['Float']['output']>;
  maxScoreB?: Maybe<Scalars['Float']['output']>;
  scoreA?: Maybe<Scalars['Float']['output']>;
  scoreB?: Maybe<Scalars['Float']['output']>;
};

export enum ExerciseType {
  Homework = 'HOMEWORK',
  Lecture = 'LECTURE',
}

/** Обратная связь */
export type FeedbackElement = Element & {
  __typename?: 'FeedbackElement';
  createdAt: Scalars['Date']['output'];
  /** Части элементов */
  elementParts?: Maybe<Array<ElementPart>>;
  /** Задание, к которому привязан элемент */
  exercise: Exercise;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFile>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователей */
  usersAnswers?: Maybe<Array<UserAnswer>>;
  /** Ответы пользователей */
  usersProgresses?: Maybe<Array<UserProgress>>;
};

export type FeedbackElementLkDto = ElementLkDto & {
  __typename?: 'FeedbackElementLkDto';
  /** Файлы элементов */
  elementParts?: Maybe<Array<ElementPartLkDto>>;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFileLkDto>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Ключ файла в S3 */
  key: Scalars['String']['output'];
  /** Название файла */
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Ссылка на файл */
  url: Scalars['String']['output'];
};

/** Скачивание файла */
export type FileElement = Element & {
  __typename?: 'FileElement';
  createdAt: Scalars['Date']['output'];
  /** Части элементов */
  elementParts?: Maybe<Array<ElementPart>>;
  /** Задание, к которому привязан элемент */
  exercise: Exercise;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFile>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователей */
  usersAnswers?: Maybe<Array<UserAnswer>>;
  /** Ответы пользователей */
  usersProgresses?: Maybe<Array<UserProgress>>;
};

export type FileElementInput = {
  /** Файлы привязанные к элементу */
  files: Array<ElementFileInput>;
};

export type FileElementLkDto = ElementLkDto & {
  __typename?: 'FileElementLkDto';
  /** Файлы элементов */
  elementParts?: Maybe<Array<ElementPartLkDto>>;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFileLkDto>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
};

export type FileInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type FileLkDto = {
  __typename?: 'FileLkDto';
  /** Ключ файла в S3 */
  key: Scalars['String']['output'];
  /** Название файла */
  name: Scalars['String']['output'];
  /** Ссылка на файл */
  url: Scalars['String']['output'];
};

export type GeneratePasswordResult = {
  __typename?: 'GeneratePasswordResult';
  /** Пароль пользователя */
  password?: Maybe<Scalars['String']['output']>;
  /** Номер телефона(логин) пользователя */
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

/** Заголовок */
export type HeadingElement = Element & {
  __typename?: 'HeadingElement';
  createdAt: Scalars['Date']['output'];
  /** Части элементов */
  elementParts?: Maybe<Array<ElementPart>>;
  /** Задание, к которому привязан элемент */
  exercise: Exercise;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFile>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Текст заголовок */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип элемента */
  type: ElementType;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователей */
  usersAnswers?: Maybe<Array<UserAnswer>>;
  /** Ответы пользователей */
  usersProgresses?: Maybe<Array<UserProgress>>;
};

export type HeadingElementInput = {
  /** Текст заголовок */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type HeadingElementLkDto = ElementLkDto & {
  __typename?: 'HeadingElementLkDto';
  /** Файлы элементов */
  elementParts?: Maybe<Array<ElementPartLkDto>>;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFileLkDto>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Текст заголовок */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип элемента */
  type: ElementType;
};

export type Lesson = {
  __typename?: 'Lesson';
  /** Блок, к которому привязан урок */
  block: Block;
  /** ID блока */
  blockId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  /** Дата начала урока */
  dateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата окончания урока */
  dateTo?: Maybe<Scalars['Date']['output']>;
  /** Задания урока */
  exercises?: Maybe<Array<Exercise>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер урока */
  order: Scalars['Int']['output'];
  /** Название урока */
  title: Scalars['String']['output'];
  /** Доступен ли урок в пробной версии */
  trial: Scalars['Boolean']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type LessonLkDto = {
  __typename?: 'LessonLkDto';
  /** ID блока */
  blockId: Scalars['ID']['output'];
  /** Дата начала урока */
  dateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата окончания урока */
  dateTo?: Maybe<Scalars['Date']['output']>;
  /** Задания урока */
  exercises?: Maybe<Array<ExerciseLkDto>>;
  id: Scalars['ID']['output'];
  /** Доступен ли урок пользователю */
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Порядковый номер урока */
  order: Scalars['Int']['output'];
  /** Название урока */
  title: Scalars['String']['output'];
  /** Доступен ли урок в пробной версии */
  trial: Scalars['Boolean']['output'];
  /** Причина недоступности урока */
  unavailableReason?: Maybe<UnavailableReason>;
};

export type Mutation = {
  __typename?: 'Mutation';
  SystemUpdateNoAnswerElementProgress: Scalars['Float']['output'];
  /** Добавление пользователя в курс */
  addUserInCourse: Scalars['Boolean']['output'];
  /** Блокировка пользователя */
  blockOrUnblockUser: User;
  /** Изменить роль пользователя */
  changeRole: GeneratePasswordResult;
  /** Копирование курс */
  copyCourse: Course;
  /** Создание или обновление курса */
  createOrUpdateCourse: Course;
  createSource: Scalars['String']['output'];
  /** Добавление пользователя в блок */
  createUserBlock: UserBlock;
  /** Удалить курс */
  deleteCourse: Scalars['Boolean']['output'];
  /** Удаление карточки */
  deleteCourseCard: Scalars['Boolean']['output'];
  /** Удаление пользователя из блока */
  deleteUserBlock: Scalars['Boolean']['output'];
  /** Изменить пароль пользователя */
  generateUserPassword: GeneratePasswordResult;
  /** Авторизация связке номер телефона + пароль */
  loginByCredentials: AuthTokensDto;
  /** Действие над ответом пользователя */
  processAnswer?: Maybe<UserAnswer>;
  /** Скрыть или опубликовать курс */
  publishOrHideCourse: Course;
  /** Скрыть или опубликовать карточку курса */
  publishOrHideCourseCard: CourseCard;
  /** Обновление токенов авторизации */
  refreshTokens: AuthTokensDto;
  /** Удаление пользователя из курса */
  removeUserFromCourse: Scalars['Boolean']['output'];
  sources: Scalars['String']['output'];
  systemAmoAccountInfo: Scalars['String']['output'];
  systemAmoAuth: Scalars['String']['output'];
  systemChannelConnect: Scalars['String']['output'];
  systemChannelDisconnect: Scalars['Boolean']['output'];
  systemUserJwt: AuthTokensDto;
  /** Обновление блоков курса */
  updateBlocks: Array<Block>;
  /** Обновление карточки или создание рекламной карточки */
  updateCourseCard: Array<CourseCard>;
  /** Обновление кураторов курса */
  updateCourseCurators: Array<CourseCurator>;
  /** Обновление элементов */
  updateElements: Exercise;
  /** Обновление данных пользователя */
  updateUserData: User;
  /** Прямая загрузка фалйа */
  uploadFile: File;
};

export type MutationAddUserInCourseArgs = {
  input: AddUserInCourseInput;
};

export type MutationBlockOrUnblockUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationChangeRoleArgs = {
  role: RoleName;
  userId: Scalars['ID']['input'];
};

export type MutationCopyCourseArgs = {
  input: CopyCourseInput;
};

export type MutationCreateOrUpdateCourseArgs = {
  input: UpdateCourseInput;
};

export type MutationCreateUserBlockArgs = {
  input: CreateUserBlockInput;
};

export type MutationDeleteCourseArgs = {
  courseId: Scalars['ID']['input'];
};

export type MutationDeleteCourseCardArgs = {
  courseCardId: Scalars['ID']['input'];
};

export type MutationDeleteUserBlockArgs = {
  blockId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationGenerateUserPasswordArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationLoginByCredentialsArgs = {
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type MutationProcessAnswerArgs = {
  input?: InputMaybe<ProcessAnswerInput>;
};

export type MutationPublishOrHideCourseArgs = {
  courseId: Scalars['ID']['input'];
};

export type MutationPublishOrHideCourseCardArgs = {
  courseCardId: Scalars['ID']['input'];
};

export type MutationRefreshTokensArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationRemoveUserFromCourseArgs = {
  courseId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationSystemUserJwtArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUpdateBlocksArgs = {
  input: UpdateBlocksInput;
};

export type MutationUpdateCourseCardArgs = {
  input: Array<UpdateCourseCardInput>;
};

export type MutationUpdateCourseCuratorsArgs = {
  input: UpdateCourseCuratorInput;
};

export type MutationUpdateElementsArgs = {
  input: UpdateElementsInput;
};

export type MutationUpdateUserDataArgs = {
  input: UpdateUserDataInput;
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
};

export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum OrderType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type PaginatedAcquiring = {
  __typename?: 'PaginatedAcquiring';
  /** Данные на указанной странице */
  data?: Maybe<Array<Acquiring>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedBlock = {
  __typename?: 'PaginatedBlock';
  /** Данные на указанной странице */
  data?: Maybe<Array<Block>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedColorTheme = {
  __typename?: 'PaginatedColorTheme';
  /** Данные на указанной странице */
  data?: Maybe<Array<ColorTheme>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedCourse = {
  __typename?: 'PaginatedCourse';
  /** Данные на указанной странице */
  data?: Maybe<Array<Course>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedExercises = {
  __typename?: 'PaginatedExercises';
  /** Данные на указанной странице */
  data?: Maybe<Array<Exercise>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  /** Данные на указанной странице */
  data?: Maybe<Array<User>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUserAnswers = {
  __typename?: 'PaginatedUserAnswers';
  /** Данные на указанной странице */
  data?: Maybe<Array<UserAnswer>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUsersCourse = {
  __typename?: 'PaginatedUsersCourse';
  /** Данные на указанной странице */
  data?: Maybe<Array<UserCourse>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PreviewUserLkDto = {
  __typename?: 'PreviewUserLkDto';
  /** Файл аватара пользователя */
  avatarFile?: Maybe<FileLkDto>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Числовой ID пользователя */
  numericId: Scalars['Int']['output'];
  /** Название роли */
  role: RoleName;
  /** Username пользоателя из телеграма */
  telegramUsername?: Maybe<Scalars['String']['output']>;
};

export type ProcessAnswerInput = {
  /** Если "true" также проставить статус "COMPLETE" */
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Прикреплённые файлы */
  files?: InputMaybe<Array<FileInput>>;
  /** ID ответа пользователя */
  id: Scalars['ID']['input'];
  /** Если "true" только проставить статус "IN_PROGRESS" */
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  /** Оставить сообщение */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Изменить оценку ответа, если определено то проставить статус "COMPLETE" */
  score?: InputMaybe<Scalars['Float']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /** Список инстансов эквайринга */
  acquiringInstances: PaginatedAcquiring;
  /** Список блоков курса */
  blocks: PaginatedBlock;
  /** Список цветовых тем */
  colorThemes: PaginatedColorTheme;
  /** Курс по ID */
  course: Course;
  /** Карточка курса по Id */
  courseCard: CourseCard;
  /** Карточка курса */
  courseCards: Array<CourseCard>;
  /** Кураторы курса */
  courseCurators?: Maybe<Array<CourseCurator>>;
  /** Список пользователей курса */
  courseUsers: PaginatedUser;
  /** Список курсов */
  courses: PaginatedCourse;
  /** Задание по Id */
  exercise?: Maybe<Exercise>;
  /** Список заданий */
  exercises?: Maybe<PaginatedExercises>;
  /** Текущий пользователь */
  me: User;
  /** Пользователь по Id */
  user: User;
  /** Ответ пользователя по ID */
  userAnswer?: Maybe<UserAnswer>;
  /** История изменений ответа */
  userAnswerHistory?: Maybe<Array<UserAnswerHistory>>;
  /** Список курсов пользователя */
  userCourses: PaginatedUsersCourse;
  /** Список пользователей */
  users: PaginatedUser;
  /** Список ответов пользователей */
  usersAnswers?: Maybe<PaginatedUserAnswers>;
};

export type QueryAcquiringInstancesArgs = {
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryBlocksArgs = {
  filter: AdminBlockListFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryColorThemesArgs = {
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryCourseArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryCourseCardArgs = {
  courseCardId: Scalars['ID']['input'];
};

export type QueryCourseCardsArgs = {
  courseId: Scalars['ID']['input'];
  filter: CourseCardsListFilterInput;
};

export type QueryCourseCuratorsArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryCourseUsersArgs = {
  filter?: InputMaybe<AdminCourseUsersFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryCoursesArgs = {
  filter?: InputMaybe<AdminCourseListFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryExerciseArgs = {
  exerciseId: Scalars['ID']['input'];
};

export type QueryExercisesArgs = {
  filter?: InputMaybe<AdminExerciseListFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryUserArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryUserAnswerArgs = {
  userAnswerId: Scalars['ID']['input'];
};

export type QueryUserAnswerHistoryArgs = {
  answerId: Scalars['ID']['input'];
  order?: InputMaybe<OrderEnum>;
};

export type QueryUserCoursesArgs = {
  filter: AdminUserCourseListFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<AdminUserListFilterInput>;
  limit: Scalars['Float']['input'];
  order: AdminUserListOrderInput;
  page: Scalars['Float']['input'];
};

export type QueryUsersAnswersArgs = {
  filter: AdminUserAnswerListFilterInput;
  limit: Scalars['Float']['input'];
  order: AdminUserAnswerListOrderInput;
  page: Scalars['Float']['input'];
};

/** Вопрос */
export type QuestionElement = Element & {
  __typename?: 'QuestionElement';
  createdAt: Scalars['Date']['output'];
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** Части элементов */
  elementParts?: Maybe<Array<ElementPart>>;
  /** Задание, к которому привязан элемент */
  exercise: Exercise;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFile>>;
  id: Scalars['ID']['output'];
  /** Баллы за вопрос */
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** Баллы за не правильный ответ */
  negativeScore?: Maybe<Scalars['Float']['output']>;
  /** Номер */
  number: Scalars['Float']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Подсказка в поле ответа */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Баллы за правильный ответ */
  positiveScore?: Maybe<Scalars['Float']['output']>;
  /** Тип вопроса */
  questionType: QuestionType;
  /** Тип автоматической проверки вопроса */
  scoreType: QuestionScoreType;
  /** Оглавление */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип элемента */
  type: ElementType;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователей */
  usersAnswers?: Maybe<Array<UserAnswer>>;
  /** Ответы пользователей */
  usersProgresses?: Maybe<Array<UserProgress>>;
};

export type QuestionElementInput = {
  /** Ответы */
  answers?: InputMaybe<Array<ElementPartInput>>;
  /** Описание */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Файлы для вопросов */
  files?: InputMaybe<Array<ElementFileInput>>;
  /** Баллы за вопрос */
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  /** Баллы за не правильный ответ */
  negativeScore?: InputMaybe<Scalars['Float']['input']>;
  /** Подсказка в поле ответа */
  placeholder?: InputMaybe<Scalars['String']['input']>;
  /** Баллы за правильный ответ */
  positiveScore?: InputMaybe<Scalars['Float']['input']>;
  /** Тип вопроса */
  questionType: QuestionType;
  /** Тип автоматической проверки вопроса */
  scoreType: QuestionScoreType;
  /** Оглавление */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionElementLkDto = ElementLkDto & {
  __typename?: 'QuestionElementLkDto';
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** Файлы элементов */
  elementParts?: Maybe<Array<ElementPartLkDto>>;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFileLkDto>>;
  id: Scalars['ID']['output'];
  /** Номер */
  number: Scalars['Float']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Подсказка в поле ответа */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Тип вопроса */
  questionType: QuestionType;
  /** Оглавление */
  title?: Maybe<Scalars['String']['output']>;
  /** Тип элемента */
  type: ElementType;
};

/** Тип автоматической проверки вопроса вопроса */
export enum QuestionScoreType {
  Full = 'FULL',
  Partial = 'PARTIAL',
}

/** Тип вопроса */
export enum QuestionType {
  FewAnswers = 'FEW_ANSWERS',
  FileAnswer = 'FILE_ANSWER',
  Matching = 'MATCHING',
  SingleAnswer = 'SINGLE_ANSWER',
  TextAnswers = 'TEXT_ANSWERS',
}

export type RefreshToken = {
  __typename?: 'RefreshToken';
  createdAt: Scalars['Date']['output'];
  /** Дата истечения токена */
  expireAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Пользователь токена */
  user: User;
  /** Id пользователя */
  userId: Scalars['ID']['output'];
};

export enum RoleName {
  Admin = 'ADMIN',
  Curator = 'CURATOR',
  User = 'USER',
}

export type ScoreType = {
  __typename?: 'ScoreType';
  /** Максимальное количество баллов */
  maxScore: Scalars['Float']['output'];
  /** Количество баллов */
  score?: Maybe<Scalars['Float']['output']>;
};

/** Текстовый элемент */
export type TextElement = Element & {
  __typename?: 'TextElement';
  /** Содержание */
  content: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  /** Части элементов */
  elementParts?: Maybe<Array<ElementPart>>;
  /** Задание, к которому привязан элемент */
  exercise: Exercise;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFile>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователей */
  usersAnswers?: Maybe<Array<UserAnswer>>;
  /** Ответы пользователей */
  usersProgresses?: Maybe<Array<UserProgress>>;
};

export type TextElementInput = {
  /** Содержание */
  content: Scalars['String']['input'];
};

export type TextElementLkDto = ElementLkDto & {
  __typename?: 'TextElementLkDto';
  /** Содержание */
  content: Scalars['String']['output'];
  /** Файлы элементов */
  elementParts?: Maybe<Array<ElementPartLkDto>>;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFileLkDto>>;
  id: Scalars['ID']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
};

export type Transaction = {
  __typename?: 'Transaction';
  /** Эквайринг транзакции */
  acquiring?: Maybe<Acquiring>;
  /** Id эквайринга */
  acquiringId?: Maybe<Scalars['ID']['output']>;
  amount: Scalars['Float']['output'];
  /** Покупаемый блок */
  block?: Maybe<Block>;
  /** Id блока */
  blockId?: Maybe<Scalars['ID']['output']>;
  /** Покупаемый курс */
  course?: Maybe<Course>;
  /** Id курса */
  courseId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orderNumber: Scalars['Float']['output'];
  status: TransactionStatus;
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum TransactionStatus {
  Canceled = 'CANCELED',
  New = 'NEW',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Rejected = 'REJECTED',
  Succeeded = 'SUCCEEDED',
  WaitingForCapture = 'WAITING_FOR_CAPTURE',
}

export enum UnavailableReason {
  CourseNotPurchased = 'COURSE_NOT_PURCHASED',
  NotPurchased = 'NOT_PURCHASED',
  NotTrial = 'NOT_TRIAL',
  Progress = 'PROGRESS',
  TimeExpired = 'TIME_EXPIRED',
  TimeNotStarted = 'TIME_NOT_STARTED',
}

export type UpdateBlockInput = {
  /** Тип доступа к блоку */
  availabilityType: BlockAvailabilityType;
  /** Стоимость блока */
  cost: Scalars['Float']['input'];
  /** Обложка блока */
  coverFile?: InputMaybe<Scalars['Upload']['input']>;
  /** Дата начала уроков блока */
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Дата окончания уроков блока */
  dateTo?: InputMaybe<Scalars['Date']['input']>;
  /** Id блока */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Данные уроков блока */
  lessons?: InputMaybe<Array<UpdateLessonInput>>;
  /** Название блока */
  title: Scalars['String']['input'];
};

export type UpdateBlocksInput = {
  /** Данные блоков курса */
  blocks: Array<UpdateBlockInput>;
  /** Id курса */
  courseId: Scalars['ID']['input'];
};

export type UpdateCourseCardInput = {
  /** Данные карточки рекламы */
  advertisingInput?: InputMaybe<CourseCardAdvertisingInput>;
  coverFile?: InputMaybe<Scalars['Upload']['input']>;
  /** Описание карточки */
  description: Scalars['String']['input'];
  /** Id карточки */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Ссылка карточки */
  link?: InputMaybe<Scalars['String']['input']>;
  /** Использовать ли обложку курса */
  useDefaultCover: Scalars['Boolean']['input'];
};

export type UpdateCourseCuratorInput = {
  /** Id курса */
  courseId: Scalars['ID']['input'];
  /** Входные данные кураторов курса */
  curatorsInput: Array<CuratorIdInput>;
};

export type UpdateCourseInput = {
  /** Id эквайринга */
  acquiringId?: InputMaybe<Scalars['ID']['input']>;
  /** ID цветовой темы */
  colorThemeId: Scalars['ID']['input'];
  /** Цена курса */
  cost: Scalars['Float']['input'];
  coverFile?: InputMaybe<Scalars['Upload']['input']>;
  /** Id курса при обновлении */
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Id курса при обновлении */
  dateTo?: InputMaybe<Scalars['Date']['input']>;
  /** Id курса при обновлении */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Класс предмета */
  grade: Scalars['Float']['input'];
  /** Id курса при обновлении */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Предмет курса */
  subject: CourseSubject;
  /** Название курса */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateElementsInput = {
  /** Данные элементов */
  elements: Array<ElementInput>;
  /** Id задания */
  exerciseId: Scalars['ID']['input'];
};

export type UpdateExerciseInput = {
  /** Дата начала задания */
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Дата окончания задания */
  dateTo?: InputMaybe<Scalars['Date']['input']>;
  /** Id задания */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Участвует лизадание в прогрессе */
  inProgress: Scalars['Boolean']['input'];
  /** Время прохождения задания в секундах */
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
  /** Заголовок задания */
  title: Scalars['String']['input'];
  /** ип задания */
  type: ExerciseType;
};

export type UpdateLessonInput = {
  /** Дата начала урока */
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Дата окончания урока */
  dateTo?: InputMaybe<Scalars['Date']['input']>;
  /** Задания в уроке */
  exercises?: InputMaybe<Array<UpdateExerciseInput>>;
  /** Id урока */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Название урока */
  title: Scalars['String']['input'];
  /** Доступен ли урок в пробной версии */
  trial: Scalars['Boolean']['input'];
};

export type UpdateUserDataInput = {
  /** Файл аватарки пользователя */
  avatarFile?: InputMaybe<Scalars['Upload']['input']>;
  /** Дата рождения пользователя */
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  /** Комментарий о пользователи внутри системы */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Имя пользователя */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Id пользователя */
  id: Scalars['ID']['input'];
  /** Фамилия пользователя */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Отчество пользователя */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Значение номера телефона */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  /** Записи истории изменений ответов */
  answersHistory?: Maybe<Array<UserAnswerHistory>>;
  /** Файл аватара пользователя */
  avatarFile?: Maybe<File>;
  /** ID файла аватара пользователя */
  avatarFileId?: Maybe<Scalars['ID']['output']>;
  /** Дата рождения пользователя */
  birthDate?: Maybe<Scalars['Date']['output']>;
  /** ID чата с ботом в telegram */
  botChatId?: Maybe<Scalars['Float']['output']>;
  /** Комментарий о пользователи внутри системы */
  comment?: Maybe<Scalars['String']['output']>;
  /** Дата последней покупки по курсу */
  courseLastPurchaseDate?: Maybe<Scalars['Date']['output']>;
  /** Количество платных блоков */
  coursePaidBlocksCount?: Maybe<Scalars['Float']['output']>;
  /** Дата приобретения курса */
  coursePurchaseDate?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Курсы куратора */
  curatorCourses?: Maybe<Array<CourseCurator>>;
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Заблокирован ли пользователь */
  isBlocked: Scalars['Boolean']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Числовой ID пользователя */
  numericId: Scalars['Int']['output'];
  /** Значение номера телефона */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** `Рефреш`-токены пользователя */
  refreshTokens?: Maybe<Array<RefreshToken>>;
  /** Название роли */
  role: RoleName;
  /** ID пользователя из telegram */
  telegramId: Scalars['Float']['output'];
  /** Username пользоателя из телеграма */
  telegramUsername?: Maybe<Scalars['String']['output']>;
  /** Транзакции пользователя */
  transactions?: Maybe<Array<Transaction>>;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователя */
  userAnswers?: Maybe<Array<UserAnswer>>;
  /** Курсы пользователя */
  userCourses?: Maybe<Array<UserCourse>>;
  /** Прогрес пользователя по элементам */
  userProgresses?: Maybe<Array<UserProgress>>;
};

export type UserCourseLastPurchaseDateArgs = {
  courseId: Scalars['ID']['input'];
};

export type UserCoursePaidBlocksCountArgs = {
  courseId: Scalars['ID']['input'];
};

export type UserCoursePurchaseDateArgs = {
  courseId: Scalars['ID']['input'];
};

export type UserAnswer = {
  __typename?: 'UserAnswer';
  /** Ответ пользователя */
  answer?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Элемент */
  element: Element;
  /** ID элемента */
  elementId: Scalars['ID']['output'];
  /** Часть элемента */
  elementPart?: Maybe<ElementPart>;
  /** ID части элемента */
  elementPartId?: Maybe<Scalars['ID']['output']>;
  /** Файлы */
  files?: Maybe<Array<UserAnswerFile>>;
  /** История изменений ответа */
  history?: Maybe<Array<UserAnswerHistory>>;
  id: Scalars['ID']['output'];
  /** Ответ пользователя */
  status?: Maybe<UserAnswerStatus>;
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user: User;
  /** ID пользователя */
  userId: Scalars['ID']['output'];
};

/** Файл, прикрепленный к ответу */
export type UserAnswerFile = {
  __typename?: 'UserAnswerFile';
  createdAt: Scalars['Date']['output'];
  /** Прикрепленный файл */
  file: File;
  /** ID прикрепленного файла */
  fileId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Ответ */
  userAnswer: UserAnswer;
  /** ID ответа */
  userAnswerId: Scalars['ID']['output'];
};

export type UserAnswerFileLkDto = {
  __typename?: 'UserAnswerFileLkDto';
  /** Прикрепленный файл */
  file: FileLkDto;
  id: Scalars['ID']['output'];
};

export type UserAnswerHistory = {
  __typename?: 'UserAnswerHistory';
  answer: UserAnswer;
  answerId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  /** Файлы истории */
  files?: Maybe<Array<UserAnswerHistoryFile>>;
  id: Scalars['ID']['output'];
  /** Сообщение */
  message?: Maybe<Scalars['String']['output']>;
  /** Новый статус */
  newStatus?: Maybe<UserAnswerStatus>;
  /** Проставленные баллы */
  score?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserAnswerHistoryFile = {
  __typename?: 'UserAnswerHistoryFile';
  createdAt: Scalars['Date']['output'];
  /** Прикрепленный файл */
  file: File;
  /** ID прикрепленного файла */
  fileId: Scalars['ID']['output'];
  /** История изменений */
  history: UserAnswerHistory;
  /** ID истории */
  historyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type UserAnswerHistoryFileLkDto = {
  __typename?: 'UserAnswerHistoryFileLkDto';
  /** Прикрепленный файл */
  file: FileLkDto;
  /** ID истории */
  historyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type UserAnswerHistoryLkDto = {
  __typename?: 'UserAnswerHistoryLkDto';
  /** Файлы истории */
  files?: Maybe<Array<UserAnswerHistoryFileLkDto>>;
  id: Scalars['ID']['output'];
  /** Сообщение */
  message?: Maybe<Scalars['String']['output']>;
  /** Новый статус */
  newStatus?: Maybe<UserAnswerStatus>;
  /** Проставленные баллы */
  score?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user?: Maybe<PreviewUserLkDto>;
};

export enum UserAnswerStatus {
  Autocomplete = 'AUTOCOMPLETE',
  Complete = 'COMPLETE',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  ReplyReceived = 'REPLY_RECEIVED',
}

export type UserBlock = {
  __typename?: 'UserBlock';
  /** Блок */
  block: Block;
  /** ID блока */
  blockId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Способ приобретения */
  type: UserBlockType;
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user: User;
  /** ID пользователя */
  userId: Scalars['ID']['output'];
};

export enum UserBlockType {
  Issued = 'ISSUED',
  Paid = 'PAID',
  Trial = 'TRIAL',
}

export type UserCourse = {
  __typename?: 'UserCourse';
  /** Курс */
  course: Course;
  /** ID курса */
  courseId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Тип доступа */
  type: UserCourseType;
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user: User;
  /** ID пользователя */
  userId: Scalars['ID']['output'];
};

export enum UserCourseType {
  Full = 'FULL',
  Partial = 'PARTIAL',
  Trial = 'TRIAL',
}

export type UserProgress = {
  __typename?: 'UserProgress';
  createdAt: Scalars['Date']['output'];
  /** Элемент */
  element: Element;
  /** ID элемента */
  elementId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Положительный ли прогресс по элементу */
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  /** Баллы полученные за элемент */
  score?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user: User;
  /** ID пользователя */
  userId: Scalars['ID']['output'];
};

/** Видео элемент */
export type VideoElement = Element & {
  __typename?: 'VideoElement';
  createdAt: Scalars['Date']['output'];
  /** Части элементов */
  elementParts?: Maybe<Array<ElementPart>>;
  /** Задание, к которому привязан элемент */
  exercise: Exercise;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFile>>;
  id: Scalars['ID']['output'];
  /** ID видео на киноскопе */
  kinescopeId: Scalars['String']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
  updatedAt: Scalars['Date']['output'];
  /** Ответы пользователей */
  usersAnswers?: Maybe<Array<UserAnswer>>;
  /** Ответы пользователей */
  usersProgresses?: Maybe<Array<UserProgress>>;
};

export type VideoElementInput = {
  /** ID видео на киноскопе */
  kinescopeId: Scalars['String']['input'];
};

export type VideoElementLkDto = ElementLkDto & {
  __typename?: 'VideoElementLkDto';
  /** Файлы элементов */
  elementParts?: Maybe<Array<ElementPartLkDto>>;
  /** ID задания */
  exerciseId: Scalars['ID']['output'];
  /** Файлы элементов */
  files?: Maybe<Array<ElementFileLkDto>>;
  id: Scalars['ID']['output'];
  /** ID видео на киноскопе */
  kinescopeId: Scalars['String']['output'];
  /** Порядковый номер элемента */
  order: Scalars['Int']['output'];
  /** Тип элемента */
  type: ElementType;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatarFile?: { __typename?: 'File'; id: string; url: string } | null;
  };
};

export type LoginByCredentialsMutationVariables = Exact<{
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}>;

export type LoginByCredentialsMutation = {
  __typename?: 'Mutation';
  loginByCredentials: { __typename?: 'AuthTokensDto'; accessToken: string; refreshToken: string };
};

export type AddUserInCourseMutationVariables = Exact<{
  input: AddUserInCourseInput;
}>;

export type AddUserInCourseMutation = { __typename?: 'Mutation'; addUserInCourse: boolean };

export type CopyCourseMutationVariables = Exact<{
  input: CopyCourseInput;
}>;

export type CopyCourseMutation = { __typename?: 'Mutation'; copyCourse: { __typename?: 'Course'; id: string } };

export type CreateOrUpdateCourseMutationVariables = Exact<{
  input: UpdateCourseInput;
}>;

export type CreateOrUpdateCourseMutation = {
  __typename?: 'Mutation';
  createOrUpdateCourse: {
    __typename?: 'Course';
    id: string;
    title?: string | null;
    subject: CourseSubject;
    description?: string | null;
    dateFrom?: any | null;
    dateTo?: any | null;
    grade: number;
    cost: number;
    colorTheme?: { __typename?: 'ColorTheme'; id: string; color: string } | null;
    coverFile?: { __typename?: 'File'; id: string; url: string } | null;
    acquiring?: { __typename?: 'Acquiring'; id: string; type: AcquiringType } | null;
  };
};

export type CreateUserBlockMutationVariables = Exact<{
  input: CreateUserBlockInput;
}>;

export type CreateUserBlockMutation = {
  __typename?: 'Mutation';
  createUserBlock: { __typename?: 'UserBlock'; id: string; createdAt: any; type: UserBlockType };
};

export type DeleteCourseMutationVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;

export type DeleteCourseMutation = { __typename?: 'Mutation'; deleteCourse: boolean };

export type DeleteCourseCardMutationVariables = Exact<{
  courseCardId: Scalars['ID']['input'];
}>;

export type DeleteCourseCardMutation = { __typename?: 'Mutation'; deleteCourseCard: boolean };

export type DeleteUserBlockMutationVariables = Exact<{
  blockId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type DeleteUserBlockMutation = { __typename?: 'Mutation'; deleteUserBlock: boolean };

export type PublishOrHideCourseMutationVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;

export type PublishOrHideCourseMutation = {
  __typename?: 'Mutation';
  publishOrHideCourse: { __typename?: 'Course'; id: string; isHidden: boolean };
};

export type PublishOrHideCourseCardMutationVariables = Exact<{
  courseCardId: Scalars['ID']['input'];
}>;

export type PublishOrHideCourseCardMutation = {
  __typename?: 'Mutation';
  publishOrHideCourseCard: { __typename?: 'CourseCard'; id: string; isHidden: boolean };
};

export type UpdateBlocksMutationVariables = Exact<{
  input: UpdateBlocksInput;
}>;

export type UpdateBlocksMutation = {
  __typename?: 'Mutation';
  updateBlocks: Array<{
    __typename?: 'Block';
    id: string;
    title: string;
    availabilityType: BlockAvailabilityType;
    cost: number;
    dateFrom?: any | null;
    dateTo?: any | null;
    coverFile?: { __typename?: 'File'; id: string; url: string } | null;
    lessons?: Array<{
      __typename?: 'Lesson';
      id: string;
      title: string;
      trial: boolean;
      dateTo?: any | null;
      dateFrom?: any | null;
      exercises?: Array<{
        __typename?: 'Exercise';
        id: string;
        title: string;
        type: ExerciseType;
        inProgress: boolean;
        timeLimit?: number | null;
      }> | null;
    }> | null;
  }>;
};

export type UpdateBlocksPaymentInfoMutationVariables = Exact<{
  input: UpdateBlocksInput;
}>;

export type UpdateBlocksPaymentInfoMutation = {
  __typename?: 'Mutation';
  updateBlocks: Array<{
    __typename?: 'Block';
    id: string;
    title: string;
    availabilityType: BlockAvailabilityType;
    cost: number;
  }>;
};

export type UpdateCourseCardMutationVariables = Exact<{
  input: Array<UpdateCourseCardInput> | UpdateCourseCardInput;
}>;

export type UpdateCourseCardMutation = {
  __typename?: 'Mutation';
  updateCourseCard: Array<{
    __typename?: 'CourseCard';
    id: string;
    description: string;
    isHidden: boolean;
    useDefaultCover: boolean;
    type: CourseCardType;
    link?: string | null;
    coverFile?: { __typename?: 'File'; id: string; url: string } | null;
    advertising?: Array<{
      __typename?: 'CourseCardAdvertising';
      id: string;
      course: { __typename?: 'Course'; id: string; title?: string | null };
    }> | null;
  }>;
};

export type UpdateCourseCuratorsMutationVariables = Exact<{
  input: UpdateCourseCuratorInput;
}>;

export type UpdateCourseCuratorsMutation = {
  __typename?: 'Mutation';
  updateCourseCurators: Array<{
    __typename?: 'CourseCurator';
    id: string;
    user: {
      __typename?: 'User';
      id: string;
      numericId: number;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      role: RoleName;
      avatarFile?: { __typename?: 'File'; id: string; url: string } | null;
    };
  }>;
};

export type UpdateCoursePaymentInfoMutationVariables = Exact<{
  input: UpdateCourseInput;
}>;

export type UpdateCoursePaymentInfoMutation = {
  __typename?: 'Mutation';
  createOrUpdateCourse: {
    __typename?: 'Course';
    id: string;
    cost: number;
    colorThemeId?: string | null;
    grade: number;
    subject: CourseSubject;
    acquiring?: { __typename?: 'Acquiring'; id: string; title: string } | null;
  };
};

export type UpdateElementsMutationVariables = Exact<{
  input: UpdateElementsInput;
}>;

export type UpdateElementsMutation = {
  __typename?: 'Mutation';
  updateElements: {
    __typename?: 'Exercise';
    id: string;
    elements?: Array<
      | { __typename?: 'ExerciseLinkElement'; description?: string | null; id: string; type: ElementType }
      | {
          __typename?: 'FeedbackElement';
          id: string;
          type: ElementType;
          elementParts?: Array<{ __typename?: 'ElementPart'; content?: string | null }> | null;
        }
      | {
          __typename?: 'FileElement';
          id: string;
          type: ElementType;
          files?: Array<{
            __typename?: 'ElementFile';
            id: string;
            type: ElementFileType;
            fileName: string;
            file: { __typename?: 'File'; id: string; name: string; url: string };
          }> | null;
        }
      | { __typename?: 'HeadingElement'; title?: string | null; id: string; type: ElementType }
      | {
          __typename?: 'QuestionElement';
          questionType: QuestionType;
          title?: string | null;
          description?: string | null;
          placeholder?: string | null;
          maxScore?: number | null;
          scoreType: QuestionScoreType;
          negativeScore?: number | null;
          positiveScore?: number | null;
          id: string;
          type: ElementType;
          elementParts?: Array<{
            __typename?: 'ElementPart';
            id: string;
            correctAnswer?: string | null;
            isCorrect?: boolean | null;
            content?: string | null;
          }> | null;
          files?: Array<{
            __typename?: 'ElementFile';
            id: string;
            fileName: string;
            file: { __typename?: 'File'; id: string; name: string; url: string };
          }> | null;
        }
      | { __typename?: 'TextElement'; content: string; id: string; type: ElementType }
      | {
          __typename?: 'VideoElement';
          kinescopeId: string;
          id: string;
          type: ElementType;
        }
    > | null;
  };
};

export type AcquiringInstancesQueryVariables = Exact<{
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type AcquiringInstancesQuery = {
  __typename?: 'Query';
  acquiringInstances: {
    __typename?: 'PaginatedAcquiring';
    totalCount: number;
    data?: Array<{ __typename?: 'Acquiring'; id: string; title: string }> | null;
  };
};

export type BlocksQueryVariables = Exact<{
  filter: AdminBlockListFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type BlocksQuery = {
  __typename?: 'Query';
  blocks: {
    __typename?: 'PaginatedBlock';
    totalCount: number;
    data?: Array<{
      __typename?: 'Block';
      id: string;
      title: string;
      availabilityType: BlockAvailabilityType;
      dateFrom?: any | null;
      dateTo?: any | null;
      cost: number;
      coverFile?: { __typename?: 'File'; id: string; url: string } | null;
      lessons?: Array<{
        __typename?: 'Lesson';
        id: string;
        title: string;
        trial: boolean;
        dateTo?: any | null;
        dateFrom?: any | null;
        exercises?: Array<{
          __typename?: 'Exercise';
          id: string;
          title: string;
          type: ExerciseType;
          inProgress: boolean;
          timeLimit?: number | null;
        }> | null;
      }> | null;
    }> | null;
  };
};

export type ColorThemesQueryVariables = Exact<{
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type ColorThemesQuery = {
  __typename?: 'Query';
  colorThemes: {
    __typename?: 'PaginatedColorTheme';
    totalCount: number;
    data?: Array<{ __typename?: 'ColorTheme'; id: string; color: string }> | null;
  };
};

export type CourseCardsQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
  filter: CourseCardsListFilterInput;
}>;

export type CourseCardsQuery = {
  __typename?: 'Query';
  courseCards: Array<{
    __typename?: 'CourseCard';
    id: string;
    description: string;
    isHidden: boolean;
    useDefaultCover: boolean;
    type: CourseCardType;
    link?: string | null;
    coverFile?: { __typename?: 'File'; id: string; url: string } | null;
    course: { __typename?: 'Course'; id: string; title?: string | null };
    advertising?: Array<{
      __typename?: 'CourseCardAdvertising';
      id: string;
      course: { __typename?: 'Course'; id: string; title?: string | null };
    }> | null;
  }>;
};

export type CourseCuratorsQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;

export type CourseCuratorsQuery = {
  __typename?: 'Query';
  courseCurators?: Array<{
    __typename?: 'CourseCurator';
    id: string;
    user: {
      __typename?: 'User';
      id: string;
      numericId: number;
      lastName?: string | null;
      firstName?: string | null;
      middleName?: string | null;
      role: RoleName;
      avatarFile?: { __typename?: 'File'; id: string; url: string } | null;
    };
  }> | null;
};

export type GetCourseDescriptionQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;

export type GetCourseDescriptionQuery = {
  __typename?: 'Query';
  course: {
    __typename?: 'Course';
    id: string;
    title?: string | null;
    subject: CourseSubject;
    cost: number;
    description?: string | null;
    dateFrom?: any | null;
    dateTo?: any | null;
    grade: number;
    colorTheme?: { __typename?: 'ColorTheme'; id: string; color: string } | null;
    coverFile?: { __typename?: 'File'; id: string; url: string } | null;
    acquiring?: { __typename?: 'Acquiring'; id: string; title: string } | null;
  };
};

export type GetCourseHeaderQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;

export type GetCourseHeaderQuery = {
  __typename?: 'Query';
  course: { __typename?: 'Course'; id: string; title?: string | null; isHidden: boolean; numericId: number };
};

export type CourseNavigationQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;

export type CourseNavigationQuery = {
  __typename?: 'Query';
  course: {
    __typename?: 'Course';
    id: string;
    title?: string | null;
    blocks?: Array<{
      __typename?: 'Block';
      id: string;
      title: string;
      lessons?: Array<{
        __typename?: 'Lesson';
        id: string;
        title: string;
        exercises?: Array<{ __typename?: 'Exercise'; id: string; title: string; type: ExerciseType }> | null;
      }> | null;
    }> | null;
  };
};

export type CourseUsersQueryVariables = Exact<{
  filter?: InputMaybe<AdminCourseUsersFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  courseId: Scalars['ID']['input'];
}>;

export type CourseUsersQuery = {
  __typename?: 'Query';
  courseUsers: {
    __typename?: 'PaginatedUser';
    totalCount: number;
    data?: Array<{
      __typename?: 'User';
      id: string;
      numericId: number;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      coursePurchaseDate?: any | null;
      coursePaidBlocksCount?: number | null;
      courseLastPurchaseDate?: any | null;
    }> | null;
  };
  course: { __typename?: 'Course'; id: string; title?: string | null };
};

export type CoursesQueryVariables = Exact<{
  filter?: InputMaybe<AdminCourseListFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type CoursesQuery = {
  __typename?: 'Query';
  courses: {
    __typename?: 'PaginatedCourse';
    totalCount: number;
    data?: Array<{
      __typename?: 'Course';
      id: string;
      numericId: number;
      title?: string | null;
      subject: CourseSubject;
      dateFrom?: any | null;
      dateTo?: any | null;
      isHidden: boolean;
      createdAt: any;
      usersCount?: number | null;
    }> | null;
  };
};

export type CoursesListQueryVariables = Exact<{
  filter?: InputMaybe<AdminCourseListFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type CoursesListQuery = {
  __typename?: 'Query';
  courses: {
    __typename?: 'PaginatedCourse';
    data?: Array<{ __typename?: 'Course'; id: string; title?: string | null }> | null;
  };
};

export type GetExerciseElementsQueryVariables = Exact<{
  exerciseId: Scalars['ID']['input'];
}>;

export type GetExerciseElementsQuery = {
  __typename?: 'Query';
  exercise?: {
    __typename?: 'Exercise';
    id: string;
    title: string;
    type: ExerciseType;
    lesson: {
      __typename?: 'Lesson';
      id: string;
      title: string;
      block: {
        __typename?: 'Block';
        id: string;
        title: string;
        course: { __typename?: 'Course'; id: string; title?: string | null };
      };
    };
    elements?: Array<
      | { __typename?: 'ExerciseLinkElement'; description?: string | null; id: string; type: ElementType }
      | {
          __typename?: 'FeedbackElement';
          id: string;
          type: ElementType;
          elementParts?: Array<{ __typename?: 'ElementPart'; content?: string | null }> | null;
        }
      | {
          __typename?: 'FileElement';
          id: string;
          type: ElementType;
          files?: Array<{
            __typename?: 'ElementFile';
            id: string;
            type: ElementFileType;
            fileName: string;
            file: { __typename?: 'File'; id: string; name: string; url: string };
          }> | null;
        }
      | { __typename?: 'HeadingElement'; title?: string | null; id: string; type: ElementType }
      | {
          __typename?: 'QuestionElement';
          questionType: QuestionType;
          title?: string | null;
          description?: string | null;
          placeholder?: string | null;
          maxScore?: number | null;
          scoreType: QuestionScoreType;
          negativeScore?: number | null;
          positiveScore?: number | null;
          id: string;
          type: ElementType;
          elementParts?: Array<{
            __typename?: 'ElementPart';
            id: string;
            correctAnswer?: string | null;
            isCorrect?: boolean | null;
            content?: string | null;
          }> | null;
          files?: Array<{
            __typename?: 'ElementFile';
            id: string;
            fileName: string;
            file: { __typename?: 'File'; id: string; name: string; url: string };
          }> | null;
        }
      | { __typename?: 'TextElement'; content: string; id: string; type: ElementType }
      | {
          __typename?: 'VideoElement';
          kinescopeId: string;
          id: string;
          type: ElementType;
        }
    > | null;
  } | null;
};

export type PaymentQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
  filter: AdminBlockListFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type PaymentQuery = {
  __typename?: 'Query';
  course: {
    __typename?: 'Course';
    id: string;
    cost: number;
    colorThemeId?: string | null;
    grade: number;
    subject: CourseSubject;
    acquiring?: { __typename?: 'Acquiring'; id: string; title: string } | null;
  };
  blocks: {
    __typename?: 'PaginatedBlock';
    data?: Array<{
      __typename?: 'Block';
      id: string;
      title: string;
      availabilityType: BlockAvailabilityType;
      cost: number;
    }> | null;
  };
};

export type UserBlocksQueryVariables = Exact<{
  filter: AdminBlockListFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  userId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
}>;

export type UserBlocksQuery = {
  __typename?: 'Query';
  blocks: {
    __typename?: 'PaginatedBlock';
    totalCount: number;
    data?: Array<{
      __typename?: 'Block';
      id: string;
      title: string;
      cost: number;
      userBlock?: { __typename?: 'UserBlock'; id: string; createdAt: any; type: UserBlockType } | null;
      userLastTransaction?: { __typename?: 'Transaction'; id: string; createdAt: any } | null;
    }> | null;
  };
  user: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
  };
  course: { __typename?: 'Course'; id: string; title?: string | null };
};

export type UserListQueryVariables = Exact<{
  filter?: InputMaybe<AdminUserListFilterInput>;
  limit: Scalars['Float']['input'];
  order: AdminUserListOrderInput;
  page: Scalars['Float']['input'];
}>;

export type UserListQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'PaginatedUser';
    data?: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      numericId: number;
    }> | null;
  };
};

export type ProcessAnswerMutationVariables = Exact<{
  input?: InputMaybe<ProcessAnswerInput>;
}>;

export type ProcessAnswerMutation = {
  __typename?: 'Mutation';
  processAnswer?: {
    __typename?: 'UserAnswer';
    id: string;
    status?: UserAnswerStatus | null;
    history?: Array<{
      __typename?: 'UserAnswerHistory';
      id: string;
      newStatus?: UserAnswerStatus | null;
      score?: number | null;
      message?: string | null;
      createdAt: any;
      updatedAt: any;
      user?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
      } | null;
      answer: { __typename?: 'UserAnswer'; id: string; answer?: string | null };
    }> | null;
  } | null;
};

export type UserAnswerQueryVariables = Exact<{
  userAnswerId: Scalars['ID']['input'];
}>;

export type UserAnswerQuery = {
  __typename?: 'Query';
  userAnswer?: {
    __typename?: 'UserAnswer';
    id: string;
    createdAt: any;
    answer?: string | null;
    status?: UserAnswerStatus | null;
    files?: Array<{
      __typename?: 'UserAnswerFile';
      file: { __typename?: 'File'; id: string; url: string; name: string };
    }> | null;
    user: {
      __typename?: 'User';
      id: string;
      numericId: number;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
    };
    element:
      | {
          __typename?: 'ExerciseLinkElement';
          id: string;
          exercise: {
            __typename?: 'Exercise';
            id: string;
            lesson: {
              __typename?: 'Lesson';
              id: string;
              title: string;
              block: {
                __typename?: 'Block';
                id: string;
                title: string;
                course: { __typename?: 'Course'; id: string; title?: string | null };
              };
            };
          };
        }
      | {
          __typename?: 'FeedbackElement';
          id: string;
          exercise: {
            __typename?: 'Exercise';
            id: string;
            lesson: {
              __typename?: 'Lesson';
              id: string;
              title: string;
              block: {
                __typename?: 'Block';
                id: string;
                title: string;
                course: { __typename?: 'Course'; id: string; title?: string | null };
              };
            };
          };
        }
      | {
          __typename?: 'FileElement';
          id: string;
          exercise: {
            __typename?: 'Exercise';
            id: string;
            lesson: {
              __typename?: 'Lesson';
              id: string;
              title: string;
              block: {
                __typename?: 'Block';
                id: string;
                title: string;
                course: { __typename?: 'Course'; id: string; title?: string | null };
              };
            };
          };
        }
      | {
          __typename?: 'HeadingElement';
          id: string;
          exercise: {
            __typename?: 'Exercise';
            id: string;
            lesson: {
              __typename?: 'Lesson';
              id: string;
              title: string;
              block: {
                __typename?: 'Block';
                id: string;
                title: string;
                course: { __typename?: 'Course'; id: string; title?: string | null };
              };
            };
          };
        }
      | {
          __typename?: 'QuestionElement';
          title?: string | null;
          description?: string | null;
          maxScore?: number | null;
          id: string;
          exercise: {
            __typename?: 'Exercise';
            id: string;
            lesson: {
              __typename?: 'Lesson';
              id: string;
              title: string;
              block: {
                __typename?: 'Block';
                id: string;
                title: string;
                course: { __typename?: 'Course'; id: string; title?: string | null };
              };
            };
          };
        }
      | {
          __typename?: 'TextElement';
          id: string;
          exercise: {
            __typename?: 'Exercise';
            id: string;
            lesson: {
              __typename?: 'Lesson';
              id: string;
              title: string;
              block: {
                __typename?: 'Block';
                id: string;
                title: string;
                course: { __typename?: 'Course'; id: string; title?: string | null };
              };
            };
          };
        }
      | {
          __typename?: 'VideoElement';
          id: string;
          exercise: {
            __typename?: 'Exercise';
            id: string;
            lesson: {
              __typename?: 'Lesson';
              id: string;
              title: string;
              block: {
                __typename?: 'Block';
                id: string;
                title: string;
                course: { __typename?: 'Course'; id: string; title?: string | null };
              };
            };
          };
        };
  } | null;
  userAnswerHistory?: Array<{
    __typename?: 'UserAnswerHistory';
    id: string;
    newStatus?: UserAnswerStatus | null;
    message?: string | null;
    score?: number | null;
    createdAt: any;
    user?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      avatarFile?: { __typename?: 'File'; id: string; url: string } | null;
    } | null;
    answer: { __typename?: 'UserAnswer'; id: string; status?: UserAnswerStatus | null; answer?: string | null };
    files?: Array<{
      __typename?: 'UserAnswerHistoryFile';
      id: string;
      file: { __typename?: 'File'; id: string; url: string; name: string };
    }> | null;
  }> | null;
};

export type UserAnswerHeaderQueryVariables = Exact<{
  userAnswerId: Scalars['ID']['input'];
}>;

export type UserAnswerHeaderQuery = {
  __typename?: 'Query';
  userAnswer?: {
    __typename?: 'UserAnswer';
    id: string;
    status?: UserAnswerStatus | null;
    user: {
      __typename?: 'User';
      id: string;
      numericId: number;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
    };
  } | null;
};

export type UserAnswerHistoryQueryVariables = Exact<{
  answerId: Scalars['ID']['input'];
  order?: InputMaybe<OrderEnum>;
}>;

export type UserAnswerHistoryQuery = {
  __typename?: 'Query';
  userAnswerHistory?: Array<{
    __typename?: 'UserAnswerHistory';
    id: string;
    newStatus?: UserAnswerStatus | null;
    createdAt: any;
    user?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
    } | null;
  }> | null;
};

export type UserExercisesQueryVariables = Exact<{
  filter?: InputMaybe<AdminExerciseListFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  userId: Scalars['ID']['input'];
}>;

export type UserExercisesQuery = {
  __typename?: 'Query';
  exercises?: {
    __typename?: 'PaginatedExercises';
    totalCount: number;
    data?: Array<{
      __typename?: 'Exercise';
      id: string;
      title: string;
      userFileAnswer?: {
        __typename?: 'UserAnswer';
        id: string;
        createdAt: any;
        updatedAt: any;
        status?: UserAnswerStatus | null;
      } | null;
      userStatistic: {
        __typename?: 'ExerciseStatistic';
        scoreA?: number | null;
        scoreB?: number | null;
        maxScoreB?: number | null;
        maxScoreA?: number | null;
      };
    }> | null;
  } | null;
  user: {
    __typename?: 'User';
    id: string;
    numericId: number;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
  };
};

export type UsersAnswersQueryVariables = Exact<{
  filter: AdminUserAnswerListFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  order: AdminUserAnswerListOrderInput;
}>;

export type UsersAnswersQuery = {
  __typename?: 'Query';
  usersAnswers?: {
    __typename?: 'PaginatedUserAnswers';
    totalCount: number;
    data?: Array<{
      __typename?: 'UserAnswer';
      id: string;
      updatedAt: any;
      status?: UserAnswerStatus | null;
      element:
        | {
            __typename?: 'ExerciseLinkElement';
            id: string;
            exercise: {
              __typename?: 'Exercise';
              id: string;
              lesson: {
                __typename?: 'Lesson';
                id: string;
                block: {
                  __typename?: 'Block';
                  id: string;
                  course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
                };
              };
            };
          }
        | {
            __typename?: 'FeedbackElement';
            id: string;
            exercise: {
              __typename?: 'Exercise';
              id: string;
              lesson: {
                __typename?: 'Lesson';
                id: string;
                block: {
                  __typename?: 'Block';
                  id: string;
                  course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
                };
              };
            };
          }
        | {
            __typename?: 'FileElement';
            id: string;
            exercise: {
              __typename?: 'Exercise';
              id: string;
              lesson: {
                __typename?: 'Lesson';
                id: string;
                block: {
                  __typename?: 'Block';
                  id: string;
                  course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
                };
              };
            };
          }
        | {
            __typename?: 'HeadingElement';
            id: string;
            exercise: {
              __typename?: 'Exercise';
              id: string;
              lesson: {
                __typename?: 'Lesson';
                id: string;
                block: {
                  __typename?: 'Block';
                  id: string;
                  course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
                };
              };
            };
          }
        | {
            __typename?: 'QuestionElement';
            id: string;
            exercise: {
              __typename?: 'Exercise';
              id: string;
              lesson: {
                __typename?: 'Lesson';
                id: string;
                block: {
                  __typename?: 'Block';
                  id: string;
                  course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
                };
              };
            };
          }
        | {
            __typename?: 'TextElement';
            id: string;
            exercise: {
              __typename?: 'Exercise';
              id: string;
              lesson: {
                __typename?: 'Lesson';
                id: string;
                block: {
                  __typename?: 'Block';
                  id: string;
                  course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
                };
              };
            };
          }
        | {
            __typename?: 'VideoElement';
            id: string;
            exercise: {
              __typename?: 'Exercise';
              id: string;
              lesson: {
                __typename?: 'Lesson';
                id: string;
                block: {
                  __typename?: 'Block';
                  id: string;
                  course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
                };
              };
            };
          };
      user: {
        __typename?: 'User';
        id: string;
        numericId: number;
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
      };
    }> | null;
  } | null;
};

export type BlockOrUnblockUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type BlockOrUnblockUserMutation = {
  __typename?: 'Mutation';
  blockOrUnblockUser: { __typename?: 'User'; isBlocked: boolean; id: string };
};

export type ChangeRoleMutationVariables = Exact<{
  role: RoleName;
  userId: Scalars['ID']['input'];
}>;

export type ChangeRoleMutation = {
  __typename?: 'Mutation';
  changeRole: { __typename?: 'GeneratePasswordResult'; password?: string | null; phoneNumber?: string | null };
};

export type GenerateUserPasswordMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type GenerateUserPasswordMutation = {
  __typename?: 'Mutation';
  generateUserPassword: {
    __typename?: 'GeneratePasswordResult';
    phoneNumber?: string | null;
    password?: string | null;
  };
};

export type RemoveUserFromCourseMutationVariables = Exact<{
  courseId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type RemoveUserFromCourseMutation = { __typename?: 'Mutation'; removeUserFromCourse: boolean };

export type UpdateUserDataMutationVariables = Exact<{
  input: UpdateUserDataInput;
}>;

export type UpdateUserDataMutation = {
  __typename?: 'Mutation';
  updateUserData: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    phoneNumber?: string | null;
    comment?: string | null;
    birthDate?: any | null;
    avatarFile?: { __typename?: 'File'; id: string; url: string } | null;
  };
};

export type UserQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    phoneNumber?: string | null;
    comment?: string | null;
    birthDate?: any | null;
    avatarFile?: { __typename?: 'File'; id: string; url: string } | null;
  };
};

export type UserCoursesQueryVariables = Exact<{
  filter: AdminUserCourseListFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type UserCoursesQuery = {
  __typename?: 'Query';
  userCourses: {
    __typename?: 'PaginatedUsersCourse';
    totalCount: number;
    data?: Array<{
      __typename?: 'UserCourse';
      id: string;
      courseId: string;
      userId: string;
      type: UserCourseType;
      createdAt: any;
      course: { __typename?: 'Course'; id: string; numericId: number; title?: string | null; grade: number };
    }> | null;
  };
};

export type UserHeaderQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type UserHeaderQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    numericId: number;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    isBlocked: boolean;
    role: RoleName;
  };
};

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<AdminUserListFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  order: AdminUserListOrderInput;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'PaginatedUser';
    totalCount: number;
    data?: Array<{
      __typename?: 'User';
      id: string;
      numericId: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      createdAt: any;
      role: RoleName;
      avatarFile?: { __typename?: 'File'; id: string; url: string } | null;
    }> | null;
  };
};

export const MeDocument = gql`
  query me {
    me {
      id
      firstName
      lastName
      avatarFile {
        id
        url
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}

export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}

export function useMeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}

export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginByCredentialsDocument = gql`
  mutation loginByCredentials($password: String!, $phoneNumber: String!) {
    loginByCredentials(password: $password, phoneNumber: $phoneNumber) {
      accessToken
      refreshToken
    }
  }
`;
export type LoginByCredentialsMutationFn = Apollo.MutationFunction<
  LoginByCredentialsMutation,
  LoginByCredentialsMutationVariables
>;

/**
 * __useLoginByCredentialsMutation__
 *
 * To run a mutation, you first call `useLoginByCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByCredentialsMutation, { data, loading, error }] = useLoginByCredentialsMutation({
 *   variables: {
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useLoginByCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginByCredentialsMutation, LoginByCredentialsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginByCredentialsMutation, LoginByCredentialsMutationVariables>(
    LoginByCredentialsDocument,
    options,
  );
}

export type LoginByCredentialsMutationHookResult = ReturnType<typeof useLoginByCredentialsMutation>;
export type LoginByCredentialsMutationResult = Apollo.MutationResult<LoginByCredentialsMutation>;
export type LoginByCredentialsMutationOptions = Apollo.BaseMutationOptions<
  LoginByCredentialsMutation,
  LoginByCredentialsMutationVariables
>;
export const AddUserInCourseDocument = gql`
  mutation addUserInCourse($input: AddUserInCourseInput!) {
    addUserInCourse(input: $input)
  }
`;
export type AddUserInCourseMutationFn = Apollo.MutationFunction<
  AddUserInCourseMutation,
  AddUserInCourseMutationVariables
>;

/**
 * __useAddUserInCourseMutation__
 *
 * To run a mutation, you first call `useAddUserInCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserInCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserInCourseMutation, { data, loading, error }] = useAddUserInCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserInCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUserInCourseMutation, AddUserInCourseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserInCourseMutation, AddUserInCourseMutationVariables>(
    AddUserInCourseDocument,
    options,
  );
}

export type AddUserInCourseMutationHookResult = ReturnType<typeof useAddUserInCourseMutation>;
export type AddUserInCourseMutationResult = Apollo.MutationResult<AddUserInCourseMutation>;
export type AddUserInCourseMutationOptions = Apollo.BaseMutationOptions<
  AddUserInCourseMutation,
  AddUserInCourseMutationVariables
>;
export const CopyCourseDocument = gql`
  mutation copyCourse($input: CopyCourseInput!) {
    copyCourse(input: $input) {
      id
    }
  }
`;
export type CopyCourseMutationFn = Apollo.MutationFunction<CopyCourseMutation, CopyCourseMutationVariables>;

/**
 * __useCopyCourseMutation__
 *
 * To run a mutation, you first call `useCopyCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyCourseMutation, { data, loading, error }] = useCopyCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyCourseMutation, CopyCourseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CopyCourseMutation, CopyCourseMutationVariables>(CopyCourseDocument, options);
}

export type CopyCourseMutationHookResult = ReturnType<typeof useCopyCourseMutation>;
export type CopyCourseMutationResult = Apollo.MutationResult<CopyCourseMutation>;
export type CopyCourseMutationOptions = Apollo.BaseMutationOptions<CopyCourseMutation, CopyCourseMutationVariables>;
export const CreateOrUpdateCourseDocument = gql`
  mutation createOrUpdateCourse($input: UpdateCourseInput!) {
    createOrUpdateCourse(input: $input) {
      id
      title
      subject
      colorTheme {
        id
        color
      }
      description
      dateFrom
      dateTo
      grade
      coverFile {
        id
        url
      }
      acquiring {
        id
        type
      }
      cost
    }
  }
`;
export type CreateOrUpdateCourseMutationFn = Apollo.MutationFunction<
  CreateOrUpdateCourseMutation,
  CreateOrUpdateCourseMutationVariables
>;

/**
 * __useCreateOrUpdateCourseMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCourseMutation, { data, loading, error }] = useCreateOrUpdateCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCourseMutation, CreateOrUpdateCourseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrUpdateCourseMutation, CreateOrUpdateCourseMutationVariables>(
    CreateOrUpdateCourseDocument,
    options,
  );
}

export type CreateOrUpdateCourseMutationHookResult = ReturnType<typeof useCreateOrUpdateCourseMutation>;
export type CreateOrUpdateCourseMutationResult = Apollo.MutationResult<CreateOrUpdateCourseMutation>;
export type CreateOrUpdateCourseMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateCourseMutation,
  CreateOrUpdateCourseMutationVariables
>;
export const CreateUserBlockDocument = gql`
  mutation createUserBlock($input: CreateUserBlockInput!) {
    createUserBlock(input: $input) {
      id
      createdAt
      type
    }
  }
`;
export type CreateUserBlockMutationFn = Apollo.MutationFunction<
  CreateUserBlockMutation,
  CreateUserBlockMutationVariables
>;

/**
 * __useCreateUserBlockMutation__
 *
 * To run a mutation, you first call `useCreateUserBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserBlockMutation, { data, loading, error }] = useCreateUserBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserBlockMutation, CreateUserBlockMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserBlockMutation, CreateUserBlockMutationVariables>(
    CreateUserBlockDocument,
    options,
  );
}

export type CreateUserBlockMutationHookResult = ReturnType<typeof useCreateUserBlockMutation>;
export type CreateUserBlockMutationResult = Apollo.MutationResult<CreateUserBlockMutation>;
export type CreateUserBlockMutationOptions = Apollo.BaseMutationOptions<
  CreateUserBlockMutation,
  CreateUserBlockMutationVariables
>;
export const DeleteCourseDocument = gql`
  mutation deleteCourse($courseId: ID!) {
    deleteCourse(courseId: $courseId)
  }
`;
export type DeleteCourseMutationFn = Apollo.MutationFunction<DeleteCourseMutation, DeleteCourseMutationVariables>;

/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useDeleteCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCourseMutation, DeleteCourseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(DeleteCourseDocument, options);
}

export type DeleteCourseMutationHookResult = ReturnType<typeof useDeleteCourseMutation>;
export type DeleteCourseMutationResult = Apollo.MutationResult<DeleteCourseMutation>;
export type DeleteCourseMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseMutation,
  DeleteCourseMutationVariables
>;
export const DeleteCourseCardDocument = gql`
  mutation deleteCourseCard($courseCardId: ID!) {
    deleteCourseCard(courseCardId: $courseCardId)
  }
`;
export type DeleteCourseCardMutationFn = Apollo.MutationFunction<
  DeleteCourseCardMutation,
  DeleteCourseCardMutationVariables
>;

/**
 * __useDeleteCourseCardMutation__
 *
 * To run a mutation, you first call `useDeleteCourseCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseCardMutation, { data, loading, error }] = useDeleteCourseCardMutation({
 *   variables: {
 *      courseCardId: // value for 'courseCardId'
 *   },
 * });
 */
export function useDeleteCourseCardMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCourseCardMutation, DeleteCourseCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCourseCardMutation, DeleteCourseCardMutationVariables>(
    DeleteCourseCardDocument,
    options,
  );
}

export type DeleteCourseCardMutationHookResult = ReturnType<typeof useDeleteCourseCardMutation>;
export type DeleteCourseCardMutationResult = Apollo.MutationResult<DeleteCourseCardMutation>;
export type DeleteCourseCardMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseCardMutation,
  DeleteCourseCardMutationVariables
>;
export const DeleteUserBlockDocument = gql`
  mutation deleteUserBlock($blockId: ID!, $userId: ID!) {
    deleteUserBlock(userId: $userId, blockId: $blockId)
  }
`;
export type DeleteUserBlockMutationFn = Apollo.MutationFunction<
  DeleteUserBlockMutation,
  DeleteUserBlockMutationVariables
>;

/**
 * __useDeleteUserBlockMutation__
 *
 * To run a mutation, you first call `useDeleteUserBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBlockMutation, { data, loading, error }] = useDeleteUserBlockMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserBlockMutation, DeleteUserBlockMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserBlockMutation, DeleteUserBlockMutationVariables>(
    DeleteUserBlockDocument,
    options,
  );
}

export type DeleteUserBlockMutationHookResult = ReturnType<typeof useDeleteUserBlockMutation>;
export type DeleteUserBlockMutationResult = Apollo.MutationResult<DeleteUserBlockMutation>;
export type DeleteUserBlockMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserBlockMutation,
  DeleteUserBlockMutationVariables
>;
export const PublishOrHideCourseDocument = gql`
  mutation publishOrHideCourse($courseId: ID!) {
    publishOrHideCourse(courseId: $courseId) {
      id
      isHidden
    }
  }
`;
export type PublishOrHideCourseMutationFn = Apollo.MutationFunction<
  PublishOrHideCourseMutation,
  PublishOrHideCourseMutationVariables
>;

/**
 * __usePublishOrHideCourseMutation__
 *
 * To run a mutation, you first call `usePublishOrHideCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishOrHideCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishOrHideCourseMutation, { data, loading, error }] = usePublishOrHideCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function usePublishOrHideCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<PublishOrHideCourseMutation, PublishOrHideCourseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishOrHideCourseMutation, PublishOrHideCourseMutationVariables>(
    PublishOrHideCourseDocument,
    options,
  );
}

export type PublishOrHideCourseMutationHookResult = ReturnType<typeof usePublishOrHideCourseMutation>;
export type PublishOrHideCourseMutationResult = Apollo.MutationResult<PublishOrHideCourseMutation>;
export type PublishOrHideCourseMutationOptions = Apollo.BaseMutationOptions<
  PublishOrHideCourseMutation,
  PublishOrHideCourseMutationVariables
>;
export const PublishOrHideCourseCardDocument = gql`
  mutation publishOrHideCourseCard($courseCardId: ID!) {
    publishOrHideCourseCard(courseCardId: $courseCardId) {
      id
      isHidden
    }
  }
`;
export type PublishOrHideCourseCardMutationFn = Apollo.MutationFunction<
  PublishOrHideCourseCardMutation,
  PublishOrHideCourseCardMutationVariables
>;

/**
 * __usePublishOrHideCourseCardMutation__
 *
 * To run a mutation, you first call `usePublishOrHideCourseCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishOrHideCourseCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishOrHideCourseCardMutation, { data, loading, error }] = usePublishOrHideCourseCardMutation({
 *   variables: {
 *      courseCardId: // value for 'courseCardId'
 *   },
 * });
 */
export function usePublishOrHideCourseCardMutation(
  baseOptions?: Apollo.MutationHookOptions<PublishOrHideCourseCardMutation, PublishOrHideCourseCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishOrHideCourseCardMutation, PublishOrHideCourseCardMutationVariables>(
    PublishOrHideCourseCardDocument,
    options,
  );
}

export type PublishOrHideCourseCardMutationHookResult = ReturnType<typeof usePublishOrHideCourseCardMutation>;
export type PublishOrHideCourseCardMutationResult = Apollo.MutationResult<PublishOrHideCourseCardMutation>;
export type PublishOrHideCourseCardMutationOptions = Apollo.BaseMutationOptions<
  PublishOrHideCourseCardMutation,
  PublishOrHideCourseCardMutationVariables
>;
export const UpdateBlocksDocument = gql`
  mutation updateBlocks($input: UpdateBlocksInput!) {
    updateBlocks(input: $input) {
      id
      title
      availabilityType
      cost
      dateFrom
      dateTo
      coverFile {
        id
        url
      }
      lessons {
        id
        title
        trial
        dateTo
        dateFrom
        exercises {
          id
          title
          type
          inProgress
          timeLimit
        }
      }
    }
  }
`;
export type UpdateBlocksMutationFn = Apollo.MutationFunction<UpdateBlocksMutation, UpdateBlocksMutationVariables>;

/**
 * __useUpdateBlocksMutation__
 *
 * To run a mutation, you first call `useUpdateBlocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlocksMutation, { data, loading, error }] = useUpdateBlocksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBlocksMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBlocksMutation, UpdateBlocksMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBlocksMutation, UpdateBlocksMutationVariables>(UpdateBlocksDocument, options);
}

export type UpdateBlocksMutationHookResult = ReturnType<typeof useUpdateBlocksMutation>;
export type UpdateBlocksMutationResult = Apollo.MutationResult<UpdateBlocksMutation>;
export type UpdateBlocksMutationOptions = Apollo.BaseMutationOptions<
  UpdateBlocksMutation,
  UpdateBlocksMutationVariables
>;
export const UpdateBlocksPaymentInfoDocument = gql`
  mutation updateBlocksPaymentInfo($input: UpdateBlocksInput!) {
    updateBlocks(input: $input) {
      id
      title
      availabilityType
      cost
    }
  }
`;
export type UpdateBlocksPaymentInfoMutationFn = Apollo.MutationFunction<
  UpdateBlocksPaymentInfoMutation,
  UpdateBlocksPaymentInfoMutationVariables
>;

/**
 * __useUpdateBlocksPaymentInfoMutation__
 *
 * To run a mutation, you first call `useUpdateBlocksPaymentInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlocksPaymentInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlocksPaymentInfoMutation, { data, loading, error }] = useUpdateBlocksPaymentInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBlocksPaymentInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBlocksPaymentInfoMutation, UpdateBlocksPaymentInfoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBlocksPaymentInfoMutation, UpdateBlocksPaymentInfoMutationVariables>(
    UpdateBlocksPaymentInfoDocument,
    options,
  );
}

export type UpdateBlocksPaymentInfoMutationHookResult = ReturnType<typeof useUpdateBlocksPaymentInfoMutation>;
export type UpdateBlocksPaymentInfoMutationResult = Apollo.MutationResult<UpdateBlocksPaymentInfoMutation>;
export type UpdateBlocksPaymentInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateBlocksPaymentInfoMutation,
  UpdateBlocksPaymentInfoMutationVariables
>;
export const UpdateCourseCardDocument = gql`
  mutation updateCourseCard($input: [UpdateCourseCardInput!]!) {
    updateCourseCard(input: $input) {
      id
      description
      coverFile {
        id
        url
      }
      advertising {
        id
        course {
          id
          title
        }
      }
      isHidden
      useDefaultCover
      type
      link
    }
  }
`;
export type UpdateCourseCardMutationFn = Apollo.MutationFunction<
  UpdateCourseCardMutation,
  UpdateCourseCardMutationVariables
>;

/**
 * __useUpdateCourseCardMutation__
 *
 * To run a mutation, you first call `useUpdateCourseCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseCardMutation, { data, loading, error }] = useUpdateCourseCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseCardMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCourseCardMutation, UpdateCourseCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCourseCardMutation, UpdateCourseCardMutationVariables>(
    UpdateCourseCardDocument,
    options,
  );
}

export type UpdateCourseCardMutationHookResult = ReturnType<typeof useUpdateCourseCardMutation>;
export type UpdateCourseCardMutationResult = Apollo.MutationResult<UpdateCourseCardMutation>;
export type UpdateCourseCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseCardMutation,
  UpdateCourseCardMutationVariables
>;
export const UpdateCourseCuratorsDocument = gql`
  mutation updateCourseCurators($input: UpdateCourseCuratorInput!) {
    updateCourseCurators(input: $input) {
      id
      user {
        id
        numericId
        firstName
        lastName
        middleName
        role
        avatarFile {
          id
          url
        }
      }
    }
  }
`;
export type UpdateCourseCuratorsMutationFn = Apollo.MutationFunction<
  UpdateCourseCuratorsMutation,
  UpdateCourseCuratorsMutationVariables
>;

/**
 * __useUpdateCourseCuratorsMutation__
 *
 * To run a mutation, you first call `useUpdateCourseCuratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseCuratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseCuratorsMutation, { data, loading, error }] = useUpdateCourseCuratorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseCuratorsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCourseCuratorsMutation, UpdateCourseCuratorsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCourseCuratorsMutation, UpdateCourseCuratorsMutationVariables>(
    UpdateCourseCuratorsDocument,
    options,
  );
}

export type UpdateCourseCuratorsMutationHookResult = ReturnType<typeof useUpdateCourseCuratorsMutation>;
export type UpdateCourseCuratorsMutationResult = Apollo.MutationResult<UpdateCourseCuratorsMutation>;
export type UpdateCourseCuratorsMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseCuratorsMutation,
  UpdateCourseCuratorsMutationVariables
>;
export const UpdateCoursePaymentInfoDocument = gql`
  mutation updateCoursePaymentInfo($input: UpdateCourseInput!) {
    createOrUpdateCourse(input: $input) {
      id
      cost
      acquiring {
        id
        title
      }
      colorThemeId
      grade
      subject
    }
  }
`;
export type UpdateCoursePaymentInfoMutationFn = Apollo.MutationFunction<
  UpdateCoursePaymentInfoMutation,
  UpdateCoursePaymentInfoMutationVariables
>;

/**
 * __useUpdateCoursePaymentInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCoursePaymentInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoursePaymentInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoursePaymentInfoMutation, { data, loading, error }] = useUpdateCoursePaymentInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoursePaymentInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCoursePaymentInfoMutation, UpdateCoursePaymentInfoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCoursePaymentInfoMutation, UpdateCoursePaymentInfoMutationVariables>(
    UpdateCoursePaymentInfoDocument,
    options,
  );
}

export type UpdateCoursePaymentInfoMutationHookResult = ReturnType<typeof useUpdateCoursePaymentInfoMutation>;
export type UpdateCoursePaymentInfoMutationResult = Apollo.MutationResult<UpdateCoursePaymentInfoMutation>;
export type UpdateCoursePaymentInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoursePaymentInfoMutation,
  UpdateCoursePaymentInfoMutationVariables
>;
export const UpdateElementsDocument = gql`
  mutation updateElements($input: UpdateElementsInput!) {
    updateElements(input: $input) {
      id
      elements {
        id
        type
        ... on HeadingElement {
          title
        }
        ... on FeedbackElement {
          elementParts {
            content
          }
        }
        ... on VideoElement {
          kinescopeId
        }
        ... on TextElement {
          content
        }
        ... on QuestionElement {
          questionType
          title
          description
          placeholder
          maxScore
          scoreType
          negativeScore
          positiveScore
          elementParts {
            id
            correctAnswer
            isCorrect
            content
          }
          files {
            id
            fileName
            file {
              id
              name
              url
            }
          }
        }
        ... on FileElement {
          files {
            id
            type
            fileName
            file {
              id
              name
              url
            }
          }
        }
        ... on ExerciseLinkElement {
          description
        }
      }
    }
  }
`;
export type UpdateElementsMutationFn = Apollo.MutationFunction<UpdateElementsMutation, UpdateElementsMutationVariables>;

/**
 * __useUpdateElementsMutation__
 *
 * To run a mutation, you first call `useUpdateElementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateElementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateElementsMutation, { data, loading, error }] = useUpdateElementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateElementsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateElementsMutation, UpdateElementsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateElementsMutation, UpdateElementsMutationVariables>(UpdateElementsDocument, options);
}

export type UpdateElementsMutationHookResult = ReturnType<typeof useUpdateElementsMutation>;
export type UpdateElementsMutationResult = Apollo.MutationResult<UpdateElementsMutation>;
export type UpdateElementsMutationOptions = Apollo.BaseMutationOptions<
  UpdateElementsMutation,
  UpdateElementsMutationVariables
>;
export const AcquiringInstancesDocument = gql`
  query acquiringInstances($limit: Float!, $page: Float!) {
    acquiringInstances(limit: $limit, page: $page) {
      totalCount
      data {
        id
        title
      }
    }
  }
`;

/**
 * __useAcquiringInstancesQuery__
 *
 * To run a query within a React component, call `useAcquiringInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcquiringInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcquiringInstancesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAcquiringInstancesQuery(
  baseOptions: Apollo.QueryHookOptions<AcquiringInstancesQuery, AcquiringInstancesQueryVariables> &
    (
      | {
          variables: AcquiringInstancesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AcquiringInstancesQuery, AcquiringInstancesQueryVariables>(
    AcquiringInstancesDocument,
    options,
  );
}

export function useAcquiringInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AcquiringInstancesQuery, AcquiringInstancesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AcquiringInstancesQuery, AcquiringInstancesQueryVariables>(
    AcquiringInstancesDocument,
    options,
  );
}

export function useAcquiringInstancesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AcquiringInstancesQuery, AcquiringInstancesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AcquiringInstancesQuery, AcquiringInstancesQueryVariables>(
    AcquiringInstancesDocument,
    options,
  );
}

export type AcquiringInstancesQueryHookResult = ReturnType<typeof useAcquiringInstancesQuery>;
export type AcquiringInstancesLazyQueryHookResult = ReturnType<typeof useAcquiringInstancesLazyQuery>;
export type AcquiringInstancesSuspenseQueryHookResult = ReturnType<typeof useAcquiringInstancesSuspenseQuery>;
export type AcquiringInstancesQueryResult = Apollo.QueryResult<
  AcquiringInstancesQuery,
  AcquiringInstancesQueryVariables
>;
export const BlocksDocument = gql`
  query blocks($filter: AdminBlockListFilterInput!, $limit: Float!, $page: Float!) {
    blocks(filter: $filter, limit: $limit, page: $page) {
      totalCount
      data {
        id
        title
        availabilityType
        dateFrom
        dateTo
        cost
        coverFile {
          id
          url
        }
        lessons {
          id
          title
          trial
          dateTo
          dateFrom
          exercises {
            id
            title
            type
            inProgress
            timeLimit
          }
        }
      }
    }
  }
`;

/**
 * __useBlocksQuery__
 *
 * To run a query within a React component, call `useBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlocksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBlocksQuery(
  baseOptions: Apollo.QueryHookOptions<BlocksQuery, BlocksQueryVariables> &
    (
      | {
          variables: BlocksQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlocksQuery, BlocksQueryVariables>(BlocksDocument, options);
}

export function useBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlocksQuery, BlocksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlocksQuery, BlocksQueryVariables>(BlocksDocument, options);
}

export function useBlocksSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BlocksQuery, BlocksQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BlocksQuery, BlocksQueryVariables>(BlocksDocument, options);
}

export type BlocksQueryHookResult = ReturnType<typeof useBlocksQuery>;
export type BlocksLazyQueryHookResult = ReturnType<typeof useBlocksLazyQuery>;
export type BlocksSuspenseQueryHookResult = ReturnType<typeof useBlocksSuspenseQuery>;
export type BlocksQueryResult = Apollo.QueryResult<BlocksQuery, BlocksQueryVariables>;
export const ColorThemesDocument = gql`
  query colorThemes($limit: Float!, $page: Float!) {
    colorThemes(limit: $limit, page: $page) {
      totalCount
      data {
        id
        color
      }
    }
  }
`;

/**
 * __useColorThemesQuery__
 *
 * To run a query within a React component, call `useColorThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useColorThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColorThemesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useColorThemesQuery(
  baseOptions: Apollo.QueryHookOptions<ColorThemesQuery, ColorThemesQueryVariables> &
    (
      | {
          variables: ColorThemesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ColorThemesQuery, ColorThemesQueryVariables>(ColorThemesDocument, options);
}

export function useColorThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ColorThemesQuery, ColorThemesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ColorThemesQuery, ColorThemesQueryVariables>(ColorThemesDocument, options);
}

export function useColorThemesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ColorThemesQuery, ColorThemesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ColorThemesQuery, ColorThemesQueryVariables>(ColorThemesDocument, options);
}

export type ColorThemesQueryHookResult = ReturnType<typeof useColorThemesQuery>;
export type ColorThemesLazyQueryHookResult = ReturnType<typeof useColorThemesLazyQuery>;
export type ColorThemesSuspenseQueryHookResult = ReturnType<typeof useColorThemesSuspenseQuery>;
export type ColorThemesQueryResult = Apollo.QueryResult<ColorThemesQuery, ColorThemesQueryVariables>;
export const CourseCardsDocument = gql`
  query courseCards($courseId: ID!, $filter: CourseCardsListFilterInput!) {
    courseCards(courseId: $courseId, filter: $filter) {
      id
      description
      coverFile {
        id
        url
      }
      course {
        id
        title
      }
      advertising {
        id
        course {
          id
          title
        }
      }
      isHidden
      useDefaultCover
      type
      link
    }
  }
`;

/**
 * __useCourseCardsQuery__
 *
 * To run a query within a React component, call `useCourseCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCardsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCourseCardsQuery(
  baseOptions: Apollo.QueryHookOptions<CourseCardsQuery, CourseCardsQueryVariables> &
    (
      | {
          variables: CourseCardsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseCardsQuery, CourseCardsQueryVariables>(CourseCardsDocument, options);
}

export function useCourseCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseCardsQuery, CourseCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseCardsQuery, CourseCardsQueryVariables>(CourseCardsDocument, options);
}

export function useCourseCardsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CourseCardsQuery, CourseCardsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CourseCardsQuery, CourseCardsQueryVariables>(CourseCardsDocument, options);
}

export type CourseCardsQueryHookResult = ReturnType<typeof useCourseCardsQuery>;
export type CourseCardsLazyQueryHookResult = ReturnType<typeof useCourseCardsLazyQuery>;
export type CourseCardsSuspenseQueryHookResult = ReturnType<typeof useCourseCardsSuspenseQuery>;
export type CourseCardsQueryResult = Apollo.QueryResult<CourseCardsQuery, CourseCardsQueryVariables>;
export const CourseCuratorsDocument = gql`
  query courseCurators($courseId: ID!) {
    courseCurators(courseId: $courseId) {
      id
      user {
        id
        numericId
        lastName
        firstName
        middleName
        role
        avatarFile {
          id
          url
        }
      }
    }
  }
`;

/**
 * __useCourseCuratorsQuery__
 *
 * To run a query within a React component, call `useCourseCuratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCuratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCuratorsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseCuratorsQuery(
  baseOptions: Apollo.QueryHookOptions<CourseCuratorsQuery, CourseCuratorsQueryVariables> &
    (
      | {
          variables: CourseCuratorsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseCuratorsQuery, CourseCuratorsQueryVariables>(CourseCuratorsDocument, options);
}

export function useCourseCuratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseCuratorsQuery, CourseCuratorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseCuratorsQuery, CourseCuratorsQueryVariables>(CourseCuratorsDocument, options);
}

export function useCourseCuratorsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CourseCuratorsQuery, CourseCuratorsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CourseCuratorsQuery, CourseCuratorsQueryVariables>(CourseCuratorsDocument, options);
}

export type CourseCuratorsQueryHookResult = ReturnType<typeof useCourseCuratorsQuery>;
export type CourseCuratorsLazyQueryHookResult = ReturnType<typeof useCourseCuratorsLazyQuery>;
export type CourseCuratorsSuspenseQueryHookResult = ReturnType<typeof useCourseCuratorsSuspenseQuery>;
export type CourseCuratorsQueryResult = Apollo.QueryResult<CourseCuratorsQuery, CourseCuratorsQueryVariables>;
export const GetCourseDescriptionDocument = gql`
  query getCourseDescription($courseId: ID!) {
    course(courseId: $courseId) {
      id
      title
      subject
      colorTheme {
        id
        color
      }
      cost
      description
      dateFrom
      dateTo
      grade
      coverFile {
        id
        url
      }
      acquiring {
        id
        title
      }
    }
  }
`;

/**
 * __useGetCourseDescriptionQuery__
 *
 * To run a query within a React component, call `useGetCourseDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseDescriptionQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseDescriptionQuery(
  baseOptions: Apollo.QueryHookOptions<GetCourseDescriptionQuery, GetCourseDescriptionQueryVariables> &
    (
      | {
          variables: GetCourseDescriptionQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseDescriptionQuery, GetCourseDescriptionQueryVariables>(
    GetCourseDescriptionDocument,
    options,
  );
}

export function useGetCourseDescriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCourseDescriptionQuery, GetCourseDescriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseDescriptionQuery, GetCourseDescriptionQueryVariables>(
    GetCourseDescriptionDocument,
    options,
  );
}

export function useGetCourseDescriptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCourseDescriptionQuery, GetCourseDescriptionQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCourseDescriptionQuery, GetCourseDescriptionQueryVariables>(
    GetCourseDescriptionDocument,
    options,
  );
}

export type GetCourseDescriptionQueryHookResult = ReturnType<typeof useGetCourseDescriptionQuery>;
export type GetCourseDescriptionLazyQueryHookResult = ReturnType<typeof useGetCourseDescriptionLazyQuery>;
export type GetCourseDescriptionSuspenseQueryHookResult = ReturnType<typeof useGetCourseDescriptionSuspenseQuery>;
export type GetCourseDescriptionQueryResult = Apollo.QueryResult<
  GetCourseDescriptionQuery,
  GetCourseDescriptionQueryVariables
>;
export const GetCourseHeaderDocument = gql`
  query getCourseHeader($courseId: ID!) {
    course(courseId: $courseId) {
      id
      title
      isHidden
      numericId
    }
  }
`;

/**
 * __useGetCourseHeaderQuery__
 *
 * To run a query within a React component, call `useGetCourseHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseHeaderQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<GetCourseHeaderQuery, GetCourseHeaderQueryVariables> &
    (
      | {
          variables: GetCourseHeaderQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseHeaderQuery, GetCourseHeaderQueryVariables>(GetCourseHeaderDocument, options);
}

export function useGetCourseHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCourseHeaderQuery, GetCourseHeaderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseHeaderQuery, GetCourseHeaderQueryVariables>(GetCourseHeaderDocument, options);
}

export function useGetCourseHeaderSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCourseHeaderQuery, GetCourseHeaderQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCourseHeaderQuery, GetCourseHeaderQueryVariables>(GetCourseHeaderDocument, options);
}

export type GetCourseHeaderQueryHookResult = ReturnType<typeof useGetCourseHeaderQuery>;
export type GetCourseHeaderLazyQueryHookResult = ReturnType<typeof useGetCourseHeaderLazyQuery>;
export type GetCourseHeaderSuspenseQueryHookResult = ReturnType<typeof useGetCourseHeaderSuspenseQuery>;
export type GetCourseHeaderQueryResult = Apollo.QueryResult<GetCourseHeaderQuery, GetCourseHeaderQueryVariables>;
export const CourseNavigationDocument = gql`
  query courseNavigation($courseId: ID!) {
    course(courseId: $courseId) {
      id
      title
      blocks {
        id
        title
        lessons {
          id
          title
          exercises {
            id
            title
            type
          }
        }
      }
    }
  }
`;

/**
 * __useCourseNavigationQuery__
 *
 * To run a query within a React component, call `useCourseNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseNavigationQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseNavigationQuery(
  baseOptions: Apollo.QueryHookOptions<CourseNavigationQuery, CourseNavigationQueryVariables> &
    (
      | {
          variables: CourseNavigationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseNavigationQuery, CourseNavigationQueryVariables>(CourseNavigationDocument, options);
}

export function useCourseNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseNavigationQuery, CourseNavigationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseNavigationQuery, CourseNavigationQueryVariables>(CourseNavigationDocument, options);
}

export function useCourseNavigationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CourseNavigationQuery, CourseNavigationQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CourseNavigationQuery, CourseNavigationQueryVariables>(
    CourseNavigationDocument,
    options,
  );
}

export type CourseNavigationQueryHookResult = ReturnType<typeof useCourseNavigationQuery>;
export type CourseNavigationLazyQueryHookResult = ReturnType<typeof useCourseNavigationLazyQuery>;
export type CourseNavigationSuspenseQueryHookResult = ReturnType<typeof useCourseNavigationSuspenseQuery>;
export type CourseNavigationQueryResult = Apollo.QueryResult<CourseNavigationQuery, CourseNavigationQueryVariables>;
export const CourseUsersDocument = gql`
  query courseUsers($filter: AdminCourseUsersFilterInput, $limit: Float!, $page: Float!, $courseId: ID!) {
    courseUsers(filter: $filter, limit: $limit, page: $page) {
      totalCount
      data {
        id
        numericId
        firstName
        lastName
        middleName
        coursePurchaseDate(courseId: $courseId)
        coursePaidBlocksCount(courseId: $courseId)
        courseLastPurchaseDate(courseId: $courseId)
      }
    }
    course(courseId: $courseId) {
      id
      title
    }
  }
`;

/**
 * __useCourseUsersQuery__
 *
 * To run a query within a React component, call `useCourseUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseUsersQuery(
  baseOptions: Apollo.QueryHookOptions<CourseUsersQuery, CourseUsersQueryVariables> &
    (
      | {
          variables: CourseUsersQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseUsersQuery, CourseUsersQueryVariables>(CourseUsersDocument, options);
}

export function useCourseUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseUsersQuery, CourseUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseUsersQuery, CourseUsersQueryVariables>(CourseUsersDocument, options);
}

export function useCourseUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CourseUsersQuery, CourseUsersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CourseUsersQuery, CourseUsersQueryVariables>(CourseUsersDocument, options);
}

export type CourseUsersQueryHookResult = ReturnType<typeof useCourseUsersQuery>;
export type CourseUsersLazyQueryHookResult = ReturnType<typeof useCourseUsersLazyQuery>;
export type CourseUsersSuspenseQueryHookResult = ReturnType<typeof useCourseUsersSuspenseQuery>;
export type CourseUsersQueryResult = Apollo.QueryResult<CourseUsersQuery, CourseUsersQueryVariables>;
export const CoursesDocument = gql`
  query courses($filter: AdminCourseListFilterInput, $limit: Float!, $page: Float!) {
    courses(filter: $filter, limit: $limit, page: $page) {
      totalCount
      data {
        id
        numericId
        title
        subject
        dateFrom
        dateTo
        isHidden
        createdAt
        usersCount
      }
    }
  }
`;

/**
 * __useCoursesQuery__
 *
 * To run a query within a React component, call `useCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCoursesQuery(
  baseOptions: Apollo.QueryHookOptions<CoursesQuery, CoursesQueryVariables> &
    (
      | {
          variables: CoursesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoursesQuery, CoursesQueryVariables>(CoursesDocument, options);
}

export function useCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoursesQuery, CoursesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoursesQuery, CoursesQueryVariables>(CoursesDocument, options);
}

export function useCoursesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CoursesQuery, CoursesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoursesQuery, CoursesQueryVariables>(CoursesDocument, options);
}

export type CoursesQueryHookResult = ReturnType<typeof useCoursesQuery>;
export type CoursesLazyQueryHookResult = ReturnType<typeof useCoursesLazyQuery>;
export type CoursesSuspenseQueryHookResult = ReturnType<typeof useCoursesSuspenseQuery>;
export type CoursesQueryResult = Apollo.QueryResult<CoursesQuery, CoursesQueryVariables>;
export const CoursesListDocument = gql`
  query coursesList($filter: AdminCourseListFilterInput, $limit: Float!, $page: Float!) {
    courses(filter: $filter, limit: $limit, page: $page) {
      data {
        id
        title
      }
    }
  }
`;

/**
 * __useCoursesListQuery__
 *
 * To run a query within a React component, call `useCoursesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCoursesListQuery(
  baseOptions: Apollo.QueryHookOptions<CoursesListQuery, CoursesListQueryVariables> &
    (
      | {
          variables: CoursesListQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoursesListQuery, CoursesListQueryVariables>(CoursesListDocument, options);
}

export function useCoursesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoursesListQuery, CoursesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoursesListQuery, CoursesListQueryVariables>(CoursesListDocument, options);
}

export function useCoursesListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CoursesListQuery, CoursesListQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoursesListQuery, CoursesListQueryVariables>(CoursesListDocument, options);
}

export type CoursesListQueryHookResult = ReturnType<typeof useCoursesListQuery>;
export type CoursesListLazyQueryHookResult = ReturnType<typeof useCoursesListLazyQuery>;
export type CoursesListSuspenseQueryHookResult = ReturnType<typeof useCoursesListSuspenseQuery>;
export type CoursesListQueryResult = Apollo.QueryResult<CoursesListQuery, CoursesListQueryVariables>;
export const GetExerciseElementsDocument = gql`
  query getExerciseElements($exerciseId: ID!) {
    exercise(exerciseId: $exerciseId) {
      id
      title
      type
      lesson {
        id
        title
        block {
          id
          title
          course {
            id
            title
          }
        }
      }
      elements {
        id
        type
        ... on HeadingElement {
          title
        }
        ... on FeedbackElement {
          elementParts {
            content
          }
        }
        ... on VideoElement {
          kinescopeId
        }
        ... on TextElement {
          content
        }
        ... on QuestionElement {
          questionType
          title
          description
          placeholder
          maxScore
          scoreType
          negativeScore
          positiveScore
          elementParts {
            id
            correctAnswer
            isCorrect
            content
          }
          files {
            id
            fileName
            file {
              id
              name
              url
            }
          }
        }
        ... on FileElement {
          files {
            id
            type
            fileName
            file {
              id
              name
              url
            }
          }
        }
        ... on ExerciseLinkElement {
          description
        }
      }
    }
  }
`;

/**
 * __useGetExerciseElementsQuery__
 *
 * To run a query within a React component, call `useGetExerciseElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseElementsQuery({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function useGetExerciseElementsQuery(
  baseOptions: Apollo.QueryHookOptions<GetExerciseElementsQuery, GetExerciseElementsQueryVariables> &
    (
      | {
          variables: GetExerciseElementsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExerciseElementsQuery, GetExerciseElementsQueryVariables>(
    GetExerciseElementsDocument,
    options,
  );
}

export function useGetExerciseElementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExerciseElementsQuery, GetExerciseElementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExerciseElementsQuery, GetExerciseElementsQueryVariables>(
    GetExerciseElementsDocument,
    options,
  );
}

export function useGetExerciseElementsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetExerciseElementsQuery, GetExerciseElementsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetExerciseElementsQuery, GetExerciseElementsQueryVariables>(
    GetExerciseElementsDocument,
    options,
  );
}

export type GetExerciseElementsQueryHookResult = ReturnType<typeof useGetExerciseElementsQuery>;
export type GetExerciseElementsLazyQueryHookResult = ReturnType<typeof useGetExerciseElementsLazyQuery>;
export type GetExerciseElementsSuspenseQueryHookResult = ReturnType<typeof useGetExerciseElementsSuspenseQuery>;
export type GetExerciseElementsQueryResult = Apollo.QueryResult<
  GetExerciseElementsQuery,
  GetExerciseElementsQueryVariables
>;
export const PaymentDocument = gql`
  query payment($courseId: ID!, $filter: AdminBlockListFilterInput!, $limit: Float!, $page: Float!) {
    course(courseId: $courseId) {
      id
      cost
      acquiring {
        id
        title
      }
      colorThemeId
      grade
      subject
    }
    blocks(filter: $filter, limit: $limit, page: $page) {
      data {
        id
        title
        availabilityType
        cost
      }
    }
  }
`;

/**
 * __usePaymentQuery__
 *
 * To run a query within a React component, call `usePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaymentQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentQuery, PaymentQueryVariables> &
    (
      | {
          variables: PaymentQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentQuery, PaymentQueryVariables>(PaymentDocument, options);
}

export function usePaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentQuery, PaymentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentQuery, PaymentQueryVariables>(PaymentDocument, options);
}

export function usePaymentSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentQuery, PaymentQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentQuery, PaymentQueryVariables>(PaymentDocument, options);
}

export type PaymentQueryHookResult = ReturnType<typeof usePaymentQuery>;
export type PaymentLazyQueryHookResult = ReturnType<typeof usePaymentLazyQuery>;
export type PaymentSuspenseQueryHookResult = ReturnType<typeof usePaymentSuspenseQuery>;
export type PaymentQueryResult = Apollo.QueryResult<PaymentQuery, PaymentQueryVariables>;
export const UserBlocksDocument = gql`
  query userBlocks($filter: AdminBlockListFilterInput!, $limit: Float!, $page: Float!, $userId: ID!, $courseId: ID!) {
    blocks(filter: $filter, limit: $limit, page: $page) {
      totalCount
      data {
        id
        title
        cost
        userBlock(userId: $userId) {
          id
          createdAt
          type
        }
        userLastTransaction(userId: $userId) {
          id
          createdAt
        }
      }
    }
    user(userId: $userId) {
      id
      firstName
      lastName
      middleName
    }
    course(courseId: $courseId) {
      id
      title
    }
  }
`;

/**
 * __useUserBlocksQuery__
 *
 * To run a query within a React component, call `useUserBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBlocksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      userId: // value for 'userId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useUserBlocksQuery(
  baseOptions: Apollo.QueryHookOptions<UserBlocksQuery, UserBlocksQueryVariables> &
    (
      | {
          variables: UserBlocksQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserBlocksQuery, UserBlocksQueryVariables>(UserBlocksDocument, options);
}

export function useUserBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserBlocksQuery, UserBlocksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserBlocksQuery, UserBlocksQueryVariables>(UserBlocksDocument, options);
}

export function useUserBlocksSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserBlocksQuery, UserBlocksQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserBlocksQuery, UserBlocksQueryVariables>(UserBlocksDocument, options);
}

export type UserBlocksQueryHookResult = ReturnType<typeof useUserBlocksQuery>;
export type UserBlocksLazyQueryHookResult = ReturnType<typeof useUserBlocksLazyQuery>;
export type UserBlocksSuspenseQueryHookResult = ReturnType<typeof useUserBlocksSuspenseQuery>;
export type UserBlocksQueryResult = Apollo.QueryResult<UserBlocksQuery, UserBlocksQueryVariables>;
export const UserListDocument = gql`
  query userList($filter: AdminUserListFilterInput, $limit: Float!, $order: AdminUserListOrderInput!, $page: Float!) {
    users(filter: $filter, limit: $limit, page: $page, order: $order) {
      data {
        id
        firstName
        lastName
        middleName
        numericId
      }
    }
  }
`;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserListQuery(
  baseOptions: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables> &
    (
      | {
          variables: UserListQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
}

export function useUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
}

export function useUserListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserListQuery, UserListQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
}

export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListSuspenseQueryHookResult = ReturnType<typeof useUserListSuspenseQuery>;
export type UserListQueryResult = Apollo.QueryResult<UserListQuery, UserListQueryVariables>;
export const ProcessAnswerDocument = gql`
  mutation processAnswer($input: ProcessAnswerInput) {
    processAnswer(input: $input) {
      id
      status
      history {
        id
        newStatus
        score
        user {
          id
          firstName
          lastName
          middleName
        }
        message
        answer {
          id
          answer
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export type ProcessAnswerMutationFn = Apollo.MutationFunction<ProcessAnswerMutation, ProcessAnswerMutationVariables>;

/**
 * __useProcessAnswerMutation__
 *
 * To run a mutation, you first call `useProcessAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processAnswerMutation, { data, loading, error }] = useProcessAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<ProcessAnswerMutation, ProcessAnswerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProcessAnswerMutation, ProcessAnswerMutationVariables>(ProcessAnswerDocument, options);
}

export type ProcessAnswerMutationHookResult = ReturnType<typeof useProcessAnswerMutation>;
export type ProcessAnswerMutationResult = Apollo.MutationResult<ProcessAnswerMutation>;
export type ProcessAnswerMutationOptions = Apollo.BaseMutationOptions<
  ProcessAnswerMutation,
  ProcessAnswerMutationVariables
>;
export const UserAnswerDocument = gql`
  query userAnswer($userAnswerId: ID!) {
    userAnswer(userAnswerId: $userAnswerId) {
      id
      createdAt
      answer
      files {
        file {
          id
          url
          name
        }
      }
      user {
        id
        numericId
        firstName
        lastName
        middleName
      }
      status
      element {
        id
        exercise {
          id
          lesson {
            id
            title
            block {
              id
              title
              course {
                id
                title
              }
            }
          }
        }
        ... on QuestionElement {
          title
          description
          maxScore
        }
      }
    }
    userAnswerHistory(answerId: $userAnswerId, order: ASC) {
      id
      user {
        id
        firstName
        lastName
        middleName
        avatarFile {
          id
          url
        }
      }
      answer {
        id
        status
        answer
      }
      files {
        id
        file {
          id
          url
          name
        }
      }
      newStatus
      message
      score
      newStatus
      createdAt
    }
  }
`;

/**
 * __useUserAnswerQuery__
 *
 * To run a query within a React component, call `useUserAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAnswerQuery({
 *   variables: {
 *      userAnswerId: // value for 'userAnswerId'
 *   },
 * });
 */
export function useUserAnswerQuery(
  baseOptions: Apollo.QueryHookOptions<UserAnswerQuery, UserAnswerQueryVariables> &
    (
      | {
          variables: UserAnswerQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAnswerQuery, UserAnswerQueryVariables>(UserAnswerDocument, options);
}

export function useUserAnswerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserAnswerQuery, UserAnswerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserAnswerQuery, UserAnswerQueryVariables>(UserAnswerDocument, options);
}

export function useUserAnswerSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserAnswerQuery, UserAnswerQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserAnswerQuery, UserAnswerQueryVariables>(UserAnswerDocument, options);
}

export type UserAnswerQueryHookResult = ReturnType<typeof useUserAnswerQuery>;
export type UserAnswerLazyQueryHookResult = ReturnType<typeof useUserAnswerLazyQuery>;
export type UserAnswerSuspenseQueryHookResult = ReturnType<typeof useUserAnswerSuspenseQuery>;
export type UserAnswerQueryResult = Apollo.QueryResult<UserAnswerQuery, UserAnswerQueryVariables>;
export const UserAnswerHeaderDocument = gql`
  query userAnswerHeader($userAnswerId: ID!) {
    userAnswer(userAnswerId: $userAnswerId) {
      id
      user {
        id
        numericId
        firstName
        lastName
        middleName
      }
      status
    }
  }
`;

/**
 * __useUserAnswerHeaderQuery__
 *
 * To run a query within a React component, call `useUserAnswerHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAnswerHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAnswerHeaderQuery({
 *   variables: {
 *      userAnswerId: // value for 'userAnswerId'
 *   },
 * });
 */
export function useUserAnswerHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<UserAnswerHeaderQuery, UserAnswerHeaderQueryVariables> &
    (
      | {
          variables: UserAnswerHeaderQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAnswerHeaderQuery, UserAnswerHeaderQueryVariables>(UserAnswerHeaderDocument, options);
}

export function useUserAnswerHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserAnswerHeaderQuery, UserAnswerHeaderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserAnswerHeaderQuery, UserAnswerHeaderQueryVariables>(UserAnswerHeaderDocument, options);
}

export function useUserAnswerHeaderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UserAnswerHeaderQuery, UserAnswerHeaderQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserAnswerHeaderQuery, UserAnswerHeaderQueryVariables>(
    UserAnswerHeaderDocument,
    options,
  );
}

export type UserAnswerHeaderQueryHookResult = ReturnType<typeof useUserAnswerHeaderQuery>;
export type UserAnswerHeaderLazyQueryHookResult = ReturnType<typeof useUserAnswerHeaderLazyQuery>;
export type UserAnswerHeaderSuspenseQueryHookResult = ReturnType<typeof useUserAnswerHeaderSuspenseQuery>;
export type UserAnswerHeaderQueryResult = Apollo.QueryResult<UserAnswerHeaderQuery, UserAnswerHeaderQueryVariables>;
export const UserAnswerHistoryDocument = gql`
  query userAnswerHistory($answerId: ID!, $order: OrderEnum) {
    userAnswerHistory(answerId: $answerId, order: $order) {
      id
      user {
        id
        firstName
        middleName
        lastName
      }
      newStatus
      createdAt
    }
  }
`;

/**
 * __useUserAnswerHistoryQuery__
 *
 * To run a query within a React component, call `useUserAnswerHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAnswerHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAnswerHistoryQuery({
 *   variables: {
 *      answerId: // value for 'answerId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserAnswerHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<UserAnswerHistoryQuery, UserAnswerHistoryQueryVariables> &
    (
      | {
          variables: UserAnswerHistoryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAnswerHistoryQuery, UserAnswerHistoryQueryVariables>(UserAnswerHistoryDocument, options);
}

export function useUserAnswerHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserAnswerHistoryQuery, UserAnswerHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserAnswerHistoryQuery, UserAnswerHistoryQueryVariables>(
    UserAnswerHistoryDocument,
    options,
  );
}

export function useUserAnswerHistorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UserAnswerHistoryQuery, UserAnswerHistoryQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserAnswerHistoryQuery, UserAnswerHistoryQueryVariables>(
    UserAnswerHistoryDocument,
    options,
  );
}

export type UserAnswerHistoryQueryHookResult = ReturnType<typeof useUserAnswerHistoryQuery>;
export type UserAnswerHistoryLazyQueryHookResult = ReturnType<typeof useUserAnswerHistoryLazyQuery>;
export type UserAnswerHistorySuspenseQueryHookResult = ReturnType<typeof useUserAnswerHistorySuspenseQuery>;
export type UserAnswerHistoryQueryResult = Apollo.QueryResult<UserAnswerHistoryQuery, UserAnswerHistoryQueryVariables>;
export const UserExercisesDocument = gql`
  query userExercises($filter: AdminExerciseListFilterInput, $limit: Float!, $page: Float!, $userId: ID!) {
    exercises(filter: $filter, page: $page, limit: $limit) {
      totalCount
      data {
        id
        title
        userFileAnswer(userId: $userId) {
          id
          createdAt
          updatedAt
          status
        }
        userStatistic(userId: $userId) {
          scoreA
          scoreB
          maxScoreB
          maxScoreA
        }
      }
    }
    user(userId: $userId) {
      id
      numericId
      firstName
      lastName
      middleName
    }
  }
`;

/**
 * __useUserExercisesQuery__
 *
 * To run a query within a React component, call `useUserExercisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExercisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExercisesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserExercisesQuery(
  baseOptions: Apollo.QueryHookOptions<UserExercisesQuery, UserExercisesQueryVariables> &
    (
      | {
          variables: UserExercisesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserExercisesQuery, UserExercisesQueryVariables>(UserExercisesDocument, options);
}

export function useUserExercisesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserExercisesQuery, UserExercisesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserExercisesQuery, UserExercisesQueryVariables>(UserExercisesDocument, options);
}

export function useUserExercisesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserExercisesQuery, UserExercisesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserExercisesQuery, UserExercisesQueryVariables>(UserExercisesDocument, options);
}

export type UserExercisesQueryHookResult = ReturnType<typeof useUserExercisesQuery>;
export type UserExercisesLazyQueryHookResult = ReturnType<typeof useUserExercisesLazyQuery>;
export type UserExercisesSuspenseQueryHookResult = ReturnType<typeof useUserExercisesSuspenseQuery>;
export type UserExercisesQueryResult = Apollo.QueryResult<UserExercisesQuery, UserExercisesQueryVariables>;
export const UsersAnswersDocument = gql`
  query usersAnswers(
    $filter: AdminUserAnswerListFilterInput!
    $limit: Float!
    $page: Float!
    $order: AdminUserAnswerListOrderInput!
  ) {
    usersAnswers(filter: $filter, limit: $limit, page: $page, order: $order) {
      data {
        id
        updatedAt
        status
        element {
          id
          exercise {
            id
            lesson {
              id
              block {
                id
                course {
                  id
                  subject
                  grade
                }
              }
            }
          }
        }
        user {
          id
          numericId
          firstName
          lastName
          middleName
        }
      }
      totalCount
    }
  }
`;

/**
 * __useUsersAnswersQuery__
 *
 * To run a query within a React component, call `useUsersAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersAnswersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUsersAnswersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersAnswersQuery, UsersAnswersQueryVariables> &
    (
      | {
          variables: UsersAnswersQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersAnswersQuery, UsersAnswersQueryVariables>(UsersAnswersDocument, options);
}

export function useUsersAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersAnswersQuery, UsersAnswersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersAnswersQuery, UsersAnswersQueryVariables>(UsersAnswersDocument, options);
}

export function useUsersAnswersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersAnswersQuery, UsersAnswersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersAnswersQuery, UsersAnswersQueryVariables>(UsersAnswersDocument, options);
}

export type UsersAnswersQueryHookResult = ReturnType<typeof useUsersAnswersQuery>;
export type UsersAnswersLazyQueryHookResult = ReturnType<typeof useUsersAnswersLazyQuery>;
export type UsersAnswersSuspenseQueryHookResult = ReturnType<typeof useUsersAnswersSuspenseQuery>;
export type UsersAnswersQueryResult = Apollo.QueryResult<UsersAnswersQuery, UsersAnswersQueryVariables>;
export const BlockOrUnblockUserDocument = gql`
  mutation blockOrUnblockUser($userId: ID!) {
    blockOrUnblockUser(userId: $userId) {
      isBlocked
      id
    }
  }
`;
export type BlockOrUnblockUserMutationFn = Apollo.MutationFunction<
  BlockOrUnblockUserMutation,
  BlockOrUnblockUserMutationVariables
>;

/**
 * __useBlockOrUnblockUserMutation__
 *
 * To run a mutation, you first call `useBlockOrUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockOrUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockOrUnblockUserMutation, { data, loading, error }] = useBlockOrUnblockUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBlockOrUnblockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<BlockOrUnblockUserMutation, BlockOrUnblockUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlockOrUnblockUserMutation, BlockOrUnblockUserMutationVariables>(
    BlockOrUnblockUserDocument,
    options,
  );
}

export type BlockOrUnblockUserMutationHookResult = ReturnType<typeof useBlockOrUnblockUserMutation>;
export type BlockOrUnblockUserMutationResult = Apollo.MutationResult<BlockOrUnblockUserMutation>;
export type BlockOrUnblockUserMutationOptions = Apollo.BaseMutationOptions<
  BlockOrUnblockUserMutation,
  BlockOrUnblockUserMutationVariables
>;
export const ChangeRoleDocument = gql`
  mutation changeRole($role: RoleName!, $userId: ID!) {
    changeRole(userId: $userId, role: $role) {
      password
      phoneNumber
    }
  }
`;
export type ChangeRoleMutationFn = Apollo.MutationFunction<ChangeRoleMutation, ChangeRoleMutationVariables>;

/**
 * __useChangeRoleMutation__
 *
 * To run a mutation, you first call `useChangeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRoleMutation, { data, loading, error }] = useChangeRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChangeRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeRoleMutation, ChangeRoleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeRoleMutation, ChangeRoleMutationVariables>(ChangeRoleDocument, options);
}

export type ChangeRoleMutationHookResult = ReturnType<typeof useChangeRoleMutation>;
export type ChangeRoleMutationResult = Apollo.MutationResult<ChangeRoleMutation>;
export type ChangeRoleMutationOptions = Apollo.BaseMutationOptions<ChangeRoleMutation, ChangeRoleMutationVariables>;
export const GenerateUserPasswordDocument = gql`
  mutation generateUserPassword($userId: ID!) {
    generateUserPassword(userId: $userId) {
      phoneNumber
      password
    }
  }
`;
export type GenerateUserPasswordMutationFn = Apollo.MutationFunction<
  GenerateUserPasswordMutation,
  GenerateUserPasswordMutationVariables
>;

/**
 * __useGenerateUserPasswordMutation__
 *
 * To run a mutation, you first call `useGenerateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUserPasswordMutation, { data, loading, error }] = useGenerateUserPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGenerateUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateUserPasswordMutation, GenerateUserPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateUserPasswordMutation, GenerateUserPasswordMutationVariables>(
    GenerateUserPasswordDocument,
    options,
  );
}

export type GenerateUserPasswordMutationHookResult = ReturnType<typeof useGenerateUserPasswordMutation>;
export type GenerateUserPasswordMutationResult = Apollo.MutationResult<GenerateUserPasswordMutation>;
export type GenerateUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  GenerateUserPasswordMutation,
  GenerateUserPasswordMutationVariables
>;
export const RemoveUserFromCourseDocument = gql`
  mutation removeUserFromCourse($courseId: ID!, $userId: ID!) {
    removeUserFromCourse(userId: $userId, courseId: $courseId)
  }
`;
export type RemoveUserFromCourseMutationFn = Apollo.MutationFunction<
  RemoveUserFromCourseMutation,
  RemoveUserFromCourseMutationVariables
>;

/**
 * __useRemoveUserFromCourseMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromCourseMutation, { data, loading, error }] = useRemoveUserFromCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveUserFromCourseMutation, RemoveUserFromCourseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUserFromCourseMutation, RemoveUserFromCourseMutationVariables>(
    RemoveUserFromCourseDocument,
    options,
  );
}

export type RemoveUserFromCourseMutationHookResult = ReturnType<typeof useRemoveUserFromCourseMutation>;
export type RemoveUserFromCourseMutationResult = Apollo.MutationResult<RemoveUserFromCourseMutation>;
export type RemoveUserFromCourseMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserFromCourseMutation,
  RemoveUserFromCourseMutationVariables
>;
export const UpdateUserDataDocument = gql`
  mutation updateUserData($input: UpdateUserDataInput!) {
    updateUserData(input: $input) {
      id
      firstName
      lastName
      middleName
      phoneNumber
      comment
      birthDate
      avatarFile {
        id
        url
      }
    }
  }
`;
export type UpdateUserDataMutationFn = Apollo.MutationFunction<UpdateUserDataMutation, UpdateUserDataMutationVariables>;

/**
 * __useUpdateUserDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDataMutation, { data, loading, error }] = useUpdateUserDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserDataMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserDataMutation, UpdateUserDataMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserDataMutation, UpdateUserDataMutationVariables>(UpdateUserDataDocument, options);
}

export type UpdateUserDataMutationHookResult = ReturnType<typeof useUpdateUserDataMutation>;
export type UpdateUserDataMutationResult = Apollo.MutationResult<UpdateUserDataMutation>;
export type UpdateUserDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDataMutation,
  UpdateUserDataMutationVariables
>;
export const UserDocument = gql`
  query user($userId: ID!) {
    user(userId: $userId) {
      id
      firstName
      lastName
      middleName
      phoneNumber
      comment
      birthDate
      avatarFile {
        id
        url
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> &
    (
      | { variables: UserQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}

export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}

export function useUserSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}

export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserCoursesDocument = gql`
  query userCourses($filter: AdminUserCourseListFilterInput!, $limit: Float!, $page: Float!) {
    userCourses(page: $page, limit: $limit, filter: $filter) {
      totalCount
      data {
        id
        courseId
        course {
          id
          numericId
          title
          grade
        }
        userId
        type
        createdAt
      }
    }
  }
`;

/**
 * __useUserCoursesQuery__
 *
 * To run a query within a React component, call `useUserCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCoursesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserCoursesQuery(
  baseOptions: Apollo.QueryHookOptions<UserCoursesQuery, UserCoursesQueryVariables> &
    (
      | {
          variables: UserCoursesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserCoursesQuery, UserCoursesQueryVariables>(UserCoursesDocument, options);
}

export function useUserCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserCoursesQuery, UserCoursesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserCoursesQuery, UserCoursesQueryVariables>(UserCoursesDocument, options);
}

export function useUserCoursesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserCoursesQuery, UserCoursesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserCoursesQuery, UserCoursesQueryVariables>(UserCoursesDocument, options);
}

export type UserCoursesQueryHookResult = ReturnType<typeof useUserCoursesQuery>;
export type UserCoursesLazyQueryHookResult = ReturnType<typeof useUserCoursesLazyQuery>;
export type UserCoursesSuspenseQueryHookResult = ReturnType<typeof useUserCoursesSuspenseQuery>;
export type UserCoursesQueryResult = Apollo.QueryResult<UserCoursesQuery, UserCoursesQueryVariables>;
export const UserHeaderDocument = gql`
  query userHeader($userId: ID!) {
    user(userId: $userId) {
      id
      numericId
      firstName
      lastName
      middleName
      isBlocked
      role
    }
  }
`;

/**
 * __useUserHeaderQuery__
 *
 * To run a query within a React component, call `useUserHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHeaderQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<UserHeaderQuery, UserHeaderQueryVariables> &
    (
      | {
          variables: UserHeaderQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserHeaderQuery, UserHeaderQueryVariables>(UserHeaderDocument, options);
}

export function useUserHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserHeaderQuery, UserHeaderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserHeaderQuery, UserHeaderQueryVariables>(UserHeaderDocument, options);
}

export function useUserHeaderSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserHeaderQuery, UserHeaderQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserHeaderQuery, UserHeaderQueryVariables>(UserHeaderDocument, options);
}

export type UserHeaderQueryHookResult = ReturnType<typeof useUserHeaderQuery>;
export type UserHeaderLazyQueryHookResult = ReturnType<typeof useUserHeaderLazyQuery>;
export type UserHeaderSuspenseQueryHookResult = ReturnType<typeof useUserHeaderSuspenseQuery>;
export type UserHeaderQueryResult = Apollo.QueryResult<UserHeaderQuery, UserHeaderQueryVariables>;
export const UsersDocument = gql`
  query users($filter: AdminUserListFilterInput, $limit: Float!, $page: Float!, $order: AdminUserListOrderInput!) {
    users(filter: $filter, limit: $limit, page: $page, order: $order) {
      totalCount
      data {
        id
        numericId
        firstName
        middleName
        lastName
        createdAt
        role
        avatarFile {
          id
          url
        }
      }
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables> &
    (
      | { variables: UsersQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}

export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}

export function useUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}

export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;

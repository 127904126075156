import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

interface AddItemButtonProps {
  title: string;
  onClick: () => void;
}

const AddItemButton = ({ title, onClick }: AddItemButtonProps) => (
  <Button startIcon={<AddIcon />} sx={{ width: 'fit-content' }} onClick={onClick}>
    {title}
  </Button>
);

export default AddItemButton;

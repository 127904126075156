import { InputsGeneratorProps, InputType } from 'components/generators/inputsGenerator';
import { FilterType } from 'components/generators/filtersGenerator';
import { formatForFilter } from 'helpers/date';

const filterFields = (): InputsGeneratorProps[] => [
  {
    name: 'userNumericId',
    label: 'ID пользователя',
    inputType: InputType.TEXT,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value: string) => parseFloat(value),
  },
  {
    name: 'userFullName',
    label: 'ФИО',
    inputType: InputType.TEXT,
  },
  {
    name: 'coursePurchaseAt',
    label: 'Дата получения курса',
    inputType: InputType.DATE,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value: string) => formatForFilter(value, 'start'),
  },
];

export default filterFields;

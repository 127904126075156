import { CourseSubject } from '@generated/graphql';

const COLOR_SCHEMES: { color: string; id: CourseSubject }[] = [
  {
    color: '#2FC373',
    id: CourseSubject.Biology,
  },
  {
    color: '#AC80E4',
    id: CourseSubject.Chemistry,
  },
  {
    color: '#FFCA2A',
    id: CourseSubject.Literature,
  },
  {
    color: '#FF8C36',
    id: CourseSubject.Math,
  },
  {
    color: '#FF5789',
    id: CourseSubject.RussianLanguage,
  },
  {
    color: '#FB4BCA',
    id: CourseSubject.SocialScience,
  },
  {
    color: '#8A70FF',
    id: CourseSubject.Physics,
  },
  {
    color: '#3DD9BD',
    id: CourseSubject.Informatics,
  },
  {
    color: '#0E91F0',
    id: CourseSubject.History,
  },
];

export default COLOR_SCHEMES;

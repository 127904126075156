import React, { FC } from 'react';
import TextFieldControl, { ITextFieldControl } from 'components/inputs/control/TextFieldControl';
import AutocompleteControl, { IAutocompleteControlProps } from 'components/inputs/control/AutocompleteControl';
import DatePickerControl, { DatePickerProps } from 'components/inputs/control/DatePickerControl';
import { checkIsDateValid } from 'helpers/date';
import { FilterType } from 'components/generators/filtersGenerator';

export enum InputType {
  DATE = 'DATE',
  TEXT = 'TEXT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
}

export const INPUT_DEFAULT_VALUE = {
  [InputType.DATE]: null,
  [InputType.TEXT]: '',
  [InputType.AUTOCOMPLETE]: null,
};

interface RequiredInputProps {
  label: string;
  name: string;
  filterType?: FilterType;
  customFilter?: (value: any) => string | number | boolean | string[];
}

type TextFieldInputProps = {
  inputType: InputType.TEXT;
  type?: React.HTMLInputTypeAttribute | undefined;
} & RequiredInputProps &
  ITextFieldControl;

type AutocompleteInputProps = {
  inputType: InputType.AUTOCOMPLETE;
} & RequiredInputProps &
  IAutocompleteControlProps;

type DateInputProps = {
  inputType: InputType.DATE;
} & RequiredInputProps &
  DatePickerProps;

export type InputsGeneratorProps = TextFieldInputProps | AutocompleteInputProps | DateInputProps;

const GeneratedInput: FC<{ input: InputsGeneratorProps }> = ({ input }) => {
  const { inputType, filterType, customFilter, ...inputProps } = input;
  switch (inputType) {
    case InputType.DATE: {
      return (
        <DatePickerControl
          rules={{ validate: (value: Date) => (value ? checkIsDateValid(value) : true) }}
          {...{ ...(inputProps as DatePickerProps) }}
        />
      );
    }
    case InputType.TEXT: {
      return <TextFieldControl type={input.type} {...{ ...(inputProps as TextFieldInputProps) }} />;
    }
    case InputType.AUTOCOMPLETE: {
      return <AutocompleteControl {...{ ...(inputProps as AutocompleteInputProps) }} />;
    }

    default:
      return null;
  }
};

export default GeneratedInput;

import { InputsGeneratorProps, InputType } from 'components/generators/inputsGenerator';
import { FilterType } from 'components/generators/filtersGenerator';
import { USER_COURSE_TYPE_OPTIONS } from 'constants/global';
import { formatForFilter } from 'helpers/date';

const filterFields = (): InputsGeneratorProps[] => [
  {
    name: 'courseNumericId',
    label: 'ID курса',
    inputType: InputType.TEXT,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value) => parseFloat(value),
  },
  {
    name: 'courseTitle',
    label: 'Название курса',
    inputType: InputType.TEXT,
  },
  {
    name: 'type',
    label: 'Тип доступа',
    inputType: InputType.AUTOCOMPLETE,
    filterType: FilterType.CUSTOM_FILTER,
    options: USER_COURSE_TYPE_OPTIONS,
    customFilter: (value) => value.id,
  },
  {
    name: 'createdAt',
    label: 'Дата приобретения',
    inputType: InputType.DATE,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value) => formatForFilter(value, 'start'),
  },
];

export default filterFields;

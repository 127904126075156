import { IconButton, Tooltip } from '@mui/material';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useContext } from 'react';

export interface CopyButtonProps {
  copyText: string;
}

const CopyButton = ({ copyText }: CopyButtonProps) => {
  const { addToast } = useContext(ToastContext);

  const onCopy = () => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS, text: 'Текст успешно скопирован' }));
  };
  return (
    <Tooltip title='Скопировать'>
      <IconButton sx={{ color: 'primary.main', mr: -1.25 }} onClick={onCopy}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;

import {
  Breakpoint,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';

type IModalProps = {
  open: boolean;
  title: string;
  content: string | React.JSX.Element;
  onClose: () => void;
  loading?: boolean;
  handleClick: () => void;
  buttonText?: string;
  color?: ButtonProps['color'];
  maxWidth?: Breakpoint | false;
};

const Modal: FC<IModalProps> = ({
  open,
  title,
  content,
  maxWidth = 'sm',
  onClose,
  handleClick,
  color,
  buttonText,
  loading = false,
}) => {
  if (!open) return null;
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle component={Typography} variant='h3' textAlign='center'>
        {title}
      </DialogTitle>
      <DialogContent>
        {typeof content === 'string' ? <DialogContentText>{content}</DialogContentText> : content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отменить</Button>
        <LoadingButton
          onClick={handleClick}
          color={color || 'error'}
          variant={color ? 'contained' : 'text'}
          loading={loading || false}
          loadingIndicator='Загрузка'
        >
          {buttonText || 'Удалить'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;

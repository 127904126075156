import React, { FC } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Path, useFieldArray, UseFormReturn } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import { DEFAULT_FILE_PAIR } from './constants';
import { ExerciseForm, FilePair } from '../../types/formTypes';
import CoverFile from './components/CoverFile';
import DownloadFile from './components/DownloadFile';

type FilesProps = {
  form: UseFormReturn<ExerciseForm>;
  elementIndex: number;
  disabled?: boolean;
};

const DownloadFiles: FC<FilesProps> = ({ form, elementIndex, disabled }) => {
  const { control } = form;
  const filePairsFieldName: Path<ExerciseForm> = `elements.${elementIndex}.filePairs`;

  const filePairFieldArray = useFieldArray<ExerciseForm, `elements.${number}.filePairs`, 'formId'>({
    control,
    keyName: 'formId',
    name: filePairsFieldName,
    shouldUnregister: false,
    rules: {
      validate: (filePairs: FilePair[]) =>
        filePairs.every((pair) => {
          const { cover, download } = pair;
          return (cover.file?.url || cover.file?.preview) && download.file;
        }),
    },
  });
  const { fields, append, remove } = filePairFieldArray;

  const appendFile = () => {
    append(DEFAULT_FILE_PAIR);
  };

  const deleteFilePair = (filePairIndex: number) => {
    remove(filePairIndex);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={3}>
        {fields.map((filePair, filePairIndex: number) => (
          <React.Fragment key={`filePair-${filePair.formId}`}>
            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <ActionButton type={ActionButtonEnum.DELETE} handleClick={() => deleteFilePair(filePairIndex)} />
            </Grid>
            <Grid item xs={5.5}>
              <Typography sx={{ mb: 1 }}>Обложка</Typography>
              <CoverFile
                {...{ field: filePair.cover, form, filePairFieldArray, filePairIndex, filePairsFieldName, disabled }}
              />
            </Grid>
            <Grid item xs={5.5}>
              <Typography sx={{ mb: 1 }}>Файл для скачивания</Typography>
              <DownloadFile
                {...{ field: filePair.download, filePairFieldArray, form, filePairIndex, filePairsFieldName, disabled }}
              />
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12}>
          <Button startIcon={<AddIcon />} onClick={appendFile}>
            Добавить пару файлов
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadFiles;

import CircularLoading from 'components/CircularLoading';
import {
  CourseCardsQuery,
  CourseCardType,
  CoursesListQuery,
  useCourseCardsQuery,
  useCoursesListQuery,
  useUpdateCourseCardMutation,
} from '@generated/graphql';
import { AutocompleteControl, Dropzone, EditorControl, Form, RadioGroup, TextFieldControl } from 'components/inputs';
import { useFieldArray, useForm } from 'react-hook-form';
import { FC, useContext } from 'react';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import CardActionButtons from 'pages/CourseCards/CardActionButtons';
import { ArrayElement, FilePreview } from 'types/common';
import { LK_URL } from 'constants/global';

export interface CardFormInputs {
  useDefaultCover: string;
  link: string | undefined | null;
  description: string;
  advertising: { id: string; title: string }[] | undefined | null;
  coverFile?: FilePreview | CourseCardsQuery['courseCards'][number]['coverFile'];
  coverFileUrl?: string;
  id?: string;
  isHidden: boolean;
  course?: ArrayElement<CourseCardsQuery['courseCards']>['course'];
}

type CourseOption = NonNullable<CoursesListQuery['courses']['data']>[number];

const CardsPage: FC<{ cardType: CourseCardType }> = ({ cardType }) => {
  const { addToast } = useContext(ToastContext);
  const { courseId } = useParams<{ courseId: string }>();
  const { data: courses, loading: coursesLoading } = useCoursesListQuery({
    variables: {
      limit: 100,
      page: 0,
    },
  });

  const { data, loading, error } = useCourseCardsQuery({
    variables: {
      courseId: courseId!,
      filter: {
        type: cardType,
      },
    },
  });
  const courseCards = data?.courseCards;
  const isAdvertising = cardType === CourseCardType.Advertising;

  const form = useForm<{ cards: CardFormInputs[] }>({
    values: {
      cards:
        courseCards?.map((courseCard) => ({
          id: courseCard.id,
          isHidden: courseCard.isHidden,
          description: courseCard.description,
          coverFileUrl: courseCard.coverFile?.url,
          useDefaultCover: courseCard.useDefaultCover.toString(),
          coverFile: courseCard.coverFile,
          course: courseCard.course,
          link: courseCard.link || '',
          advertising: courseCard.advertising?.map((ad) => ad.course) as { id: string; title: string }[],
        })) || [],
    },
  });
  const { control, watch, setValue, handleSubmit } = form;

  const { append, fields } = useFieldArray({
    control,
    name: 'cards',
    keyName: 'formId',
  });

  const addCard = () => append({ link: '', advertising: [], useDefaultCover: 'true', description: '', isHidden: true });

  const resetImg = (index: number) => {
    setValue(`cards.${index}.coverFileUrl`, undefined);
    setValue(`cards.${index}.coverFile`, null);
  };

  const [updateCard] = useUpdateCourseCardMutation();

  const onSubmit = ({ cards }: { cards: CardFormInputs[] }) => {
    const getCoverFile = (card: CardFormInputs) => {
      if (!card.coverFile) return null;
      if ('preview' in card.coverFile && card.coverFile.preview) return card.coverFile;
      return undefined;
    };

    updateCard({
      variables: {
        input: cards.map((card) => {
          const coverFile = getCoverFile(card);
          return {
            useDefaultCover: card.useDefaultCover === 'true',
            link: card.link,
            id: card.id,
            description: card.description,
            coverFile,
            advertisingInput: {
              courseId,
              coursesIds: card.advertising?.map((ad) => ad.id),
            },
          };
        }),
      },
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onSetNewImage = (name: string, image: FilePreview | string, index: number) => {
    setValue(`cards.${index}.${name as 'coverFile' | 'coverFileUrl'}`, image as FilePreview);
  };

  if (loading || !!error) return <CircularLoading />;

  return (
    <Form form={form} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        {fields?.map((card, index) => {
          const coverFileUrl = watch(`cards.${index}.coverFileUrl`);
          const isUploadCover = watch(`cards.${index}.useDefaultCover`) === 'false';
          return (
            <Card key={`card-${cardType}-${card.formId}`}>
              <Grid container key={card.formId}>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    {isAdvertising && (
                      <AutocompleteControl<CourseOption, true>
                        name={`cards.${index}.advertising`}
                        options={courses?.courses.data || []}
                        optionName='title'
                        loading={coursesLoading}
                        label='В каких курсах отображать'
                        multiple
                      />
                    )}
                    <EditorControl name={`cards.${index}.description`} label='Описание' />
                    <RadioGroup
                      itemList={[
                        { value: true, label: 'Использовать обложку курса' },
                        { value: false, label: 'Загрузить обложку' },
                      ]}
                      name={`cards.${index}.useDefaultCover`}
                    />
                    {isUploadCover && (
                      <Stack width={300} height={300} position='relative' borderRadius='10px'>
                        {coverFileUrl && (
                          <Stack position='absolute' width='100%' height='100%' overflow='hidden'>
                            <img
                              src={coverFileUrl}
                              width='100%'
                              height='100%'
                              style={{ objectFit: 'cover' }}
                              alt='обложка курса'
                            />
                          </Stack>
                        )}
                        <Box
                          sx={{ opacity: coverFileUrl ? 0 : 1, '&:hover': { opacity: 0.8 } }}
                          width='100%'
                          height='100%'
                          overflow='hidden'
                        >
                          <Dropzone
                            setValue={(name, file) => onSetNewImage(name, file, index)}
                            name={`cards.${index}.coverFile`}
                            nameUrl={`cards.${index}.coverFileUrl`}
                          />
                        </Box>
                        {coverFileUrl && (
                          <ActionButton
                            isFilled
                            type={ActionButtonEnum.DELETE}
                            handleClick={() => resetImg(index)}
                            sx={{ position: 'absolute', right: 10, top: 10 }}
                          />
                        )}
                      </Stack>
                    )}
                    {isAdvertising && <TextFieldControl name={`cards.${index}.link`} label='Ссылка для перехода' />}
                  </Stack>
                </Grid>
                {card.id && (
                  <Grid item xs={4}>
                    <Stack alignItems='flex-end' spacing={2}>
                      <Stack direction='row' justifyContent='flex-end'>
                        <Button
                          variant='contained'
                          size='small'
                          onClick={() => window.open(`${LK_URL}/card/${card.id}`)}
                        >
                          Предпросмотр
                        </Button>
                        {isAdvertising && <CardActionButtons card={card} />}
                      </Stack>
                      {isAdvertising && (
                        <Typography>{card.isHidden ? 'Карточка скрыта' : 'Карточка опубликована'}</Typography>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Card>
          );
        })}
        {isAdvertising && (
          <Button startIcon={<AddIcon />} sx={{ width: 'fit-content' }} onClick={addCard}>
            карточка
          </Button>
        )}
        <LoadingButton type='submit' variant='contained' loadingIndicator='Загрузка' sx={{ width: 'fit-content' }}>
          Сохранить
        </LoadingButton>
      </Stack>
    </Form>
  );
};

export default CardsPage;

import { ElementFileType, ExerciseType, QuestionScoreType, QuestionType } from '@generated/graphql';
import { UseFieldArrayReturn } from 'react-hook-form';

export enum ExtendedElementTypes {
  FEEDBACK = 'FEEDBACK',
  FILE = 'FILE',
  HEADING = 'HEADING',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  QUESTION_MATCHING = 'QUESTION_MATCHING',
  QUESTION_SINGLE_ANSWER = 'QUESTION_SINGLE_ANSWER',
  QUESTION_FEW_ANSWERS = 'QUESTION_FEW_ANSWERS',
  QUESTION_TEXT_ANSWERS = 'QUESTION_TEXT_ANSWERS',
  QUESTION_FILE_ANSWER = 'QUESTION_FILE_ANSWER',
  EXERCISE_LINK = 'EXERCISE_LINK',
}

interface ICommonElementField {
  id?: string;
  type: { id: ExtendedElementTypes; displayName: string } | null;
  open: boolean;
}

export interface IExerciseLink extends ICommonElementField {
  description: string | null | undefined;
  __typename?: 'ExerciseLinkElement';
}

export interface IFeedback extends ICommonElementField {
  __typename?: 'FeedbackElement';
  elementParts?: Array<{ __typename?: 'ElementPart'; content?: string | null }> | null;
}

export interface ITextElement extends ICommonElementField {
  __typename?: 'TextElement';
  content: string;
}

export interface IVideoElement extends ICommonElementField {
  __typename?: 'VideoElement';
  kinescopeId: string;
}

export interface IFile {
  id?: string | null;
  type?: ElementFileType;
  fileName?: string | null;
  fileNameWithoutExtension?: string;
  file?: {
    __typename?: 'File';
    id?: string;
    name: string;
    url?: string;
    preview?: string;
  } | null;
}

export interface FilePair {
  cover: IFile;
  download: IFile;
}

export interface IFileElement extends ICommonElementField {
  __typename?: 'FileElement';
  filePairs?: FilePair[] | null;
}

export interface IHeadingElement extends ICommonElementField {
  __typename?: 'HeadingElement';
  title?: string | null;
}

// ======== QUESTION SCORE TYPE

export interface IQuestionFullScore {
  maxScore: string;
}

export interface IQuestionPartialScore {
  negativeScore: string;
  positiveScore: string;
}

export type QuestionScore = IQuestionFullScore | IQuestionPartialScore;

// ==========ANSWER TYPE

export interface IQuestionSingleAnswer {
  singleCorrectAnswer: string;
  elementParts: Array<{
    __typename?: 'ElementPart';
    id?: string;
    content?: string | null;
    isCorrect?: boolean | null;
  }>;
}

export interface IQuestionFewAnswer {
  elementParts: Array<{
    __typename?: 'ElementPart';
    id?: string;
    content?: string | null;
    isCorrect?: boolean | null;
  }>;
}

export interface IQuestionFileAnswer {}

export interface IQuestionTextAnswer {
  elementParts: Array<{
    __typename?: 'ElementPart';
    id?: string;
    correctAnswer?: string | null;
  }>;
}

export interface IQuestionMatchingAnswer {
  elementParts: Array<{
    __typename?: 'ElementPart';
    id?: string;
    correctAnswer?: string | null;
    content?: string | null;
  }>;
}

export type IQuestionElement = {
  __typename?: 'QuestionElement';
  title?: string | null;
  placeholder?: string | null;
  files?: Array<IFile> | null;
  description?: string | null;
  scoreType: QuestionScoreType;
  questionType?: QuestionType;
  id: string;
  singleCorrectAnswer?: string;
  elementParts?: Array<{
    __typename?: 'ElementPart';
    id?: string;
    correctAnswer?: string | null;
    content?: string | null;
    isCorrect?: boolean | null;
  }>;
} & ICommonElementField &
  QuestionScore;

export type ElementUnionType =
  | IFileElement
  | IHeadingElement
  | IQuestionElement
  | IVideoElement
  | ITextElement
  | IExerciseLink;
/* | IFeedback */

export type ExerciseForm = {
  id: string | undefined;
  title: string | undefined;
  type: ExerciseType | undefined;
  elements: ElementUnionType[] | undefined;
};

export type QuestionComponents =
  | ExtendedElementTypes.QUESTION_FEW_ANSWERS
  | ExtendedElementTypes.QUESTION_MATCHING
  | ExtendedElementTypes.QUESTION_TEXT_ANSWERS
  | ExtendedElementTypes.QUESTION_FILE_ANSWER
  | ExtendedElementTypes.QUESTION_SINGLE_ANSWER;

export type FilePairFieldArrayType = UseFieldArrayReturn<ExerciseForm, `elements.${number}.filePairs`, 'formId'>;

// ==================

export type ExerciseFormWithSingleAnswer = Omit<ExerciseForm, 'elements'> & {
  elements: Array<
    Omit<ElementUnionType, 'elementParts'> & {
      elementParts: IQuestionSingleAnswer['elementParts'];
    }
  >;
};

export type ExerciseFormWithFewAnswer = Omit<ExerciseForm, 'elements'> & {
  elements: Array<
    Omit<ElementUnionType, 'elementParts'> & {
      elementParts: IQuestionFewAnswer['elementParts'];
    }
  >;
};

export type ExerciseFormWithMatching = Omit<ExerciseForm, 'elements'> & {
  elements: Array<
    Omit<ElementUnionType, 'elementParts'> & {
      elementParts: IQuestionMatchingAnswer['elementParts'];
    }
  >;
};

export type ExerciseFormWithTextAnswer = Omit<ExerciseForm, 'elements'> & {
  elements: Array<
    Omit<ElementUnionType, 'elementParts'> & {
      elementParts: IQuestionTextAnswer['elementParts'];
    }
  >;
};

import { Grid } from '@mui/material';
import TextFieldControl from 'components/inputs/control/TextFieldControl';
import { DatePickerControl } from 'components/inputs';
import CheckboxControl from 'components/inputs/control/CheckboxControl';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { BlockForm } from 'pages/Course/view/tabs/Content/types';

interface LessonDataFormProps {
  lessonItemName: `blocks.${number}.lessons.${number}`;
  form: UseFormReturn<BlockForm>;
  blockIndex: number;
}

const LessonDataForm = ({ lessonItemName, form, blockIndex }: LessonDataFormProps) => {
  const { getValues, setValue } = form;

  const onTrialChange = () => {
    const blockLessons = getValues(`blocks.${blockIndex}.lessons`);
    setValue(
      `blocks.${blockIndex}.trial`,
      blockLessons?.every((blockLesson) => blockLesson.trial),
    );
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <TextFieldControl label='Название урока' name={`${lessonItemName}.title`} />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2.5}>
          <DatePickerControl name={`${lessonItemName}.dateFrom`} label='Доступен с' />
        </Grid>
        <Grid item xs={2.5}>
          <DatePickerControl name={`${lessonItemName}.dateTo`} label='Доступен по' />
        </Grid>
      </Grid>
      <CheckboxControl
        label='Доступен в тестовом доступе'
        name={`${lessonItemName}.trial`}
        handleChange={onTrialChange}
      />
    </>
  );
};

export default LessonDataForm;

import { useGetCourseDescriptionQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { Form, TextFieldControl, DatePickerControl, Dropzone, AutocompleteControl } from 'components/inputs';
import { Path, useForm } from 'react-hook-form';
import { Box, Grid, Stack, Tooltip } from '@mui/material';
import { useContext, useEffect } from 'react';
import { formatISODate } from 'helpers/date';
import { LoadingButton } from '@mui/lab';
import { GRADE_OPTIONS, SUBJECT_OPTIONS } from 'constants/global';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoOutlined } from '@mui/icons-material';
import { FilePreview } from 'types/common';
import { ICourseDescriptionInputs } from 'pages/Course/view/tabs/Description/types';
import COLOR_SCHEMES from 'pages/Course/view/tabs/Description/constants';
import useSubmitCourseDescriptionForm from 'pages/Course/view/tabs/Description/useSubmitCourseDescriptionForm';
import descriptionValidation from './validation';

const Description = () => {
  const { addToast } = useContext(ToastContext);
  const { courseId } = useParams<{ courseId: string }>();

  const { data, loading, error } = useGetCourseDescriptionQuery(
    courseId
      ? {
          variables: {
            courseId,
          },
        }
      : {
          skip: !courseId,
        },
  );

  const course = data?.course;

  const form = useForm<ICourseDescriptionInputs>({
    resolver: yupResolver<any>(descriptionValidation),
    values: {
      dateFrom: course?.dateFrom,
      dateTo: course?.dateTo,
      title: course?.title,
      description: course?.description,
      coverFile: course?.coverFile,
      timeFrom: course?.dateFrom ? formatISODate(course?.dateFrom, 'HHmm') : '0000',
      timeTo: course?.dateTo ? formatISODate(course?.dateTo, 'HHmm') : '0000',
      coverFileUrl: course?.coverFile?.url,
      colorTheme: COLOR_SCHEMES.find((scheme) => scheme.id === course?.subject),
      subject: SUBJECT_OPTIONS.find((item) => item.id === course?.subject),
      grade: GRADE_OPTIONS.find((item) => item.id === course?.grade.toString()),
    },
  });
  const { setValue, watch, handleSubmit } = form;

  const coverFileUrl = watch('coverFileUrl');

  const resetImg = () => {
    setValue('coverFileUrl', undefined);
    setValue('coverFile', null);
  };

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const subjectWatch = watch('subject');
  useEffect(() => {
    if (subjectWatch?.id) {
      setValue(
        'colorTheme',
        COLOR_SCHEMES.find((scheme) => scheme.id === subjectWatch.id),
      );
    }
  }, [subjectWatch]);

  const onSetNewImage = (name: string, image: FilePreview | string) => {
    setValue(name as Path<ICourseDescriptionInputs>, image);
  };

  const { onSubmit, updateLoading } = useSubmitCourseDescriptionForm();

  if (loading || !!error) return <CircularLoading />;

  return (
    <Form form={form} onSubmit={handleSubmit((formData) => onSubmit(formData, course), onError)}>
      <Grid container columnSpacing={6} rowSpacing={4}>
        <Grid item xs={12} md={6} xl={4.5}>
          <Stack spacing={2}>
            <TextFieldControl name='title' label='Название курса' />
            <AutocompleteControl label='Предмет' options={SUBJECT_OPTIONS} name='subject' />
            <AutocompleteControl
              label='Цвет курса'
              optionName='color'
              options={COLOR_SCHEMES}
              name='colorTheme'
              disabled
              startIcon={
                <Box
                  sx={{
                    ml: 2,
                    width: 16,
                    height: 16,
                    backgroundColor: COLOR_SCHEMES.find((scheme) => scheme.id === subjectWatch?.id)?.color,
                  }}
                />
              }
            />
            <AutocompleteControl label='Класс' options={GRADE_OPTIONS} name='grade' />
            <Stack direction='row' spacing={1}>
              <DatePickerControl name='dateFrom' label='Дата начала курса' />
              <TextFieldControl name='timeFrom' sx={{ width: '50%' }} label='Время начала' maskType='time' />
            </Stack>
            <Stack direction='row' spacing={1}>
              <DatePickerControl name='dateTo' label='Дата окончания курса' />
              <TextFieldControl name='timeTo' sx={{ width: '50%' }} label='Время окончания' maskType='time' />
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
              <TextFieldControl name='description' label='Описание курса' multiline minRows={1} />
              <Tooltip title='Этот текст будет выведен в шапке онбординга. Результат можно увидеть в разделе Карточки - Онбординг.'>
                <InfoOutlined sx={{ color: 'base.300' }} />
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md='auto' sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
          <Stack width={300} height={300} position='relative' borderRadius='10px'>
            {coverFileUrl && (
              <Stack position='absolute' width='100%' height='100%' overflow='hidden'>
                <img src={coverFileUrl} width='100%' height='100%' style={{ objectFit: 'cover' }} alt='обложка курса' />
              </Stack>
            )}
            <Box
              sx={{ opacity: coverFileUrl ? 0 : 1, '&:hover': { opacity: 0.8 } }}
              width='100%'
              height='100%'
              overflow='hidden'
            >
              <Dropzone setValue={onSetNewImage} height='100%' />
            </Box>
            {coverFileUrl && (
              <ActionButton
                isFilled
                type={ActionButtonEnum.DELETE}
                handleClick={() => resetImg()}
                sx={{ position: 'absolute', right: 10, top: 10 }}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton type='submit' variant='contained' loading={updateLoading} loadingIndicator='Загрузка'>
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Description;

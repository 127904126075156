import { BlockAvailabilityType, BlocksQuery } from '@generated/graphql';
import { EXERCISE_TYPE_OPTIONS } from 'constants/global';
import { BlockForm } from 'pages/Course/view/tabs/Content/types';

const useInitForm =
  () =>
  (blocks: BlocksQuery['blocks']['data']): BlockForm => ({
    blocks:
      blocks?.map((block) => ({
        title: block.title,
        trial: block.availabilityType === BlockAvailabilityType.Trial,
        id: block.id,
        coverFileUrl: block.coverFile?.url,
        dateFrom: block.dateFrom,
        dateTo: block.dateTo,
        cost: block.cost,
        lessons: block.lessons!.map((lesson) => ({
          title: lesson.title,
          trial: lesson.trial,
          dateFrom: lesson.dateFrom,
          dateTo: lesson.dateTo,
          id: lesson.id,
          open: false,
          exercises:
            lesson.exercises?.map((exercise) => ({
              id: exercise.id,
              title: exercise.title,
              inProgress: exercise.inProgress,
              timeLimit: exercise.timeLimit ? new Date(exercise.timeLimit * 1000).toISOString().substring(11, 19) : '',
              isTimeLimit: !!exercise.timeLimit,
              type: EXERCISE_TYPE_OPTIONS.find((type) => exercise.type === type.id)!,
            })) || null,
        })),
      })) || [],
  });

export default useInitForm;

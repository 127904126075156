import { Box, Grid, Stack } from '@mui/material';
import { DatePickerControl, Dropzone } from 'components/inputs';
import { BlockForm } from 'pages/Course/view/tabs/Content/types';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import CheckboxControl from 'components/inputs/control/CheckboxControl';
import TextFieldControl from 'components/inputs/control/TextFieldControl';

interface BlockDataFormProps {
  form: UseFormReturn<BlockForm>;
  blockItemName: `blocks.${number}`;
}

const BlockDataForm = ({ form, blockItemName }: BlockDataFormProps) => {
  const { setValue, watch, getValues } = form;
  const blockLessons = watch(`${blockItemName}.lessons`);

  const trialLessonsCount = blockLessons?.filter((lesson) => lesson.trial).length;

  const coverFileUrl = watch(`${blockItemName}.coverFileUrl`);

  const resetImg = () => {
    setValue(`${blockItemName}.coverFileUrl`, undefined);
    setValue(`${blockItemName}.coverFile`, null);
  };

  const onTrialChange = (checked: boolean) => {
    blockLessons?.forEach((_, index) => {
      const isLessonTrial = getValues(`${blockItemName}.lessons.${index}.trial`);
      if (isLessonTrial !== checked) {
        setValue(`${blockItemName}.lessons.${index}.trial`, checked);
      }
    });
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <TextFieldControl name={`${blockItemName}.title`} label='Название блока' />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2.5}>
          <DatePickerControl name={`${blockItemName}.dateFrom`} label='Доступен с' />
        </Grid>
        <Grid item xs={2.5}>
          <DatePickerControl name={`${blockItemName}.dateTo`} label='Доступен по' />
        </Grid>
      </Grid>
      <CheckboxControl
        label={`Доступен в тестовом доступе (${trialLessonsCount || 0})`}
        name={`${blockItemName}.trial`}
        handleChange={onTrialChange}
      />
      <Stack width={200} height={100} position='relative' borderRadius='10px'>
        {coverFileUrl && (
          <Stack position='absolute' width='100%' height='100%' overflow='hidden'>
            <img src={coverFileUrl} width='100%' height='100%' style={{ objectFit: 'cover' }} alt='обложка курса' />
          </Stack>
        )}
        <Box
          sx={{ opacity: coverFileUrl ? 0 : 1, '&:hover': { opacity: 0.8 } }}
          width='100%'
          height='100%'
          overflow='hidden'
        >
          <Dropzone<BlockForm>
            setValue={setValue}
            aspect={23 / 16}
            name={`${blockItemName}.coverFile`}
            nameUrl={`${blockItemName}.coverFileUrl`}
          />
        </Box>
        {coverFileUrl && (
          <ActionButton
            isFilled
            type={ActionButtonEnum.DELETE}
            handleClick={resetImg}
            sx={{ position: 'absolute', right: 10, top: 10 }}
          />
        )}
      </Stack>
    </>
  );
};

export default BlockDataForm;

import { Editor, FileLoader, UploadResponse } from 'ckeditor5';
import client from 'config/apolloConfig';
import cookies from 'js-cookie';
import { gql } from '@apollo/client';

class ImageUploadAdapter {
  private readonly loader: FileLoader;

  constructor(loader: FileLoader) {
    this.loader = loader;
  }

  public upload(): Promise<UploadResponse> {
    return this.loader.file.then(
      (file: File | null) =>
        new Promise((resolve, reject) => {
          ImageUploadAdapter.uploadFile(resolve, reject, file);
        }),
    );
  }

  static async uploadFile(resolve: (value: UploadResponse) => void, reject: (reason?: any) => void, file: File | null) {
    if (!file) {
      reject('No file found');
      return;
    }

    const accessToken = cookies.get('accessToken');

    if (!accessToken) {
      reject('No access token');
      return;
    }

    const UPLOAD_FILE_MUTATION = gql`
      mutation uploadFile($file: Upload!) {
        uploadFile(file: $file) {
          id
          name
          url
        }
      }
    `;

    try {
      const response = await client.mutate({
        mutation: UPLOAD_FILE_MUTATION,
        variables: { file },
        context: {
          headers: {
            Authorization: `JWT ${accessToken}`,
          },
        },
      });

      const { data } = response;
      if (data && data.uploadFile && data.uploadFile.url) {
        resolve({
          default: data.uploadFile.url,
        });
      } else {
        reject('Upload failed');
      }
    } catch (error) {
      reject(error);
    }
  }
}

export default function ImageUploadAdapterPlugin(editor: Editor) {
  // eslint-disable-next-line no-param-reassign
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new ImageUploadAdapter(loader);
}

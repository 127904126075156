import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { UseFormReturn, Path, UseFieldArrayReturn } from 'react-hook-form';
import { ButtonWithFile, TextFieldControl } from 'components/inputs';
import { getFileName } from 'helpers/common';
import { ElementFileType } from '@generated/graphql';
import { DEFAULT_FILE_STRUCTURE } from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/elements/DownloadFiles/constants';
import AttachDownloadFile from './AttachDownloadFile';
import { ExerciseForm, IFile } from '../../../types/formTypes';

interface DownloadFileProps {
  form: UseFormReturn<ExerciseForm>;
  filePairIndex: number;
  filePairsFieldName: `elements.${number}.filePairs`;
  disabled?: boolean;
  field: IFile;
  filePairFieldArray: UseFieldArrayReturn<ExerciseForm, `elements.${number}.filePairs`, 'formId'>;
}

const DownloadFile: FC<DownloadFileProps> = ({
  form,
  field,
  filePairFieldArray,
  filePairIndex,
  filePairsFieldName,
  disabled,
}) => {
  const { update } = filePairFieldArray;
  const { getValues } = form;

  const currentFilePairName: Path<ExerciseForm> = `${filePairsFieldName}.${filePairIndex}`;
  const currentFile = field || {};
  const isFileExist = !!currentFile?.file;

  const deleteFile = () => {
    update(filePairIndex, {
      cover: getValues(`${currentFilePairName}.cover`),
      download: {
        ...DEFAULT_FILE_STRUCTURE,
        type: ElementFileType.Download,
      },
    });
  };

  const currentFileNameWithExtension = currentFile.fileName || currentFile?.file?.name;
  const fileExtension = getFileName(currentFileNameWithExtension, 'extension');

  if (isFileExist) {
    return (
      <Stack spacing={1}>
        <TextFieldControl
          name={`${currentFilePairName}.download.fileNameWithoutExtension`}
          label='Название файла'
          rules={{ required: true }}
          endIcon={fileExtension ? `.${fileExtension}` : ''}
        />
        <ButtonWithFile
          onDelete={() => deleteFile()}
          file={currentFile.file}
          disabled={disabled}
          fileName={currentFileNameWithExtension}
        />
      </Stack>
    );
  }

  return (
    <AttachDownloadFile
      isCover={false}
      {...{ form, disabled, currentFilePairName, filePairIndex, filePairFieldArray }}
    />
  );
};

export default DownloadFile;

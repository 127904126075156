import { formatISO, set } from 'date-fns';
import {
  CreateOrUpdateCourseMutation,
  UpdateCourseInput,
  useColorThemesQuery,
  useCreateOrUpdateCourseMutation,
} from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { CourseDescription, ICourseDescriptionInputs } from 'pages/Course/view/tabs/Description/types';

const useSubmitCourseDescriptionForm = () => {
  const { addToast } = useContext(ToastContext);
  const onSuccess = () => addToast({ type: ToastTypeEnum.SUCCESS });
  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const navigate = useNavigate();

  const { data: colorScheme } = useColorThemesQuery({
    variables: { limit: 10, page: 0 },
  });

  const [updateCourse, { loading: updateLoading }] = useCreateOrUpdateCourseMutation();

  const onSubmit = (formData: ICourseDescriptionInputs, course: CourseDescription | undefined) => {
    const { dateFrom, dateTo, timeFrom, timeTo, description, title } = formData;

    const generateDate = (date: string, time: string) => {
      if (!date || !time) return undefined;
      const [hours, minutes] = [time.slice(0, 2), time.slice(2).replace(':', '')];
      return formatISO(set(new Date(date), { hours: Number(hours), minutes: Number(minutes) }));
    };

    const formattedDateFrom = generateDate(dateFrom, timeFrom);
    const formattedDateTo = generateDate(dateTo, timeTo);

    const getCoverFile = () => {
      if (!formData.coverFile) return null;
      if ('preview' in formData.coverFile && formData.coverFile.preview) return formData.coverFile;
      return undefined;
    };

    const coverFile = getCoverFile();

    const input = {
      colorThemeId: colorScheme?.colorThemes.data?.[0].id,
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
      description,
      title,
      id: course?.id,
      subject: formData.subject!.id,
      grade: parseFloat(formData.grade!.id),
      coverFile,
      cost: course?.cost || 0,
      acquiringId: course?.acquiring?.id,
    } as UpdateCourseInput;

    updateCourse({
      variables: {
        input,
      },
    })
      .then((res) => {
        const resData: CreateOrUpdateCourseMutation | undefined | null = res.data;
        navigate(`../${resData?.createOrUpdateCourse.id}`);
        onSuccess();
      })
      .catch(() => onError());
  };

  return { onSubmit, updateLoading };
};

export default useSubmitCourseDescriptionForm;

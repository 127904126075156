import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { Form } from 'components/inputs';
import { Button, Stack } from '@mui/material';
import Element from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/Element';
import AddIcon from '@mui/icons-material/Add';
import SaveButton from 'pages/Course/view/tabs/Content/components/SaveButton';
import React, { useContext } from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import onSaveExercise from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/helpers/onSaveExercise';
import { GetExerciseElementsQuery, QuestionScoreType, useUpdateElementsMutation } from '@generated/graphql';
import { useFieldArray, useForm } from 'react-hook-form';
import { ExerciseForm } from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types/formTypes';
import onSetFormValues from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/helpers/onSetFormValues';
import StrictModeDroppable from 'components/StrictModeDroppable';

interface ElementListProps {
  exercise: GetExerciseElementsQuery['exercise'];
}

const ElementList = ({ exercise }: ElementListProps) => {
  const { addToast } = useContext(ToastContext);

  const form = useForm<ExerciseForm>({
    values: onSetFormValues(exercise),
  });
  const { control, handleSubmit } = form;

  const fieldArray = useFieldArray({
    control,
    keyName: 'formId',
    name: 'elements',
  });

  const { fields, append, move } = fieldArray;

  const appendElement = () => {
    append({
      type: null,
      open: true,
      scoreType: QuestionScoreType.Full,
      id: undefined,
      files: null,
    });
  };

  const [updateElements, { loading: updateElementsLoading }] = useUpdateElementsMutation();

  const onSubmit = (formData: any) => {
    updateElements({
      variables: {
        input: {
          elements: onSaveExercise(formData),
          exerciseId: exercise?.id!,
        },
      },
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onError = () => {
    addToast({ type: ToastTypeEnum.ERROR });
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    const destinationIndex = destination?.index as number;
    const sourceIndex = source.index;

    move(sourceIndex, destinationIndex);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Form form={form} onSubmit={handleSubmit(onSubmit, onError)}>
        <Stack spacing={3}>
          <StrictModeDroppable droppableId='elements'>
            {(providedDrop) => (
              <Stack spacing={1} ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                {fields.map((element, index) => (
                  <Draggable key={`element-${element.formId}`} draggableId={`element-${element.formId}`} index={index}>
                    {(providedDrag) => (
                      <div
                        ref={providedDrag.innerRef}
                        {...providedDrag.draggableProps}
                        {...providedDrag.dragHandleProps}
                      >
                        <Element
                          form={form}
                          elementIndex={index}
                          element={element}
                          fieldArray={fieldArray}
                          key={`element-${element.formId}`}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {providedDrop.placeholder}
              </Stack>
            )}
          </StrictModeDroppable>
          <Button startIcon={<AddIcon />} sx={{ width: 'fit-content' }} onClick={() => appendElement()}>
            элемент
          </Button>
          <SaveButton loading={updateElementsLoading} />
        </Stack>
      </Form>
    </DragDropContext>
  );
};

export default ElementList;

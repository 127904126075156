import { Stack } from '@mui/material';
import React, { ReactElement } from 'react';
import CopyButton, { CopyButtonProps } from 'components/buttons/copyButton/CopyButton';

interface CopyButtonContainerProps extends CopyButtonProps {
  children: ReactElement;
}

const CopyButtonContainer = ({ copyText, children }: CopyButtonContainerProps) => (
  <Stack direction='row' alignItems='center'>
    {children}
    <CopyButton copyText={copyText} />
  </Stack>
);

export default CopyButtonContainer;

import { formatISODate } from 'helpers/date';
import { SUBJECT_NAME } from 'constants/global';
import { ITableStructure } from 'layouts/TableLayout/types';
import { CourseItem } from 'pages/Course/list/types';

const COURSES_TABLE: ITableStructure<CourseItem>[] = [
  {
    title: 'ID',
    value: (course) => course.numericId || '—',
    loadingValue: '1355',
  },
  {
    title: 'Название',
    value: (course) => course.title || '—',
    loadingValue: '1355',
  },
  {
    title: 'Предмет',
    value: ({ subject }) => (subject ? SUBJECT_NAME[subject] : '—'),
    loadingValue: 'Биология',
  },
  {
    title: 'Начало',
    value: (course) => (course.dateFrom ? formatISODate(course.dateFrom) : '—'),
    loadingValue: '21.21.2222',
  },
  {
    title: 'Окончание',
    value: (course) => (course.dateTo ? formatISODate(course.dateTo) : '—'),
    loadingValue: '21.21.2222',
  },
  {
    title: 'Участники',
    value: (course) => course.usersCount || '0',
    loadingValue: '100',
  },
  {
    title: 'Скрыт',
    value: (course) => (course.isHidden ? 'Да' : 'Нет'),
    loadingValue: '100',
  },
  {
    title: 'Дата создания',
    value: (course) => (course.createdAt ? formatISODate(course.createdAt) : '—'),
    loadingValue: '100',
  },
];

export default COURSES_TABLE;

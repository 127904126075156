import React, { BaseSyntheticEvent, FC, ReactElement } from 'react';
import { Box, Button, IconButton, Menu, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MenuButton: FC<{ children: ReactElement[] }> = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLAnchorElement) | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: BaseSyntheticEvent) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Stack spacing={2} p={2}>
          {children?.map((button, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={i} onClick={handleClose}>
              <Button {...button.props} fullWidth />
            </Box>
          ))}
        </Stack>
      </Menu>
    </div>
  );
};

export default MenuButton;

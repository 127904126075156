import React, { FC } from 'react';
import { Path, UseFormReturn } from 'react-hook-form';
import { ElementFileType } from '@generated/graphql';
import { Stack } from '@mui/material';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import { ExerciseForm, FilePairFieldArrayType } from '../../../types/formTypes';
import { DEFAULT_FILE_STRUCTURE } from '../constants';

interface IImageFileProps {
  form: UseFormReturn<ExerciseForm>;
  filePairIndex: number;
  filePairFieldArray: FilePairFieldArrayType;
  currentFilePairName: `elements.${number}.filePairs.${number}`;
}

const ImageFile: FC<IImageFileProps> = ({ form, filePairFieldArray, filePairIndex, currentFilePairName }) => {
  const { getValues, watch } = form;
  const { update } = filePairFieldArray;

  const resetImg = () => {
    update(filePairIndex, {
      cover: { ...DEFAULT_FILE_STRUCTURE, type: ElementFileType.Cover },
      download: getValues(`${currentFilePairName}.download`),
    });
  };

  const coverFileUrlFieldName: Path<ExerciseForm> = `${currentFilePairName}.cover.file.url`;
  const coverFilePreviewFieldName: Path<ExerciseForm> = `${currentFilePairName}.cover.file.preview`;

  const coverFileUrl = watch(coverFileUrlFieldName) || watch(coverFilePreviewFieldName);

  return (
    <Stack width={300} height={300} position='relative' borderRadius='10px'>
      <Stack position='absolute' width='100%' height='100%' overflow='hidden'>
        <img src={coverFileUrl} width='100%' height='100%' style={{ objectFit: 'cover' }} alt='обложка курса' />
      </Stack>

      {coverFileUrl && (
        <ActionButton
          isFilled
          type={ActionButtonEnum.DELETE}
          handleClick={resetImg}
          sx={{ position: 'absolute', right: 10, top: 10 }}
        />
      )}
    </Stack>
  );
};

export default ImageFile;

export type DefaultGeneratedOption = Record<string, any>;
export type GeneratedOption<T> = { id: keyof T; displayName: string };

const generateOptions = <T extends Record<string, any> = DefaultGeneratedOption>(object: T): GeneratedOption<T>[] =>
  Object.entries(object).reduce(
    (acc: GeneratedOption<T>[], current: [GeneratedOption<T>['id'], GeneratedOption<T>['displayName']]) => {
      const newValue: GeneratedOption<T> = {
        displayName: current[1],
        id: current[0],
      };
      acc.push(newValue);
      return acc;
    },
    [],
  );

export default generateOptions;

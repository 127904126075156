import { getFileName } from 'helpers/common';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import TextFieldControl from 'components/inputs/control/TextFieldControl';
import ButtonWithFile from 'components/inputs/control/ButtonWithFile';
import { ExerciseForm, IFile } from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types/formTypes';
import { UseFormReturn } from 'react-hook-form';

interface IFileItemProps {
  currentFile: IFile;
  elementIndex: number;
  fileIndex: number;
  disabled?: boolean;
  form: UseFormReturn<ExerciseForm>;
}

const FileItem = ({ currentFile, elementIndex, fileIndex, disabled, form }: IFileItemProps) => {
  const { getValues, setValue } = form;

  const currentFileNameWithExtension = currentFile.fileName || currentFile?.file?.name;
  const fileExtension = getFileName(currentFileNameWithExtension, 'extension');

  const deleteFile = (index: number) => {
    const currentFiles = getValues(`elements.${elementIndex}.files`) || [];
    currentFiles.splice(index, 1);
    setValue(`elements.${elementIndex}.files`, currentFiles);
  };

  return (
    <Grid key={currentFile.fileName} item xs={12} md={3}>
      <Stack spacing={1}>
        <TextFieldControl
          name={`elements.${elementIndex}.files.${fileIndex}.fileNameWithoutExtension`}
          label='Название файла'
          rules={{ required: true }}
          endIcon={fileExtension ? `.${fileExtension}` : ''}
        />
        <ButtonWithFile
          sx={{ width: 'auto' }}
          onDelete={() => deleteFile(fileIndex)}
          file={currentFile.file}
          disabled={disabled}
          fileName={currentFileNameWithExtension}
        />
      </Stack>
    </Grid>
  );
};

export default FileItem;

import React, { FC, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import Exercise from 'pages/Course/view/tabs/Content/components/Exercise';
import ButtonsWrapper, { ButtonVariantEnum } from 'components/buttons/ButtonWrapper';
import { BlockForm } from 'pages/Course/view/tabs/Content/types';
import AddItemButton from 'pages/Course/view/tabs/Content/components/AddItemButton';
import LessonDataForm from 'pages/Course/view/tabs/Content/components/Lesson/LessonDataForm';
import ItemAccordion from 'pages/Course/view/tabs/Content/components/ItemAccordion';

type LessonProps = {
  form: UseFormReturn<BlockForm>;
  lessonIndex: number;
  blockIndex: number;
};

const Lesson: FC<LessonProps> = ({ form, lessonIndex, blockIndex }) => {
  const {
    watch,
    formState: { errors },
    control,
    setValue,
  } = form;

  const lessonItemName: `blocks.${number}.lessons.${number}` = `blocks.${blockIndex}.lessons.${lessonIndex}`;

  const exerciseFieldArray = useFieldArray<BlockForm, `blocks.${number}.lessons.${number}.exercises`, 'formId'>({
    control,
    keyName: 'formId',
    name: `${lessonItemName}.exercises`,
  });
  const { fields, append } = exerciseFieldArray;

  const lesson = watch(lessonItemName);
  const isOpen = lesson?.open;

  const lessonHasError = !!errors?.blocks?.[blockIndex]?.lessons?.[lessonIndex];

  useEffect(() => {
    if (lessonHasError) {
      setValue(`blocks.${blockIndex}.lessons.${lessonIndex}.open`, true);
    }
  }, [lessonHasError]);

  const appendExercise = () => {
    append({ title: '', type: null, id: null, inProgress: false, timeLimit: null, isTimeLimit: false });
  };

  return (
    <ItemAccordion
      isError={lessonHasError}
      isOpen={isOpen}
      onAccordionChange={() => setValue(`${lessonItemName}.open`, !isOpen)}
      summary={
        <Box sx={{ display: 'flex' }}>
          <Typography variant='h6'>{`${lessonIndex + 1}. Урок ${lesson?.title && `«${lesson.title}»`}`}</Typography>
        </Box>
      }
      details={
        isOpen ? (
          <Stack spacing={3} sx={{ flexGrow: 1 }}>
            <LessonDataForm form={form} blockIndex={blockIndex} lessonItemName={lessonItemName} />
            {fields?.map((lessonPart, index) => (
              <ButtonsWrapper
                key={`lesson-part-${lessonPart.formId}`}
                data={lessonPart}
                fieldArray={exerciseFieldArray}
                index={index}
                variant={ButtonVariantEnum.LESSON_PART}
              >
                <Exercise parentName={`${lessonItemName}.exercises.${index}`} form={form} />
              </ButtonsWrapper>
            ))}
            {fields.length < 2 && <AddItemButton title='часть урока' onClick={appendExercise} />}
          </Stack>
        ) : undefined
      }
    />
  );
};

export default Lesson;

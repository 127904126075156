import { format, isValid } from 'date-fns';
import {
  AdminBlockListFilterInput,
  BlockAvailabilityType,
  BlocksQuery,
  Exact,
  useUpdateBlocksMutation,
} from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { BlockForm } from 'pages/Course/view/tabs/Content/types';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloQueryResult } from '@apollo/client';

type BlockRefetch = (
  variables?: Partial<Exact<{ filter: AdminBlockListFilterInput; limit: number; page: number }>> | undefined,
) => Promise<ApolloQueryResult<BlocksQuery>>;

type BlockItem = BlockForm['blocks'][number];

const useUpdateBlocks = () => {
  const params = useParams<{ courseId: string }>();
  const courseId = params.courseId as string;

  const { addToast } = useContext(ToastContext);
  const [updateBlock, { loading: updateBlockLoading }] = useUpdateBlocksMutation();

  const getCoverFile = (block: BlockItem) => {
    if (!block.coverFile && !block.coverFileUrl) return null;
    if (block.coverFile?.preview) return block.coverFile;
    return undefined;
  };

  const checkDate = (date: Date | string | null | undefined) =>
    date && typeof date !== 'string' && isValid(date) ? format(date, 'yyyy-MM-dd') : null;

  const convertTime = (time: string | null | undefined) => {
    if (!time) return null;
    const [hours, minutes, seconds] = time.match(/.{1,2}/g) ?? [];
    if (hours && minutes && seconds) return +hours * 60 * 60 + +minutes * 60 + +seconds;
    return null;
  };

  const getAvailabilityType = (block: BlockItem) => {
    if (block.trial) return BlockAvailabilityType.Trial;
    if (block.lessons.some((lesson) => lesson.trial)) return BlockAvailabilityType.Mixed;
    return BlockAvailabilityType.Payable;
  };

  const onUpdateBlock = (formData: BlockForm, refetch: BlockRefetch) => {
    updateBlock({
      variables: {
        input: {
          courseId,
          blocks: formData.blocks.map((block) => ({
            title: block.title,
            id: block.id,
            availabilityType: getAvailabilityType(block),
            cost: block.cost || 0,
            dateFrom: checkDate(block.dateFrom),
            dateTo: checkDate(block.dateTo),
            coverFile: getCoverFile(block),
            lessons: block.lessons?.length
              ? block.lessons?.map((lesson) => ({
                  id: lesson.id,
                  title: lesson.title,
                  trial: lesson.trial,
                  dateFrom: checkDate(lesson.dateFrom),
                  dateTo: checkDate(lesson.dateTo),
                  exercises: lesson.exercises?.map((exercise) => ({
                    id: exercise.id,
                    title: exercise.title,
                    type: typeof exercise.type === 'string' ? exercise.type : exercise.type!.id, // TODO check sting
                    inProgress: exercise.inProgress,
                    timeLimit: exercise.isTimeLimit ? convertTime(exercise.timeLimit) : null,
                  })),
                }))
              : undefined,
          })),
        },
      },
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS });
        refetch();
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };
  return { onUpdateBlock, updateBlockLoading };
};

export default useUpdateBlocks;

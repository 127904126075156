import React from 'react';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { ApolloError } from '@apollo/client';
import { UseFormReturn } from 'react-hook-form';
import { InputsGeneratorProps } from 'components/generators/inputsGenerator';
import * as yup from 'yup';
import { RefetchFunction } from '@apollo/client/react/hooks/useSuspenseQuery';
import { SxProps } from '@mui/material';

export type Order = 'asc' | 'desc';

export const ENUM_PAGE = {
  first: 'первую',
  last: 'последнюю',
  previous: 'предыдущую',
  next: 'следующую',
};

export interface ITableStructure<Row extends Identifiable> {
  title: string;
  value: (row: Row) => string | number | undefined | null | React.JSX.Element;
  loadingValue: string | undefined;
  hide?: boolean;
  align?: TableCellProps['align'];
  tooltip?: string | ((row?: Row) => string);
  sortName?: string;
}

export interface Identifiable {
  id: string;
}

export type TableLayoutProps<
  Row extends Identifiable,
  Filters extends Record<string, any> | undefined = undefined,
  OrderField extends string | undefined = undefined,
> = {
  loading: boolean;
  error?: ApolloError;
  filtersFunc?: (form?: UseFormReturn) => InputsGeneratorProps[];
  validationSchema?: yup.ObjectSchema<any>;
  data: Row[] | undefined | null;
  tableStructure: ITableStructure<Row>[];
  totalCount?: number | undefined;
  refetch?: RefetchFunction<any, any>;
  defaultFilter?: Filters;
  defaultOrder?: { order: Order; field: OrderField };
  action?: (row: Row) => void;
  rowSx?: (row: Row) => SxProps;
};

import { formatISODate } from 'helpers/date';
import { IconButton } from '@mui/material';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import React, { useContext } from 'react';
import { ModalContext } from 'context/modalContext';
import {
  AdminBlockListFilterInput,
  Exact,
  useCreateUserBlockMutation,
  useDeleteUserBlockMutation,
  UserBlocksQuery,
} from '@generated/graphql';
import { ArrayElement } from 'types/common';
import { createUserName } from 'helpers/common';
import { USER_BLOCK_TYPE_TITLE } from 'constants/global';
import { ITableStructure } from 'layouts/TableLayout/types';
import { ApolloQueryResult } from '@apollo/client';

type Block = ArrayElement<UserBlocksQuery['blocks']['data']>;
type Refetch = (
  variables?:
    | Partial<
        Exact<{ filter: AdminBlockListFilterInput; limit: number; page: number; userId: string; courseId: string }>
      >
    | undefined,
) => Promise<ApolloQueryResult<UserBlocksQuery>>;

const USER_BLOCKS_TABLE = (refetch: Refetch, user: UserBlocksQuery['user'] | undefined): ITableStructure<Block>[] => {
  const { handleOpen } = useContext(ModalContext);
  const { addToast } = useContext(ToastContext);
  const [addBlock] = useCreateUserBlockMutation();
  const [deleteBlock] = useDeleteUserBlockMutation();
  return [
    {
      title: 'Название блока',
      value: (block) => block.title || '—',
      loadingValue: 'Название',
    },
    {
      title: 'Дата приобретения курса',
      value: (block) => (block.userBlock?.createdAt ? formatISODate(block.userBlock?.createdAt) : '—'),
      loadingValue: '20,20,0000',
    },
    {
      title: 'Способ приобретения',
      value: (block) => (block.userBlock?.type ? USER_BLOCK_TYPE_TITLE[block.userBlock?.type] : '—'),
      loadingValue: 'ТТТТ',
    },
    {
      title: 'Стоимость приобретения',
      value: (block) => block.cost,
      loadingValue: '1000',
    },
    // {
    //   title: 'Промокод',
    //   value: (block: Block) => block.cost,
    //   loadingValue: '1000'
    // },
    {
      title: 'Действие',
      value: (block) => {
        const isBlockBought = block.userBlock?.type;
        if (isBlockBought) {
          return (
            <IconButton
              size='large'
              color='error'
              onClick={(e) => {
                e.stopPropagation();
                handleOpen({
                  title: 'Удалить блок у пользователя',
                  content: `Вы уверены, что хотите удалить блок «${block?.title}» у пользователя ${createUserName(user)}?`,
                  handleClick: async () =>
                    deleteBlock({
                      variables: { userId: user?.id!, blockId: block?.id! },
                    })
                      .then(() => {
                        addToast({ type: ToastTypeEnum.SUCCESS });
                        refetch();
                      })
                      .catch(() => addToast({ type: ToastTypeEnum.ERROR })),
                });
              }}
            >
              <CloseIcon />
            </IconButton>
          );
        }
        return (
          <IconButton
            size='large'
            color='success'
            onClick={(e) => {
              e.stopPropagation();
              handleOpen({
                title: 'Добавить блок пользователю',
                buttonText: 'Добавить',
                content: `Вы уверены, что хотите добавить блок «${block?.title}» пользователю ${createUserName(user)}?`,
                handleClick: async () =>
                  addBlock({
                    variables: { input: { userId: user?.id!, blockId: block?.id! } },
                  })
                    .then(() => {
                      addToast({ type: ToastTypeEnum.SUCCESS });
                      refetch();
                    })
                    .catch(() => addToast({ type: ToastTypeEnum.ERROR })),
              });
            }}
          >
            <AddIcon />
          </IconButton>
        );
      },
      tooltip: (block) => (block?.userBlock?.type ? 'Удалить блок у пользователя' : 'Добавить блок пользователю'),
      loadingValue: 'Х',
    },
  ];
};

export default USER_BLOCKS_TABLE;

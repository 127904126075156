import React, { forwardRef, useImperativeHandle } from 'react';
import { Stack } from '@mui/material';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { Draggable } from 'react-beautiful-dnd';
import LessonCard from 'pages/Course/view/tabs/Content/components/Block/components/LessonCard';
import StrictModeDroppable from 'components/StrictModeDroppable';
import { BlockForm } from '../../types';
import BlockDataForm from './components/BlockDataForm';
import AddItemButton from '../AddItemButton';

type BlockProps = {
  form: UseFormReturn<BlockForm>;
  blockIndex: number;
};

type LessonForm = BlockForm['blocks'][number]['lessons'][number];

export type BlockRef =
  | {
      removeLessonFromBlock: (lessonIndex: number) => void;
      moveLesson: (sourceLessonIndex: number, destinationLessonIndex: number) => void;
      prependLesson: (lesson: LessonForm) => void;
      appendLesson: (lesson: LessonForm) => void;
      insertLesson: (lesson: LessonForm, destinationLessonIndex: number) => void;
    }
  | undefined
  | null;

const Block = forwardRef<BlockRef, BlockProps>(({ form, blockIndex }, ref) => {
  const {
    control,
    formState: { errors },
  } = form;

  const blockItemName: `blocks.${number}` = `blocks.${blockIndex}`;

  const lessonFieldArray = useFieldArray<BlockForm, `blocks.${number}.lessons`, 'formId'>({
    control,
    keyName: 'formId',
    name: `${blockItemName}.lessons`,
  });

  const { append, fields, remove, move, prepend, insert } = lessonFieldArray;

  const appendLesson = () => {
    append({ title: '', trial: false, open: false, id: undefined });
  };

  const blockError = !!errors?.blocks?.[blockIndex];

  useImperativeHandle(ref, () => ({
    removeLessonFromBlock(lessonIndex: number) {
      remove(lessonIndex);
    },
    moveLesson(sourceLessonIndex: number, destinationLessonIndex: number) {
      move(sourceLessonIndex, destinationLessonIndex);
    },
    prependLesson(lesson: LessonForm) {
      prepend(lesson);
    },
    appendLesson(lesson: LessonForm) {
      append(lesson);
    },
    insertLesson(lesson: LessonForm, destinationLessonIndex: number) {
      insert(destinationLessonIndex, lesson);
    },
  }));

  return (
    <Stack
      spacing={1}
      name={`blocks-${blockIndex}`}
      sx={{
        border: ({ palette }) => `1px solid ${blockError ? palette.error.main : palette.divider}`,
        p: 3,
        borderRadius: '10px',
        flexGrow: 1,
      }}
    >
      <BlockDataForm form={form} blockItemName={blockItemName} />
      <StrictModeDroppable droppableId={`blocks.${blockIndex}`}>
        {(providedDrop) => (
          <Stack spacing={1} ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
            {fields.map((lesson, index) => (
              <Draggable key={`lesson-${lesson.formId}`} draggableId={`lesson-${lesson.formId}`} index={index}>
                {(providedDrag) => (
                  <div ref={providedDrag.innerRef} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps}>
                    <LessonCard
                      lessonFieldArray={lessonFieldArray}
                      lessonIndex={index}
                      lesson={lesson}
                      form={form}
                      blockIndex={blockIndex}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {providedDrop.placeholder}
          </Stack>
        )}
      </StrictModeDroppable>
      <AddItemButton title='урок' onClick={appendLesson} />
    </Stack>
  );
});

export default Block;

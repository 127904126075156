import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Filters from 'components/FiltersGroup';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import SkeletonTable from 'components/SkeletonTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash';
import { generateFilterQuery } from 'components/generators/filtersGenerator';
import { ENUM_PAGE, Identifiable, Order, TableLayoutProps } from 'layouts/TableLayout/types';

const TableLayout = <
  Row extends Identifiable,
  Filters extends Record<string, any> | undefined = undefined,
  OrderField extends string | undefined = undefined,
>({
  loading,
  error,
  filtersFunc,
  validationSchema,
  data,
  tableStructure,
  totalCount = 0,
  refetch,
  defaultFilter,
  defaultOrder,
  action,
  rowSx,
}: TableLayoutProps<Row, Filters, OrderField>) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const { limit, page } = params as unknown as { limit: number; page: number };
  const [order, setOrder] = React.useState<Order | undefined>(defaultOrder?.order);
  const [orderBy, setOrderBy] = React.useState<string | undefined>(defaultOrder?.field);

  const changePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
    setSearchParams(qs.stringify({ ...params, page: newPage }));

  const changeLimit = (event: React.ChangeEvent) =>
    setSearchParams(
      qs.stringify({
        ...params,
        limit: parseInt((event.target as HTMLTextAreaElement | HTMLInputElement).value, 10),
        page: 0,
      }),
    );

  const form = useForm({
    resolver: validationSchema && yupResolver(validationSchema),
  });
  const filters = filtersFunc?.(form);

  useEffect(() => {
    if (refetch) {
      refetch({
        page: Number(page) || 0,
        limit: Number(limit) || 10,
        filter: filters ? _.merge(defaultFilter, generateFilterQuery(filters, params)) : defaultFilter,
        order: order && orderBy ? { order: order.toLocaleUpperCase(), field: orderBy } : undefined,
      });
    }
  }, [params]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    setSearchParams(qs.stringify({ ...params, order: newOrder, orderBt: property }));
  };
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <>
      {filters?.length && <Filters filters={filters} form={form} />}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableStructure.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={`table-header-cell-${index}`}>
                  {item.sortName ? (
                    <TableSortLabel
                      active={orderBy === item.sortName}
                      direction={orderBy === item.sortName ? order : 'asc'}
                      onClick={createSortHandler(item.sortName!)}
                    >
                      {item.title}
                    </TableSortLabel>
                  ) : (
                    item.title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading || error ? (
            <SkeletonTable rows={limit} columns={tableStructure} />
          ) : (
            <TableBody>
              {data && data.length !== 0 ? (
                data.map((row) => (
                  <TableRow
                    key={`row-${row.id}`}
                    hover
                    sx={{
                      cursor: 'pointer',
                      ...(rowSx && rowSx(row)),
                    }}
                    onClick={() => (action ? action(row) : navigate(row.id))}
                  >
                    {tableStructure.map((item) => {
                      const tooltip = typeof item.tooltip === 'string' ? item.tooltip : item.tooltip?.(row);
                      const Cell = <TableCell key={`cell-${row.id}-${item.title}`}>{item.value(row) || '-'}</TableCell>;
                      return tooltip ? (
                        <Tooltip key={`tooltip-cell-${row.id}-${item.title}`} title={tooltip}>
                          {Cell}
                        </Tooltip>
                      ) : (
                        Cell
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell sx={{ textAlign: 'center' }} colSpan={tableStructure?.length}>
                    Нет данных
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {!!totalCount && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={totalCount || 0}
          rowsPerPage={Number(limit) || 10}
          page={Number(page) || 0}
          onPageChange={changePage}
          onRowsPerPageChange={changeLimit}
          showFirstButton
          showLastButton
          labelRowsPerPage='Количество строк'
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} из ${count !== -1 ? count : `больше, чем ${to}`}`}
          getItemAriaLabel={(type) => `Перейти на ${ENUM_PAGE[type]} страницу`}
        />
      )}
    </>
  );
};

export default TableLayout;

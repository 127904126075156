import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import TextFieldControl from 'components/inputs/control/TextFieldControl';
import Radio from '@mui/material/Radio';
import { UseFormReturn } from 'react-hook-form';
import { QuestionScoreType } from '@generated/graphql';
import { ExerciseForm, ExtendedElementTypes, QuestionComponents } from '../../types/formTypes';

interface IScoreSettingProps {
  elementIndex: number;
  elementType: QuestionComponents;
  form: UseFormReturn<ExerciseForm>;
}

interface IScoreSettingRadioButton {
  type: QuestionScoreType;
  label: string;
  elementIndex: number;
  form: UseFormReturn<ExerciseForm>;
}

const RadioButton: FC<IScoreSettingRadioButton> = ({ type, label, form, elementIndex }) => {
  const { setValue, watch } = form;

  const scoreType = watch(`elements.${elementIndex}.scoreType`);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(`elements.${elementIndex}.scoreType`, event.target.value as QuestionScoreType);
  };
  return (
    <Stack direction='row' alignItems='center'>
      <Radio
        checked={scoreType === type}
        onChange={handleChange}
        value={type}
        name={`elements.[${elementIndex}].scoreType`}
      />
      <Typography>{label}</Typography>
    </Stack>
  );
};

const ScoreSettings: FC<IScoreSettingProps> = ({ elementIndex, elementType, form }) => {
  const { watch } = form;

  const scoreType = watch(`elements.${elementIndex}.scoreType`);
  const isOnlyFullScore =
    elementType === ExtendedElementTypes.QUESTION_TEXT_ANSWERS ||
    elementType === ExtendedElementTypes.QUESTION_FILE_ANSWER;
  return (
    <Stack spacing={2}>
      {!isOnlyFullScore && (
        <RadioButton
          type={QuestionScoreType.Full}
          label='Проверка всего ответа'
          elementIndex={elementIndex}
          form={form}
        />
      )}
      <TextFieldControl
        disabled={scoreType === QuestionScoreType.Partial}
        name={`elements.${elementIndex}.maxScore`}
        label='Количество баллов за вопрос'
        rules={{ required: scoreType === QuestionScoreType.Full }}
      />
      {!isOnlyFullScore && (
        <RadioButton
          type={QuestionScoreType.Partial}
          label='Подсчет каждого ответа'
          elementIndex={elementIndex}
          form={form}
        />
      )}
      {!isOnlyFullScore && (
        <>
          <TextFieldControl
            disabled={scoreType === QuestionScoreType.Full}
            name={`elements.${elementIndex}.positiveScore`}
            label='Количество баллов за каждый правильный'
            rules={{ required: scoreType === QuestionScoreType.Partial }}
          />
          <TextFieldControl
            disabled={scoreType === QuestionScoreType.Full}
            name={`elements.${elementIndex}.negativeScore`}
            label='Количество баллов за каждый неправильный'
            rules={{ required: scoreType === QuestionScoreType.Partial }}
          />
        </>
      )}
    </Stack>
  );
};

export default ScoreSettings;

import { formatISODate } from 'helpers/date';
import { HOMEWORK_STATUS_COLOR, HOMEWORK_STATUS_NAME, SUBJECT_NAME } from 'constants/global';
import { AdminUserAnswerListOrderFields } from '@generated/graphql';
import { Chip, IconButton } from '@mui/material';
import { createUserName } from 'helpers/common';
import React from 'react';
import { Link } from 'react-router-dom';
import { Checklist } from '@mui/icons-material';
import { ITableStructure } from 'layouts/TableLayout/types';
import { UsersAnswerItem } from 'pages/Homework/list/common/types';

const HOMEWORK_TABLE: ITableStructure<UsersAnswerItem>[] = [
  {
    title: 'ID',
    value: (homework) => homework.user?.numericId || '—',
    loadingValue: '1355',
  },
  {
    title: 'ФИО',
    value: ({ user }) => createUserName(user),
    loadingValue: 'Иванов Иван Иваныч',
  },
  {
    title: 'Предмет',
    value: ({
      element: {
        exercise: {
          lesson: {
            block: {
              course: { subject },
            },
          },
        },
      },
    }) => (subject ? SUBJECT_NAME[subject] : '—'),
    loadingValue: '100',
  },
  {
    title: 'Класс',
    value: ({
      element: {
        exercise: {
          lesson: {
            block: {
              course: { grade },
            },
          },
        },
      },
    }) => grade || '—',
    loadingValue: '100',
  },
  {
    title: 'Дата изменения',
    value: (homework) => (homework.updatedAt ? formatISODate(homework.updatedAt, 'dd.MM.yyyy HH:mm') : '—'),
    loadingValue: '100',
    sortName: AdminUserAnswerListOrderFields.UpdatedAt,
  },
  {
    title: 'Статус',
    value: ({ status }) =>
      status ? (
        <Chip
          label={HOMEWORK_STATUS_NAME[status]}
          sx={{ backgroundColor: HOMEWORK_STATUS_COLOR[status] }}
          variant='outlined'
        />
      ) : (
        ''
      ),
    loadingValue: 'ХXXXXXXXXX',
  },
  {
    title: '',
    value: (homework) => (
      <IconButton
        size='large'
        component={Link}
        onClick={(e) => e.stopPropagation()}
        to={`${homework.element.exercise.lesson.block.course.id}/${homework.user.id}`}
        rel='noopener noreferrer'
      >
        <Checklist />
      </IconButton>
    ),
    tooltip: () => 'Список всех ДЗ пользователя',
    loadingValue: 'Х',
  },
];

export default HOMEWORK_TABLE;

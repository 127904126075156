import { ElementFileType, QuestionScoreType, QuestionType } from '@generated/graphql';
import {
  ExerciseForm,
  ExtendedElementTypes,
  FilePair,
  IExerciseLink,
  IFile,
  IFileElement,
  IHeadingElement,
  IQuestionElement,
  ITextElement,
  IVideoElement,
} from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types/formTypes';
import { getFileName } from 'helpers/common';
import { ELEMENT_NAME, ExtendedElementTypesExcludeFeedback } from 'constants/course';
import {
  ExerciseElements,
  ExerciseLinkElement,
  FileElement,
  HeadingElement,
  QuestionElement,
  TextElement,
  VideoElement,
} from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types/queryTypes';
import {
  isExerciseLinkElement,
  isFileElement,
  isHeadingElement,
  isTextElement,
  isVideoElement,
} from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types/guards';

const onSetExerciseLinkElement = (exerciseLinkElement: ExerciseLinkElement): IExerciseLink => {
  const type = ExtendedElementTypes.EXERCISE_LINK;
  return {
    id: exerciseLinkElement.id,
    type: { id: type, displayName: ELEMENT_NAME[type] },
    description: exerciseLinkElement.description || '',
    __typename: exerciseLinkElement.__typename || 'ExerciseLinkElement',
    open: false,
  };
};

const onSetFileElement = (fileElement: FileElement): IFileElement => {
  const type = ExtendedElementTypes.FILE;
  return {
    id: fileElement.id,
    __typename: fileElement.__typename || 'FileElement',
    type: { id: type, displayName: ELEMENT_NAME[type] },
    open: false,
    filePairs: fileElement.files?.reduce((acc: FilePair[], currentFile: IFile, index: number, array: IFile[]) => {
      if (currentFile.type === ElementFileType.Cover) {
        const cover = currentFile;
        const download = array[index + 1]; // следующий элемент — файл для скачивания
        acc.push({
          cover,
          download: {
            ...download,
            fileNameWithoutExtension: getFileName(download.fileName, 'name'),
          },
        });
      }
      return acc;
    }, []),
  };
};

const onSetHeadingElement = (headingElement: HeadingElement): IHeadingElement => {
  const type = ExtendedElementTypes.HEADING;
  return {
    id: headingElement.id,
    type: { id: type, displayName: ELEMENT_NAME[type] },
    open: false,
    __typename: headingElement.__typename || 'HeadingElement',
    title: headingElement.title,
  };
};

const onSetTextElement = (textElement: TextElement): ITextElement => {
  const type = ExtendedElementTypes.TEXT;
  return {
    id: textElement.id,
    type: { id: type, displayName: ELEMENT_NAME[type] },
    open: false,
    __typename: textElement.__typename || 'TextElement',
    content: textElement.content,
  };
};

const onSetVideoElement = (videoElement: VideoElement): IVideoElement => {
  const type = ExtendedElementTypes.VIDEO;
  return {
    id: videoElement.id,
    type: { id: type, displayName: ELEMENT_NAME[type] },
    open: false,
    __typename: videoElement.__typename || 'VideoElement',
    kinescopeId: videoElement.kinescopeId,
  };
};

const onSetQuestionElement = (questionElement: QuestionElement): IQuestionElement => {
  const {
    scoreType,
    questionType,
    description,
    title,
    id,
    placeholder,
    elementParts,
    files,
    maxScore,
    negativeScore,
    positiveScore,
  } = questionElement;
  const type = `${questionElement.type}_${questionType}` as ExtendedElementTypesExcludeFeedback;
  const isFullScore = scoreType === QuestionScoreType.Full;

  const isSingleAnswer = questionType === QuestionType.SingleAnswer;
  const isFewAnswer = questionType === QuestionType.FewAnswers;
  const isTextAnswer = questionType === QuestionType.TextAnswers;
  const isMatchingAnswer = questionType === QuestionType.Matching;

  return {
    id,
    type: { id: type, displayName: ELEMENT_NAME[type] },
    open: false,
    __typename: questionElement.__typename || 'QuestionElement',
    questionType,
    title,
    description: description || '',
    placeholder,
    ...((isSingleAnswer || isTextAnswer || isFewAnswer || isMatchingAnswer) && {
      elementParts: elementParts?.map((part) => ({
        __typename: part.__typename,
        id: part.id,
        content: isFewAnswer || isMatchingAnswer || isSingleAnswer ? part.content : undefined,
        isCorrect: isSingleAnswer || isFewAnswer ? part.isCorrect : undefined,
        correctAnswer: isMatchingAnswer || isTextAnswer ? part.correctAnswer : undefined,
      })),
    }),
    files: files?.map((file: IFile) => ({
      ...file,
      fileNameWithoutExtension: getFileName(file.fileName, 'name'),
    })),
    scoreType,
    ...(isFullScore
      ? {
          maxScore: (maxScore || 0)?.toString(),
        }
      : {
          negativeScore: (negativeScore || 0)?.toString(),
          positiveScore: (positiveScore || 0)?.toString(),
        }),
    ...(isSingleAnswer && {
      singleCorrectAnswer: elementParts?.findIndex((part) => part.isCorrect).toString(),
    }),
  };
};

// const onSetFeedbackElement = (element: FeedbackElement): IFeedback => {
//   const type = ExtendedElementTypes.FEEDBACK;
//   return {
//     id: element.id,
//     type: { id: type, displayName: ELEMENT_NAME[type] },
//     open: false,
//     elementParts: element.elementParts,
//     __typename: element.__typename,
//   };
// };

const onSetFormValues = (exercise: ExerciseElements): ExerciseForm => ({
  id: exercise?.id,
  title: exercise?.title,
  type: exercise?.type,
  elements:
    exercise?.elements?.map((elem) => {
      if (isExerciseLinkElement(elem)) {
        return onSetExerciseLinkElement(elem as ExerciseLinkElement);
      }
      if (isFileElement(elem)) {
        return onSetFileElement(elem as FileElement);
      }
      if (isHeadingElement(elem)) {
        return onSetHeadingElement(elem as HeadingElement);
      }
      if (isTextElement(elem)) {
        return onSetTextElement(elem as TextElement);
      }
      if (isVideoElement(elem)) {
        return onSetVideoElement(elem as VideoElement);
      }
      // if (isQuestionElement(elem)) {
      return onSetQuestionElement(elem as QuestionElement);
      // }
    }) || [],
});

export default onSetFormValues;

import { Button, SxProps } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

interface BackButtonProps {
  text: string;
  navigate: () => void;
  sx?: SxProps;
}

const BackButton = ({ text, navigate, sx }: BackButtonProps) => (
  <Button variant='text' onClick={navigate} sx={{ p: 0, width: 'fit-content', ...sx }}>
    <ArrowBack sx={{ color: 'base.400', mr: 1 }} />
    {text}
  </Button>
);

export default BackButton;

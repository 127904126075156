import React, { FC, useContext, useEffect } from 'react';
import { BlockAvailabilityType, PaymentQuery, useUpdateBlocksPaymentInfoMutation } from '@generated/graphql';
import { useForm } from 'react-hook-form';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { AVAILABILITY_TYPE_TITLE, LK_URL } from 'constants/global';
import { Form, TextFieldControl } from 'components/inputs';
import { useParams } from 'react-router-dom';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import CopyButtonContainer from 'components/buttons/copyButton/CopyButtonContainer';

const TABLE_HEADERS = ['Название блока', 'Способ приобретения', 'Стоимость', 'Ссылка для приобретения'];

interface IBlocksPaymentInputs {
  blocks: {
    __typename?: 'Block';
    id: string;
    title: string;
    availabilityType: BlockAvailabilityType;
    cost: string | number;
  }[];
}

const BlocksPayment: FC<{ blocks: PaymentQuery['blocks']['data'] }> = ({ blocks }) => {
  const { addToast } = useContext(ToastContext);

  const { courseId } = useParams<{ courseId: string }>();
  const form = useForm<IBlocksPaymentInputs>();
  const { reset, handleSubmit } = form;

  useEffect(() => {
    if (blocks?.length) {
      reset({ blocks });
    }
  }, [blocks]);

  const [updatePaymentInfo] = useUpdateBlocksPaymentInfoMutation();

  const onSubmit = (formData: IBlocksPaymentInputs) => {
    updatePaymentInfo({
      variables: {
        input: {
          courseId: courseId!,
          blocks: formData.blocks?.map((block) => ({
            id: block.id,
            cost: typeof block.cost === 'string' ? parseInt(block.cost, 10) : block.cost,
            availabilityType: block.availabilityType,
            title: block.title,
            lessons: null,
          })),
        },
      },
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  return (
    <Form form={form}>
      <Typography variant='h5'>Стоимость блоков</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEADERS.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={`table-header-cell-${index}`}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {blocks && blocks.length !== 0 ? (
              blocks.map((row, index) => (
                <TableRow hover key={`block-payment-row-${row.id}`}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{AVAILABILITY_TYPE_TITLE[row.availabilityType]}</TableCell>
                  <TableCell>
                    <TextFieldControl name={`blocks.${index}.cost`} />
                  </TableCell>
                  <TableCell>
                    <CopyButtonContainer copyText={`${LK_URL}/payment/${courseId}/${row.id}`}>
                      <Typography>{`${LK_URL}/payment/${courseId}/${row.id}`}</Typography>
                    </CopyButtonContainer>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }} colSpan={TABLE_HEADERS?.length}>
                  Нет данных
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant='contained' onClick={handleSubmit(onSubmit)} sx={{ mt: 2 }}>
        Сохранить
      </Button>
    </Form>
  );
};

export default BlocksPayment;

import React, { useContext } from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import FileAttachButton from 'components/inputs/control/FileAttachButton';
import { UseFormReturn } from 'react-hook-form';
import { FilePreview } from 'types/common';
import { getFileName } from 'helpers/common';
import { ExerciseForm } from '../../types/formTypes';

interface IAttachFileProps {
  form: UseFormReturn<ExerciseForm>;
  elementIndex: number;
  disabled?: boolean;
}

const AttachFile = ({ form, elementIndex, disabled }: IAttachFileProps) => {
  const { addToast } = useContext(ToastContext);
  const { setValue, getValues } = form;

  const onAttachFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];

    if (files.length > 0) {
      const filePromises = files.map((file) => {
        if (file.type.startsWith('image/')) {
          return new Promise<{ file: FilePreview; fileNameWithoutExtension: string }>((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
              const fileWithPreview = Object.assign(file, {
                preview: reader.result as string,
              });
              resolve({ file: fileWithPreview, fileNameWithoutExtension: getFileName(file.name, 'name') });
            };

            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        }
        return Promise.resolve({ file, fileNameWithoutExtension: getFileName(file.name, 'name') });
      });

      Promise.all(filePromises)
        .then((newFiles) => {
          const currentFiles = getValues(`elements.${elementIndex}.files`) || [];
          const mergedFiles = currentFiles.length ? currentFiles.concat(newFiles) : newFiles;
          setValue(`elements.${elementIndex}.files`, mergedFiles);
        })
        .catch((error) => {
          console.error('Ошибка при чтении файлов', error);
          addToast({ type: ToastTypeEnum.ERROR, text: 'Ошибка при чтении файлов' });
        });
    } else {
      addToast({ type: ToastTypeEnum.ERROR, text: 'Файл не был добавлен' });
    }
  };

  return <FileAttachButton multiple text='Прикрепить файлы' onChange={onAttachFile} disabled={disabled} />;
};

export default AttachFile;

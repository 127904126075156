import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { ReactNode } from 'react';

interface ItemAccordionProps {
  isError: boolean;
  isOpen: boolean;
  onAccordionChange: () => void;
  summary: ReactNode;
  details: ReactNode | undefined;
}

const ItemAccordion = ({ isError, isOpen, onAccordionChange, summary, details }: ItemAccordionProps) => (
  <Accordion
    sx={{
      width: '100%',
      border: ({ palette }) => `1px solid ${isError ? palette.error.main : palette.divider}`,
    }}
    expanded={isOpen}
    onChange={onAccordionChange}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between' } }}
    >
      {summary}
    </AccordionSummary>
    <AccordionDetails>{details}</AccordionDetails>
  </Accordion>
);

export default ItemAccordion;

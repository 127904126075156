import React, { FC } from 'react';
import { Box } from '@mui/material';
import { UseFormReturn, UseFieldArrayReturn, Path } from 'react-hook-form';
import { Dropzone } from 'components/inputs';
import { FilePreview } from 'types/common';
import { ExerciseForm, IFile } from '../../../types/formTypes';
import ImageFile from './ImageFile';

interface CoverFileProps {
  field: IFile;
  form: UseFormReturn<ExerciseForm>;
  filePairFieldArray: UseFieldArrayReturn<ExerciseForm, `elements.${number}.filePairs`, 'formId'>;
  filePairIndex: number;
  filePairsFieldName: `elements.${number}.filePairs`;
}

const CoverFile: FC<CoverFileProps> = ({ field, form, filePairFieldArray, filePairIndex, filePairsFieldName }) => {
  const { update } = filePairFieldArray;
  const { getValues } = form;

  const currentFilePairName: Path<ExerciseForm> = `${filePairsFieldName}.${filePairIndex}`;
  const isFileExist = !!field.file?.url || !!field?.file?.preview;

  const setNewCoverFile = (name: string, newFile: FilePreview | string) => {
    if (name === 'coverFile') {
      update(filePairIndex, {
        cover: { ...getValues(`${currentFilePairName}.cover`), file: newFile as IFile['file'] },
        download: getValues(`${filePairsFieldName}.${filePairIndex}.download`),
      });
    }
  };

  if (isFileExist) {
    return <ImageFile {...{ filePairIndex, form, filePairFieldArray, currentFilePairName }} />;
  }

  return (
    <Box width='100%' height='100%' overflow='hidden'>
      <Dropzone setValue={setNewCoverFile} />
    </Box>
  );
};

export default CoverFile;

import { useNavigate, useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { useGetExerciseElementsQuery } from '@generated/graphql';
import React, { useState } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';

import BackButton from 'components/buttons/BackButton';
import ROUTES from 'constants/routes';
import { EXERCISE_TYPE_NAME } from 'constants/global';
import { ListAltOutlined } from '@mui/icons-material';
import ElementList from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/ElementList';

import CourseNavigation from './CourseNavigation';

const ExerciseConstructor = () => {
  const navigate = useNavigate();
  const params = useParams<{ courseId: string; exerciseId: string }>();
  const exerciseId = params.exerciseId as string;
  const courseId = params.courseId as string;

  const [openNavigation, setOpenNavigation] = useState<boolean>(false);

  const { data, loading, error } = useGetExerciseElementsQuery({ variables: { exerciseId } });
  const exercise = data?.exercise;

  const lesson = exercise?.lesson;
  const block = lesson?.block;
  const course = block?.course;

  const header = `Курс: ${course?.title}, Блок: ${block?.title}, Урок: ${lesson?.title}`;

  if (loading) return <CircularLoading />;
  if (error || !exercise) return <Typography>Часть урока не найдена</Typography>;

  return (
    <>
      <CourseNavigation open={openNavigation} setOpen={setOpenNavigation} currentBlock={block} />
      <Stack spacing={2} sx={{ pb: 3 }}>
        <BackButton navigate={() => navigate(`/${ROUTES.COURSES}/${courseId}`)} text='Назад к содержанию' />
        <Stack direction='row' spacing={2} alignItems='flex-start'>
          <Tooltip title='Навигация по курсу'>
            <IconButton
              onClick={() => setOpenNavigation(!openNavigation)}
              sx={{ color: 'base.400', width: 40, height: 40 }}
            >
              <ListAltOutlined />
            </IconButton>
          </Tooltip>
          <Box>
            <Typography variant='h4'>{`${EXERCISE_TYPE_NAME[exercise.type]}: ${exercise.title}`}</Typography>
            <Typography variant='text7' sx={{ color: 'base.400' }}>
              {header}
            </Typography>
          </Box>
        </Stack>
        <ElementList exercise={exercise} />
      </Stack>
    </>
  );
};

export default ExerciseConstructor;

import { LoadingButton } from '@mui/lab';

interface SaveButtonProps {
  loading: boolean;
}

const SaveButton = ({ loading }: SaveButtonProps) => (
  <LoadingButton
    type='submit'
    variant='contained'
    loading={loading}
    loadingIndicator='Загрузка'
    sx={{ width: 200, position: 'fixed', bottom: 16, right: 24, zIndex: 999 }}
  >
    Сохранить
  </LoadingButton>
);

export default SaveButton;

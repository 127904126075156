import { INPUT_DEFAULT_VALUE, InputsGeneratorProps, InputType } from 'components/generators/inputsGenerator';
import qs, { ParsedQs } from 'qs';
import { format, isValid } from 'date-fns';
import _ from 'lodash';
import { SetURLSearchParams } from 'react-router-dom';
import { FieldValues, UseFormReset } from 'react-hook-form';

export enum FilterType {
  CUSTOM_FILTER = 'CustomFilter',
}

export const generateQueryOnSubmit = (
  data: any,
  filters: InputsGeneratorProps[],
  setSearchParams: SetURLSearchParams,
) => {
  const params = {};
  filters.forEach((filter) => {
    const { inputType, name } = filter;
    const dataName = _.get(data, name);
    const setParam = (newData: any) => _.set(params, name, newData);
    if (!dataName) return;
    switch (inputType) {
      case InputType.DATE: {
        if (isValid(dataName)) setParam(format(dataName, 'yyyy-MM-dd'));
        else setParam(dataName);
        break;
      }
      default: {
        setParam(dataName);
        break;
      }
    }
  });
  setSearchParams(qs.stringify(params));
};

export const onClearFilter = (
  filters: InputsGeneratorProps[],
  reset: UseFormReset<FieldValues>,
  setSearchParams: SetURLSearchParams,
) => {
  setSearchParams({});
  const params = {};
  filters.forEach((filter: any) => {
    const { name, defaultValue, multiple } = filter;
    const inputType = filter.inputType as InputType;
    _.set(params, name, multiple ? [] : defaultValue || INPUT_DEFAULT_VALUE[inputType]);
  });
  reset(params);
};

export const onSetFilterValueFromQuery = (
  filters: InputsGeneratorProps[],
  reset: UseFormReset<FieldValues>,
  query: ParsedQs,
) => {
  const params = {};
  filters.forEach((filter) => {
    const { name } = filter;
    const inputType = filter.inputType as InputType;
    const filterValue = _.get(query, filter.name);

    const setParam = (newData: any) => _.set(params, name, newData);
    setParam(filterValue || INPUT_DEFAULT_VALUE[inputType]);
  });
  if (Object.keys(params).length) {
    reset(params);
  }
};

export const generateFilterQuery = (filters: InputsGeneratorProps[], query: ParsedQs) => {
  const params: any = {};
  filters.forEach((filter: any) => {
    const filterName = filter.name;
    const filterValue = _.get(query, filterName);
    if (filterValue) {
      const setParam = (newData: any) => _.set(params, filterName, newData);

      switch (filter.filterType) {
        case FilterType.CUSTOM_FILTER: {
          setParam(filter.customFilter(filterValue));
          break;
        }
        default:
          setParam(filterValue);
          break;
      }
    }
  });

  return Object.keys(params).length === 0 ? undefined : params;
};

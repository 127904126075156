import {
  ElementFileInput,
  ElementFileType,
  ElementInput,
  ElementPartType,
  ElementType,
  FileElementInput,
  QuestionType,
} from '@generated/graphql';
import { isFullScoreQuestion } from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types/guards';
import {
  ExerciseForm,
  FilePair,
  IFile,
  IQuestionElement,
  IVideoElement,
  IHeadingElement,
  ITextElement,
  IExerciseLink,
  ExtendedElementTypes,
} from '../types/formTypes';

// ---------------VIDEO ELEMENT

const prepareVideoElement = (element: IVideoElement) => {
  const inputValue = element.kinescopeId;
  const onlyId = inputValue.replace('https://kinescope.io/', '');
  return {
    videoElement: {
      kinescopeId: onlyId,
    },
  };
};

// ---------------FILE ELEMENT

const getFileName = (file: IFile) => {
  const defaultFileName = file.file?.name as string;
  const fileExtension = defaultFileName.substring(defaultFileName.lastIndexOf('.'), defaultFileName.length);
  const newFileName = file.fileNameWithoutExtension || file.fileName;

  if (newFileName) {
    if (newFileName.includes('.')) {
      return newFileName.substring(0, newFileName.lastIndexOf('.')) + fileExtension;
    }
    return newFileName + fileExtension;
  }
  return file.file?.name;
};

const preparedFileElement = (restElementProps: { filePairs: FilePair[] }): { fileElement: FileElementInput } => ({
  fileElement: {
    files: restElementProps.filePairs?.reduce((acc: ElementFileInput[], currentPair: FilePair) => {
      const { cover, download } = currentPair;
      acc.push(
        {
          id: cover.id,
          type: ElementFileType.Cover,
          file: !cover.file?.url ? cover.file : undefined,
          fileName: getFileName(cover) as string,
        },
        {
          id: download.id,
          type: ElementFileType.Download,
          file: !download.file?.url ? download.file : undefined,
          fileName: getFileName(download) as string,
        },
      );

      return acc;
    }, []),
  },
});

// ---------------QUESTION ELEMENT

const preparedQuestionElement = (element: IQuestionElement, questionType: QuestionType) => ({
  questionElement: {
    description: element.description,
    title: element.title,
    placeholder: element.placeholder,
    maxScore: isFullScoreQuestion(element) && element.maxScore ? Number.parseFloat(element.maxScore) : undefined,
    ...(!isFullScoreQuestion(element) && {
      positiveScore: Number.parseFloat(element.positiveScore),
      negativeScore: Number.parseFloat(element.negativeScore),
    }),
    scoreType: element.scoreType,
    files: element?.files?.length
      ? element.files.map((file) => {
          const currentFile = file?.file || file;
          const isOldFile = 'url' in currentFile && !!currentFile?.url;
          const fileObj = isOldFile ? undefined : currentFile;
          return {
            fileName: getFileName(file) as string,
            file: fileObj,
            type: ElementFileType.Content,
            id: file.id,
          };
        })
      : null,
    questionType,
    answers:
      'elementParts' in element
        ? element.elementParts?.map((part, index) => {
            const getIsCorrect = (): boolean | undefined => {
              if ('singleCorrectAnswer' in element && element.singleCorrectAnswer) {
                return index === parseInt(element.singleCorrectAnswer, 10);
              }
              if ('isCorrect' in part) return !!part.isCorrect;
              return undefined;
            };
            return {
              content: 'content' in part ? part.content : undefined,
              correctAnswer: 'correctAnswer' in part ? part.correctAnswer : undefined,
              id: part.id,
              isCorrect: getIsCorrect(),
              type: questionType === QuestionType.Matching ? ElementPartType.Matching : ElementPartType.Answer,
            };
          })
        : undefined,
  },
});

const onSaveExercise = (formData: ExerciseForm): ElementInput[] => {
  const { elements } = formData;

  return (
    elements?.map((element) => {
      const { id, type: inputType } = element;
      const elementType = inputType!.id;
      const isQuestion = elementType.includes(ElementType.Question);
      const [type, questionType] = isQuestion
        ? (elementType.split(/_(.*)/s) as [ElementType, QuestionType])
        : [elementType as unknown as ElementType, undefined];

      const isFile = elementType === ExtendedElementTypes.FILE;
      const isHeading = elementType === ExtendedElementTypes.HEADING;
      const isText = elementType === ExtendedElementTypes.TEXT;
      // const isVideo = elementType === ElementType.Video;
      const isExerciseLink = elementType === ExtendedElementTypes.EXERCISE_LINK;

      if (isFile) {
        return {
          id,
          type,
          ...preparedFileElement(element as { filePairs: FilePair[] }),
        };
      }
      if (isHeading) {
        return {
          id,
          type,
          headingElement: { title: (element as IHeadingElement).title },
        };
      }

      if (isQuestion) {
        return {
          id,
          type,
          ...preparedQuestionElement(element as IQuestionElement, questionType as QuestionType),
        };
      }

      if (isText) {
        return {
          id,
          type,
          textElement: { content: (element as ITextElement).content },
        };
      }

      if (isExerciseLink) {
        return {
          id,
          type,
          exerciseLinkElement: { description: (element as IExerciseLink).description },
        };
      }
      // if (isVideo) {
      return {
        id,
        type,
        ...prepareVideoElement(element as IVideoElement),
      };
      // }
    }) || []
  );
};

export default onSaveExercise;

import { InputsGeneratorProps, InputType } from 'components/generators/inputsGenerator';
import { FilterType } from 'components/generators/filtersGenerator';
import { HOMEWORK_STATUS_OPTIONS, SUBJECT_OPTIONS } from 'constants/global';
import { formatForFilter } from 'helpers/date';

const filterFields = (): InputsGeneratorProps[] => [
  {
    name: 'userNumericId',
    label: 'ID, пользователь',
    inputType: InputType.TEXT,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value) => parseFloat(value),
  },
  {
    name: 'courseTitle',
    label: 'Название курса',
    inputType: InputType.TEXT,
  },
  {
    name: 'courseSubject',
    label: 'Предмет',
    inputType: InputType.AUTOCOMPLETE,
    filterType: FilterType.CUSTOM_FILTER,
    options: SUBJECT_OPTIONS,
    customFilter: (value) => value.id,
  },
  {
    name: 'status',
    label: 'Статус',
    inputType: InputType.AUTOCOMPLETE,
    filterType: FilterType.CUSTOM_FILTER,
    options: HOMEWORK_STATUS_OPTIONS,
    customFilter: (value) => value.id,
  },
  {
    name: 'updatedAt',
    label: 'Дата изменения',
    inputType: InputType.DATE,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value) => formatForFilter(value, 'start'),
  },
];

export default filterFields;

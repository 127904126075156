import { ExerciseElementItem } from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types/queryTypes';
import {
  IQuestionElement,
  IQuestionFewAnswer,
  IQuestionFullScore,
  IQuestionMatchingAnswer,
  IQuestionSingleAnswer,
  IQuestionTextAnswer,
} from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types/formTypes';
import { QuestionScoreType, QuestionType } from '@generated/graphql';

export const isExerciseLinkElement = (element: ExerciseElementItem): boolean =>
  element.__typename === 'ExerciseLinkElement';

export const isFeedbackLinkElement = (element: ExerciseElementItem): boolean =>
  element.__typename === 'FeedbackElement';

export const isFileElement = (element: ExerciseElementItem): boolean => element.__typename === 'FileElement';

export const isHeadingElement = (element: ExerciseElementItem): boolean => element.__typename === 'HeadingElement';

export const isTextElement = (element: ExerciseElementItem): boolean => element.__typename === 'TextElement';

export const isVideoElement = (element: ExerciseElementItem): boolean => element.__typename === 'VideoElement';

export const isQuestionElement = (element: ExerciseElementItem): boolean => element.__typename === 'QuestionElement';

export const isSingleAnswerQuestion = (
  element: IQuestionElement,
): element is IQuestionElement & IQuestionSingleAnswer => element.questionType === QuestionType.SingleAnswer;

export const isTextAnswerQuestion = (element: IQuestionElement): element is IQuestionElement & IQuestionTextAnswer =>
  element.questionType === QuestionType.TextAnswers;

export const isFewAnswerQuestion = (element: IQuestionElement): element is IQuestionElement & IQuestionFewAnswer =>
  element.questionType === QuestionType.FewAnswers;

export const isMatchingAnswerQuestion = (
  element: IQuestionElement,
): element is IQuestionElement & IQuestionMatchingAnswer => element.questionType === QuestionType.Matching;

export const isFullScoreQuestion = (element: IQuestionElement): element is IQuestionElement & IQuestionFullScore =>
  element.scoreType === QuestionScoreType.Full;

import ButtonsWrapper, { ButtonVariantEnum } from 'components/buttons/ButtonWrapper';
import Lesson from 'pages/Course/view/tabs/Content/components/Lesson';
import React from 'react';
import { FieldArrayWithId, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { BlockForm } from 'pages/Course/view/tabs/Content/types';

export interface LessonCardProps {
  lesson: FieldArrayWithId<BlockForm, `blocks.${number}.lessons`, 'formId'>;
  lessonFieldArray: UseFieldArrayReturn<BlockForm, `blocks.${number}.lessons`, 'formId'>;
  lessonIndex: number;
  blockIndex: number;
  form: UseFormReturn<BlockForm>;
}

const LessonCard = ({ lesson, lessonIndex, blockIndex, lessonFieldArray, form }: LessonCardProps) => (
  <ButtonsWrapper
    key={`lesson-${lesson.formId}`}
    data={lesson}
    fieldArray={lessonFieldArray}
    index={lessonIndex}
    variant={ButtonVariantEnum.LESSON}
  >
    <Lesson blockIndex={blockIndex} form={form} lessonIndex={lessonIndex} />
  </ButtonsWrapper>
);

export default LessonCard;

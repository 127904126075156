import { InputsGeneratorProps, InputType } from 'components/generators/inputsGenerator';
import { FilterType } from 'components/generators/filtersGenerator';
import { BOOLEAN_OPTIONS, ROLE_OPTIONS } from 'constants/global';
import { formatForFilter } from 'helpers/date';

const filterFields = (): InputsGeneratorProps[] => [
  {
    name: 'numericId',
    label: 'ID',
    inputType: InputType.TEXT,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value) => parseFloat(value),
  },
  {
    name: 'fullName',
    label: 'ФИО',
    inputType: InputType.TEXT,
  },
  {
    name: 'role',
    label: 'Роль',
    inputType: InputType.AUTOCOMPLETE,
    filterType: FilterType.CUSTOM_FILTER,
    options: ROLE_OPTIONS,
    customFilter: (value) => value.id,
  },
  {
    name: 'createdAt',
    label: 'Дата создания',
    inputType: InputType.DATE,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value) => formatForFilter(value, 'start'),
  },
  {
    name: 'isBlocked',
    label: 'Заблокирован',
    inputType: InputType.AUTOCOMPLETE,
    filterType: FilterType.CUSTOM_FILTER,
    options: BOOLEAN_OPTIONS,
    customFilter: (value) => Boolean(Number(value.id)),
  },
];

export default filterFields;

import { InputsGeneratorProps, InputType } from 'components/generators/inputsGenerator';
import { FilterType } from 'components/generators/filtersGenerator';
import { BOOLEAN_OPTIONS, SUBJECT_OPTIONS } from 'constants/global';
import { formatForFilter } from 'helpers/date';

const filterFields = (): InputsGeneratorProps[] => [
  {
    name: 'numericId',
    label: 'ID',
    inputType: InputType.TEXT,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value) => parseFloat(value),
  },
  {
    name: 'title',
    label: 'Название',
    inputType: InputType.TEXT,
  },
  {
    name: 'subject',
    label: 'Предмет',
    inputType: InputType.AUTOCOMPLETE,
    filterType: FilterType.CUSTOM_FILTER,
    options: SUBJECT_OPTIONS,
    customFilter: (value) => value.id,
  },
  {
    name: 'isHidden',
    label: 'Скрыт',
    inputType: InputType.AUTOCOMPLETE,
    filterType: FilterType.CUSTOM_FILTER,
    options: BOOLEAN_OPTIONS,
    customFilter: (value) => Boolean(Number(value.id)),
  },
  {
    name: 'dateFrom',
    label: 'Дата начала',
    inputType: InputType.DATE,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value) => [formatForFilter(value, 'start'), formatForFilter(value, 'end')],
  },
  {
    name: 'dateTo',
    label: 'Дата окончания',
    inputType: InputType.DATE,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value) => [formatForFilter(value, 'start'), formatForFilter(value, 'end')],
  },
];

export default filterFields;

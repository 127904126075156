import { RegisterOptions, useController } from 'react-hook-form';
import Autocomplete, { IAutocompleteProps, IOption } from 'components/inputs/Autocomplete';
import { AutocompleteFreeSoloValueMapping } from '@mui/material/useAutocomplete/useAutocomplete';

export interface IAutocompleteControlProps<
  TOption extends Record<string, any> = IOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Omit<IAutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>, 'onChange' | 'value'> {
  name: string;
  rules?: RegisterOptions;
}

const AutocompleteControl = <
  TOption extends Record<string, any> = IOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  options = [],
  name,
  optionName = 'displayName',
  multiple = false as Multiple,
  disabled = false,
  onInputChange,
  placeholder,
  search = false,
  equalityParam = 'id',
  rules,
  loading = false,
  getOptionLabel = (option: TOption | AutocompleteFreeSoloValueMapping<FreeSolo>) =>
    typeof option === 'object' ? option[optionName] : option,
  freeSolo = false as FreeSolo,
  label,
  startIcon,
  fullWidth,
  filterOptions,
}: IAutocompleteControlProps<TOption, Multiple, DisableClearable, FreeSolo>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules });

  const getValue = () => {
    if (typeof field.value === 'undefined') {
      if (multiple) return [];
      return null;
    }
    return field.value;
  };
  const value = getValue();

  return (
    <Autocomplete<TOption, Multiple, DisableClearable, FreeSolo>
      options={options}
      loading={loading}
      multiple={multiple}
      disabled={disabled}
      onInputChange={onInputChange}
      placeholder={placeholder}
      error={error}
      value={value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      freeSolo={freeSolo}
      equalityParam={equalityParam}
      getOptionLabel={getOptionLabel}
      search={search}
      label={label}
      startIcon={startIcon}
      fullWidth={fullWidth}
      filterOptions={filterOptions}
    />
  );
};

export default AutocompleteControl;

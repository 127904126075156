import { useParams } from 'react-router-dom';
import { useBlocksQuery } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import { Button, Stack } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useCallback, useContext, useRef } from 'react';
import { Form } from 'components/inputs';
import AddIcon from '@mui/icons-material/Add';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonsWrapper, { ButtonVariantEnum } from 'components/buttons/ButtonWrapper';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import SaveButton from './components/SaveButton';
import { BlockForm } from './types';
import useUpdateBlocks from './hooks/useUpdateBlocks';
import useInitForm from './hooks/useInitForm';
import Block, { BlockRef } from './components/Block';
import blockValidation from './validation';

const Content = () => {
  const params = useParams<{ courseId: string }>();
  const courseId = params.courseId as string;
  const { addToast } = useContext(ToastContext);
  const { data, loading, error, refetch } = useBlocksQuery({
    variables: { filter: { courseId }, page: 0, limit: 1000 },
  });
  const blocks = data?.blocks.data;

  const initForm = useInitForm();

  const form = useForm<BlockForm>({
    // @ts-ignore
    resolver: yupResolver(blockValidation),
    values: initForm(blocks),
  });
  const { control, handleSubmit, getValues } = form;

  const { onUpdateBlock, updateBlockLoading } = useUpdateBlocks();

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const blockFieldArray = useFieldArray({
    control,
    keyName: 'formId',
    name: 'blocks',
  });
  const { fields, append } = blockFieldArray;

  const handleAddBlock = useCallback(() => {
    append({
      title: '',
      trial: false,
      id: '',
      coverFileUrl: '',
      dateFrom: '',
      dateTo: '',
      cost: null,
      lessons: [],
    });
  }, [append]);

  const blockRef = useRef<BlockRef[]>([]);

  const onDragEnd = (result: DropResult) => {
    const sourceBlockName = result.source.droppableId as `blocks.${number}`;
    const sourceLessonIndex = result.source.index;
    const sourceBlockIndex = Number(sourceBlockName.split('.')[1]);

    const destinationBlockName = result.destination?.droppableId as `blocks.${number}`;
    const destinationBlockIndex = Number(destinationBlockName.split('.')[1]);
    const destinationLessonIndex = result.destination?.index;

    const sourceBlockRef = blockRef.current?.[sourceBlockIndex];
    const destinationBlockRef = blockRef.current?.[destinationBlockIndex];

    // в пределах одного блока
    if (typeof destinationLessonIndex !== 'undefined' && sourceBlockIndex === destinationBlockIndex) {
      sourceBlockRef?.moveLesson(sourceLessonIndex, destinationLessonIndex);
    } else {
      // перенесен в другой блок
      const lesson = getValues(`${sourceBlockName}.lessons.${sourceLessonIndex}`);
      const destinationLessonList = getValues(`${destinationBlockName}.lessons`);
      if (destinationLessonIndex === 0) {
        destinationBlockRef?.prependLesson(lesson);
      } else if (destinationLessonIndex === destinationLessonList.length) {
        destinationBlockRef?.appendLesson(lesson);
      } else if (typeof destinationLessonIndex !== 'undefined') {
        destinationBlockRef?.insertLesson(lesson, destinationLessonIndex);
      }
      // удаляем из источника
      sourceBlockRef?.removeLessonFromBlock(sourceLessonIndex);
    }
  };

  if (loading || !!error) return <CircularLoading />;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Form form={form} onSubmit={handleSubmit((formData) => onUpdateBlock(formData, refetch), onError)}>
        <Stack spacing={2}>
          {fields.map((block, index) => (
            <ButtonsWrapper<BlockForm, 'blocks', 'formId'>
              data={block}
              fieldArray={blockFieldArray}
              index={index}
              variant={ButtonVariantEnum.BLOCK}
              key={`block-${block.formId}`}
            >
              <Block
                form={form}
                blockIndex={index}
                ref={(ref) => {
                  if (blockRef.current) {
                    blockRef.current[index] = ref;
                  }
                }}
              />
            </ButtonsWrapper>
          ))}
          <Button startIcon={<AddIcon />} onClick={handleAddBlock} sx={{ width: 'fit-content' }}>
            блок
          </Button>
          <SaveButton loading={updateBlockLoading} />
        </Stack>
      </Form>
    </DragDropContext>
  );
};

export default Content;

import React, { ReactNode, useContext, useState } from 'react';
import { Stack } from '@mui/material';
import { ModalContext } from 'context/modalContext';
import { useNavigate } from 'react-router-dom';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import { ArrayPath, FieldValues, UseFieldArrayReturn } from 'react-hook-form';

export enum ButtonVariantEnum {
  BLOCK = 'BLOCK',
  LESSON = 'LESSON',
  LESSON_PART = 'LESSON_PART',
  ELEMENT = 'ELEMENT',
}

type ButtonWrapperProps<
  FieldArray extends FieldValues,
  FieldValue extends ArrayPath<FieldArray>,
  FormId extends string,
> = {
  children: ReactNode;
  index: number;
  variant: ButtonVariantEnum;
  data: { id: string | undefined | null; title: string };
  fieldArray: UseFieldArrayReturn<FieldArray, FieldValue, FormId>;
  disabled?: boolean;
};

type ButtonVariant = {
  [key in ButtonVariantEnum]: {
    title: string;
    buttons: string[];
  };
};

const ButtonsWrapper = <
  FieldArray extends FieldValues,
  FieldValue extends ArrayPath<FieldArray>,
  FormId extends string,
>({
  children,
  index,
  variant,
  data,
  fieldArray,
  disabled = false,
}: ButtonWrapperProps<FieldArray, FieldValue, FormId>) => {
  const { handleOpen } = useContext(ModalContext);
  const { fields, remove, swap } = fieldArray;

  const [hover, setHover] = useState<boolean>(false);
  const navigate = useNavigate();

  const VARIANTS: ButtonVariant = {
    BLOCK: {
      title: 'блок',
      buttons: ['remove', 'swap'],
    },
    LESSON: {
      title: 'урок',
      buttons: ['remove'],
    },
    LESSON_PART: {
      title: 'часть урока',
      buttons: ['swap', 'remove', 'edit'],
    },
    ELEMENT: {
      title: 'элемент',
      buttons: ['remove'],
    },
  };

  const currentVariant = VARIANTS[variant];
  const isRemoveActionExist = currentVariant.buttons.includes('remove');
  const isSwapActionExist = currentVariant.buttons.includes('swap');
  const isEditActionExist = currentVariant.buttons.includes('edit');
  const isLastElement = index === fields.length - 1;
  const isFirstElement = index === 0;

  const onSwapUp = () => {
    swap(index, index - 1);
    setHover(false);
  };

  const onSwapDown = () => {
    swap(index, index + 1);
    setHover(false);
  };

  const onRemove = () => {
    handleOpen({
      title: `Удалить ${currentVariant.title}`,
      content: `Вы уверены, что хотите удалить ${currentVariant.title}${data?.title ? ` «${data?.title}»` : ''}?`,
      handleClick: async () => {
        remove(index);
      },
    });
  };

  const onEdit = () => (data.id ? navigate(data.id) : undefined);

  return (
    <Stack sx={{ flexDirection: 'row', minHeight: 52, minWidth: '100%' }}>
      {children}
      <Stack
        sx={{ width: '28px', ml: 0.5, justifyContent: 'flex-start' }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <>
          {isSwapActionExist && (
            <>
              {!isFirstElement && (
                <ActionButton
                  isFilled={hover}
                  handleClick={onSwapUp}
                  type={ActionButtonEnum.MOVE_UP}
                  disabled={disabled}
                />
              )}
              {!isLastElement && (
                <ActionButton
                  isFilled={hover}
                  handleClick={onSwapDown}
                  type={ActionButtonEnum.MOVE_DOWN}
                  disabled={disabled}
                />
              )}
            </>
          )}

          {isRemoveActionExist && (
            <>
              <ActionButton
                isFilled={hover}
                disabled={disabled}
                handleClick={onRemove}
                type={ActionButtonEnum.DELETE}
              />
              {isEditActionExist && data.id && (
                <ActionButton disabled={disabled} isFilled={hover} handleClick={onEdit} type={ActionButtonEnum.EDIT} />
              )}
            </>
          )}
        </>
      </Stack>
    </Stack>
  );
};

export default ButtonsWrapper;
